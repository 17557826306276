import { PageAuthSwitch } from "@/auth/page-auth-switch";
import OfficeUserListings from "@/views/pages/office-user/listings/listings";
import ListingsCreate from "@/views/pages/office-user/listings/create/listing-create";
import ListingsViewEdit from "@/views/pages/office-user/listings/view/listing-view-edit";
import React from "react";
import { PageDiscover } from "@/views/pages/equipment-provider/discover/page-discover";
import { PageEnrollments } from "@/views/pages/equipment-provider/enrollments/page-enrollments";
import { PageMyFavorites } from "@/views/pages/equipment-provider/myfavorites/page-my-favorites";
import { PageDiscoverShow } from "@/views/pages/equipment-provider/discover/show/page-discover-show";
import PageMyDrafts from "@/views/pages/office-user/my-drafts/page-my-drafts";
import { ListingGroup } from "@/views/pages/office-user/listing-group/listing-group";
import { GroupView } from "@/views/pages/office-user/listing-group/group-view/group-view";
import { CreateGroup } from "@/views/pages/office-user/listing-group/create/create-group";
import { TermsAndConditions } from "@/views/pages/common/terms-and-conditions";
import { EquipmentProviderUserManual } from "@/views/pages/equipment-provider/user-manual/provider-user-manual";
import { PageOfferCreateEditView } from "@/views/pages/equipment-provider/discover/show/offer/page-offer-create-edit-view";
import { PageListingViewOffer } from "@/views/pages/office-user/listings/view/listing-view-offer";

const LoginRedirect = React.lazy(
  () => import("../views/pages/auth-redirects/login-redirect")
);
const RootRedirect = React.lazy(
  () => import("../views/pages/auth-redirects/root-redirect")
);
const AdminUserLayout = React.lazy(
  () => import("../layout/admin-user/admin-user-layout")
);
const ClientUserLayout = React.lazy(
  () => import("../layout/client-user/client-user-layout")
);

const Login = React.lazy(() => import("../views/pages/login/login"));
const Register = React.lazy(() => import("../views/pages/register/register"));
const Page404 = React.lazy(() => import("../views/pages/page404/page404"));

type TDefaultRoutes = {
  path: string;
  element: React.ReactNode;
  children?: TDefaultRoutes;
}[];

export const defaultRoutes: TDefaultRoutes = [
  {
    path: "/",
    element: (
      <PageAuthSwitch
        defaultElement={<LoginRedirect />}
        adminUserElement={<AdminUserLayout />}
        clientUserElement={<ClientUserLayout />}
      />
    ),
    children: [
      {
        path: "/",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<PageDiscover />}
            clientUserElement={<PageDiscover />}
          />
        ),
      },
      {
        path: "/listing-group",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<ListingGroup />}
            clientUserElement={<ListingGroup />}
          />
        ),
      },
      {
        path: "/listing-group/create",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<RootRedirect />}
            clientUserElement={<CreateGroup />}
          />
        ),
      },
      {
        path: "/listing-group/:groupId",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<RootRedirect />}
            clientUserElement={<GroupView />}
          />
        ),
      },
      {
        path: "/listing-group/:groupId/listing/create",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<RootRedirect />}
            clientUserElement={<ListingsCreate />}
          />
        ),
      },
      {
        path: "/listing/create",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<RootRedirect />}
            clientUserElement={<ListingsCreate />}
          />
        ),
      },
      {
        path: "/listing/:listingId/offer/:offerId/view",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<RootRedirect />}
            clientUserElement={<PageListingViewOffer />}
          />
        ),
      },
      {
        path: "/listing-group/:groupId/listing/:listingId",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<RootRedirect />}
            clientUserElement={<ListingsViewEdit />}
          />
        ),
      },
      {
        path: "/listing",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<RootRedirect />}
            clientUserElement={<OfficeUserListings />}
          />
        ),
      },
      {
        path: "/my-drafts",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<RootRedirect />}
            clientUserElement={<PageMyDrafts />}
          />
        ),
      },
      {
        path: "/discover",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<PageDiscover />}
            clientUserElement={<PageDiscover />}
          />
        ),
      },
      {
        path: "/discover/:listingId",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<PageDiscoverShow />}
            clientUserElement={<PageDiscoverShow />}
          />
        ),
      },
      {
        path: "/discover/:listingId/offers/:enrollmentId/create",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<PageOfferCreateEditView />}
            clientUserElement={<PageOfferCreateEditView />}
          />
        ),
      },
      {
        path: "/discover/:listingId/offers/:enrollmentId/create",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<PageOfferCreateEditView />}
            clientUserElement={<PageOfferCreateEditView />}
          />
        ),
      },
      {
        path: "/discover/:listingId/offers/:enrollmentId/:offerId/edit",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<PageOfferCreateEditView isEditing />}
            clientUserElement={<PageOfferCreateEditView isEditing />}
          />
        ),
      },
      {
        path: "/enrollments",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<PageEnrollments />}
            clientUserElement={<PageEnrollments />}
          />
        ),
      },
      {
        path: "/my-favorites",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<PageMyFavorites />}
            clientUserElement={<PageMyFavorites />}
          />
        ),
      },
      {
        path: "/terms-and-conditions",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<TermsAndConditions />}
            clientUserElement={<TermsAndConditions />}
          />
        ),
      },
      {
        path: "/user-manual",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            adminUserElement={<EquipmentProviderUserManual />}
            clientUserElement={<EquipmentProviderUserManual />}
          />
        ),
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "/login",
    element: (
      <PageAuthSwitch
        defaultElement={<Login />}
        adminUserElement={<RootRedirect />}
        clientUserElement={<RootRedirect />}
      />
    ),
  },
  {
    path: "/register",
    element: (
      <PageAuthSwitch
        defaultElement={<Register />}
        adminUserElement={<RootRedirect />}
        clientUserElement={<RootRedirect />}
      />
    ),
  },
  {
    path: "*",
    element: <Page404 />,
  },
];
