// GroupTable.tsx
import React, { useRef, useState } from "react";
import { Group, TQuery } from "./types";
import { useNavigate } from "react-router-dom";
import { dateToYearMonthDay } from "@/utils/date-fns";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import {
  cilBan,
  cilCalendar,
  cilFile,
  cilSearch,
  cilSettings,
  cilFlagAlt,
  cilUser,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
  CTooltip,
} from "@coreui/react";
import { SortArrow } from "@/components/forms/custom/custom-table/sort-arrow";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";

interface GroupTableProps {
  data: TQuery | undefined;
  isLoading: boolean;
  onDelete: (group: Group) => void;
  orderBy: string;
  orderDir: string;
  setOrder: (orderBy: string, orderDir: string) => void;
}

export const GroupTable: React.FC<GroupTableProps> = ({
  data,
  isLoading,
  onDelete,
  orderBy,
  orderDir,
  setOrder,
}) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const pageRef = useRef<HTMLDivElement>(null);
  const [mouseOverIndex, setMouseOverIndex] = useState<number | null>(null);

  const columns = [
    {
      key: "id",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>#</span>
          <SortArrow
            columnName="id"
            orderBy={orderBy}
            orderDir={orderDir}
            onClick={setOrder}
          />
        </div>
      ),
    },
    {
      key: "name",
      label: (
        <div className="flex items-center h-full gap-1">
          <CIcon icon={cilFile} />{" "}
          {t("PageListingGroups.ListingGroupsTable.name")}
          <SortArrow
            columnName="name"
            orderBy={orderBy}
            orderDir={orderDir}
            onClick={setOrder}
          />
        </div>
      ),
    },
    {
      key: "created_by",
      label: (
        <div className="flex items-center h-full gap-1 whitespace-nowrap">
          <CIcon icon={cilUser} />{" "}
          {t("PageListingGroups.ListingGroupsTable.createdBy")}
        </div>
      ),
    },
    {
      key: "created_at",
      label: (
        <div className="flex items-center h-full gap-1 whitespace-nowrap">
          <CIcon icon={cilCalendar} />{" "}
          {t("PageListingGroups.ListingGroupsTable.createdAt")}
          <SortArrow
            columnName="created_at"
            orderBy={orderBy}
            orderDir={orderDir}
            onClick={setOrder}
          />
        </div>
      ),
    },
    {
      key: "actions",
      label: (
        <div className="flex items-center h-full gap-1 whitespace-nowrap">
          <CIcon icon={cilSettings} /> {t("CommonTables.actions")}
        </div>
      ),
    },
  ];

  return (
    <div ref={pageRef}>
      <CTable
        style={{
          marginBottom: 0,
        }}
        className={`bg-white shadow-lg rounded-lg ${false ? "opacity-75" : ""} transition-all`}
      >
        <CTableBody
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr auto auto auto",
          }}
          onMouseLeave={() => {
            setMouseOverIndex(() => null);
          }}
        >
          <CTableRow
            className="text-base font-extrabold tracking-wider text-gray-900 uppercase bg-gray-200"
            style={{
              display: "contents",
            }}
            onMouseOver={() => {
              setMouseOverIndex(() => null);
            }}
          >
            {columns.map((column) => (
              <CTableHeaderCell
                className="py-4 border-b-2 border-gray-400"
                scope="col"
                key={column.key}
              >
                {column.label}
              </CTableHeaderCell>
            ))}
          </CTableRow>
          {isLoading
            ? columns.map((_, index) => (
                <CTableDataCell key={index}>
                  <DefaultSkeleton />
                </CTableDataCell>
              ))
            : data?.groups?.map?.((item: Group, index: number) => (
                <CTableRow
                  className="text-gray-700 hover:bg-gray-100"
                  key={item.id}
                  style={{
                    display: "contents",
                  }}
                  onMouseOver={() => {
                    setMouseOverIndex(() => index);
                  }}
                  color={index === mouseOverIndex ? "info" : ""}
                >
                  <CTableDataCell className="flex items-center">
                    {item.id}
                  </CTableDataCell>
                  <CTableDataCell className="flex items-center">
                    {item.is_default
                      ? t("PageListingGroups.ListingGroupsTable.defaultName")
                      : item.name}
                    {item.is_default ? (
                      <CIcon icon={cilFlagAlt} className="ml-2 text-primary" />
                    ) : (
                      ""
                    )}
                  </CTableDataCell>
                  <CTableDataCell className="flex items-center">
                    {item.created_by}
                  </CTableDataCell>
                  <CTableDataCell className="flex items-center">
                    {dateToYearMonthDay(item.created_at)}
                  </CTableDataCell>
                  <CTableDataCell>
                    <div className="flex items-center justify-center w-auto h-full gap-1">
                      <CTooltip
                        content={t("CommonTooltips.view")}
                        container={pageRef.current}
                      >
                        <CButton
                          color="info"
                          type="button"
                          onClick={() => {
                            navigate(`/listing-group/${item.id}`);
                          }}
                        >
                          <span className="text-white">
                            <CIcon icon={cilSearch} />
                          </span>
                        </CButton>
                      </CTooltip>

                      <CTooltip
                        content={
                          item.is_default
                            ? t("CommonTooltips.cantDeleteDefaultGroup")
                            : t("CommonTooltips.remove")
                        }
                        container={pageRef.current}
                      >
                        <div>
                          <CButton
                            color={item.is_default ? "secondary" : "danger"}
                            disabled={item.is_default}
                            onClick={() => onDelete(item)}
                          >
                            <span className="text-white">
                              <CIcon icon={cilBan} />
                            </span>
                          </CButton>
                        </div>
                      </CTooltip>
                    </div>
                  </CTableDataCell>
                </CTableRow>
              ))}
        </CTableBody>
      </CTable>
    </div>
  );
};
