import { EListingRequiredOfferType } from "@/enums/listing-enums";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { CBadge } from "@coreui/react";

type TOfferTypeBadgeProps = {
  offerType?: EListingRequiredOfferType;
};

export const OfferTypeBadge = (props: TOfferTypeBadgeProps) => {
  const { offerType = "" } = props;
  const { t } = useAppTranslation();

  let text: string = offerType;

  switch (offerType) {
    case EListingRequiredOfferType.Both:
      text = t("Enums.ListingRequiredOfferType.both");
      break;
    case EListingRequiredOfferType.UsedOnly:
      text = t("Enums.ListingRequiredOfferType.usedOnly");
      break;
    case EListingRequiredOfferType.NewOnly:
      text = t("Enums.ListingRequiredOfferType.newOnly");
      break;
    default:
      break;
  }
  return <CBadge color="info">{text}</CBadge>;
};
