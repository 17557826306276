import {
  MAX_ATTACHMENT_SIZE,
  MAX_ATTACHMENTS,
} from "@/constants/form-constatns";
import { useAppDispatch } from "@/store/hooks";
import { useAuth } from "@clerk/clerk-react";
import { CTab, CTabContent, CTabList, CTabPanel, CTabs } from "@coreui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, Formik, validateYupSchema } from "formik";
import { listingCreateSchema, listingCreateDraftSchema } from "./schema";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { createListingFetcher } from "./query";
import { useNavigate, useParams } from "react-router-dom";
import { setModalConfirmation } from "@/store/modal/slice";
import CIcon from "@coreui/icons-react";
import { cilInfo, cilFile } from "@coreui/icons";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { authedFetch } from "@/utils/authed-fetch";
import { ListingCreateButtons } from "./components/listing-create-buttons";
import { ListingCreateBasicInformation } from "./components/listing-create-basic-information";
import { ListingCreateSpecifications } from "./components/listing-create-specifications";
import { ListingCreateLocation } from "./components/listing-create-location";
import { ListingCreateSettings } from "./components/listing-create-settings";
import { useToastsListingCreate } from "./hooks/use-toasts-listing-create";
import { ListingCreateAttachments } from "./components/listing-create-attachments";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { ECountry } from "@/enums/location-enums";
import { EListingCategory, EListingRequiredOfferType } from "@/enums/listing-enums";
import { TSpecification } from "../types/listing-types";

const initialState: TListingCreateFormState = {
  files: [],
  isRestrictedBudget: false,
  isWithDeadline: false,
  hasSubmittedPublish: false,
  hasSubmittedDraft: false,
  isSubmitting: false,
};


const ListingCreate = () => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const {
    toastBackendError,
    toastDraftCreated,
    toastPublishCreated,
    toastMaxAttachments,
    toastMaxAttachmentSize,
  } = useToastsListingCreate();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { groupId } = useParams();
  const isRestrictedGroup: boolean = !!groupId;
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(groupId);
  const handleGroupChange = (newGroupId: string) => {
    setSelectedGroupId(newGroupId);
  };

  useBreadcrumbs("LISTING__CREATE");
  const pageRef = useRef(null);

  const initialValues: TListingCreateForm = {
    title: "",
    category: "" as EListingCategory,
    Specifications: [] as TSpecification[],
    optional_specification: "",
    country: undefined,
    budget: 0,
    attachments: [],
    is_hidden: false,
    deadline: undefined,
    group_id: 0,
    requiredOfferType: EListingRequiredOfferType.Both
  };

  const [state, setState] = useState(initialState);
  const hasSubmittedEither =
    state.hasSubmittedPublish || state.hasSubmittedDraft;
  const setFiles = (files: TListingCreateFormState["files"]) =>
    setState((p) => ({ ...p, files }));
  const setHasSubmittedPublish = (
    hasSubmittedPublish: TListingCreateFormState["hasSubmittedPublish"]
  ) => setState((p) => ({ ...p, hasSubmittedPublish }));
  const setHasSubmittedDraft = (
    hasSubmittedDraft: TListingCreateFormState["hasSubmittedDraft"]
  ) => setState((p) => ({ ...p, hasSubmittedDraft }));
  const setIsSubmitting = (
    isSubmitting: TListingCreateFormState["isSubmitting"]
  ) => setState((p) => ({ ...p, isSubmitting }));
  const setIsRestrictedBudget = (
    isRestrictedBudget: TListingCreateFormState["isRestrictedBudget"]
  ) => setState((p) => ({ ...p, isRestrictedBudget }));
  const setIsWithDeadline = (
    isWithDeadline: TListingCreateFormState["isWithDeadline"]
  ) => setState((p) => ({ ...p, isWithDeadline }));

  const createSchema = listingCreateSchema({
    isRestrictedBudget: state.isRestrictedBudget,
    isWithDeadline: state.isWithDeadline,
  });
  const draftSchema = listingCreateDraftSchema({
    isRestrictedBudget: state.isRestrictedBudget,
    isWithDeadline: state.isWithDeadline,
  });

  const navigateBack = () => {
    navigate(-1);
  };

  const { data: dataGroupName, isLoading: isLoadingGroupName } =
    useQuery<TGroupNameQuery>({
      queryKey: ["group-name", groupId],
      queryFn: async () => {
        return authedFetch({
          token: await getToken(),
          endpoint: `api/office/listing-group/get-name/${groupId}`,
        }).then((res) => res.json());
      },
      enabled: isRestrictedGroup
    });

  const { data: dataGroups, isLoading: isLoadingGroups } =
    useQuery<{id: number, name: string}[]>({
      queryKey: ["group-name"],
      queryFn: async () => {
        return authedFetch({
          token: await getToken(),
          endpoint: `api/office/listing-group/current`,
        }).then((res) => res.json());
      },
      enabled: !isRestrictedGroup
    });


  const listingDraftMutation = useMutation({
    mutationKey: ["listing-create-draft"],
    mutationFn: async ({
      formData,
      files,
    }: {
      formData: TListingCreateForm;
      files: File[];
    }) => {
      setIsSubmitting(true);
      const { budget, deadline, ...formDataRest } = formData;
      return createListingFetcher({
        formData: {
          ...formDataRest,
          group_id: parseInt(selectedGroupId || "0"),
          budget: state.isRestrictedBudget ? budget : 0,
          deadline: state.isWithDeadline ? deadline : undefined,
        },
        files,
        status: "DRAFT",
        token: await getToken(),
        attachmentErrorMessage: t("Toasts.Listing.failedToUploadAttachments"),
      });
    },
    onError: (error) => {
      toastBackendError(error);
      setIsSubmitting(false);
    },
    onSuccess: (data) => {
      toastDraftCreated();
      setIsSubmitting(false);
      const navigateListingId = data?.repoRes?.id || data?.[0]?.repoRes?.id;

      if (navigateListingId !== undefined && navigateListingId !== null) {
        navigate(`/listing-group/${selectedGroupId}/listing/${navigateListingId}`);
      }
    },
  });
  const listingMutation = useMutation({
    mutationKey: ["listing-create"],
    mutationFn: async ({
      formData,
      files,
    }: {
      formData: TListingCreateForm;
      files: File[];
    }) => {
      setIsSubmitting(true);
      const { budget, deadline, ...formDataRest } = formData;
      return createListingFetcher({
        formData: {
          ...formDataRest,
          group_id: parseInt(selectedGroupId || "0"),
          budget: state.isRestrictedBudget ? budget : 0,
          deadline: state.isWithDeadline ? deadline : undefined,
        },
        status: "OPEN",
        files,
        token: await getToken(),
        attachmentErrorMessage: t("Toasts.Listing.failedToUploadAttachments"),
      });
    },
    onError: (error) => {
      toastBackendError(error);
      setIsSubmitting(false);
    },
    onSuccess: (data) => {
      toastPublishCreated();
      setIsSubmitting(false);

      const navigateListingId = data?.repoRes?.id || data?.[0]?.repoRes?.id;

      if (navigateListingId !== undefined && navigateListingId !== null) {
        navigate(`/listing-group/${selectedGroupId}/listing/${navigateListingId}`);
      }
    },
  });

  const handleOnDraft = ({ formData }: { formData: TListingCreateForm }) => {
    validateYupSchema(formData, draftSchema)
      .then((values) => {
        listingDraftMutation.mutate({
          formData: {
            title: values.title || "",
            category: values.category || ("" as EListingCategory),
            Specifications: values?.Specifications || [] as TSpecification[],
            country: values.country,
            deadline: state.isWithDeadline ? values.deadline : undefined,
            is_hidden: values.is_hidden || false,
            budget: (values?.budget as number) > 0 ? values.budget : 0,
            group_id: parseInt(selectedGroupId || "0"),
          },
          files: state.files,
        });
      })
      .catch(() => {});
  };

  const handleAttachmentToDelete = ({
    indexOfNew,
  }: {
    indexOfNew?: number;
  }) => {
    if (indexOfNew !== undefined && indexOfNew !== null) {
      removeFileByIndex(indexOfNew);
      return;
    }
  };

  const removeFileByIndex = (index: number) => {
    const tempState = [...state.files].filter(
      (_file, _index) => _index !== index
    );
    setFiles(tempState);
  };

  const clearFiles = ({ cb }: { cb?: () => void }) => {
    setFiles([]);
    cb?.();
  };

  const handlerFilesOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const tempState: TListingCreateFormState["files"] = [...state.files];
    if (e.target.files!.length > MAX_ATTACHMENTS) {
      toastMaxAttachments();
    } else {
      for (let i = 0, len = e.target.files!.length; i < len; i++) {
        if (e.target.files![i].size < MAX_ATTACHMENT_SIZE) {
          tempState.push(e.target.files![i]);
        } else {
          clearFiles({
            cb: () => {
              toastMaxAttachmentSize();
            },
          });
          break;
        }
      }
      setFiles([...tempState]);
    }
  };

  useEffect(() => {
   if (dataGroups && dataGroups?.groups?.length > 0) {
    setSelectedGroupId(dataGroups.groups[0].id);
   }
  }, [dataGroups]);


  return (
    <div className="max-w-[1000px]" ref={pageRef}>
      <Formik<TListingCreateForm>
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (formsData, { validateForm }) => {
          if (state.files.length > 0) {
            validateForm(formsData);

            listingMutation.mutate({
              formData: {
                ...formsData,
              },
              files: state.files,
            });
          } else {
            dispatch(
              setModalConfirmation({
                isOpen: true,
                title: t("Modals.Listing.PublishingWithoutAttachments.title"),
                message: t(
                  "Modals.Listing.PublishingWithoutAttachments.message"
                ),
                buttons: {
                  cancel: {
                    color: "dark",
                    label: t("CommonTables.cancel"),
                    variant: "outline",
                  },
                  confirm: {
                    label: t("PageListingCreateEdit.Buttons.publish"),
                    color: "info",
                    onClick: () => {
                      validateForm(formsData);
                      listingMutation.mutate({
                        formData: {
                          ...formsData,
                        },
                        files: state.files,
                      });
                    },
                  },
                },
              })
            );
          }
        }}
        validateOnChange={hasSubmittedEither}
        validateOnMount={true}
        validateOnBlur={true}
        validationSchema={createSchema}
      >
        {({
          values,
          handleChange,
          errors,
          handleSubmit,
          validateForm,
          setFieldValue,
        }) => {
          // Tab error flags
          const isInfoTabErrorPublish =
            state.hasSubmittedPublish &&
            (errors.title ||
              errors.category ||
              errors.Specifications ||
              errors.country ||
              errors.Specifications ||
              errors.group_id ||
              errors.deadline);

          console.log("Specification errors:", errors.Specifications);
          const isInfoTabErrorDraft =
            state.hasSubmittedDraft &&
            (errors.title ||
              errors.category ||
              errors.group_id ||
              (errors.Specifications && values.Specifications) ||
              (errors.country && values.category) ||
              (errors.deadline && values.deadline));
          const isInfoTabError = isInfoTabErrorPublish || isInfoTabErrorDraft;

          const filesMaxSizeError =
            state.files.reduce((sum, file) => sum + file.size, 0) >
            MAX_ATTACHMENT_SIZE;

          const filesMaxCountError = state.files.length > MAX_ATTACHMENTS;

          const filesTabError = filesMaxCountError || filesMaxSizeError;

          console.log("Values Specifications:", values.Specifications);

          const handleOnClickPublish = () => {
            if (state.isSubmitting) return;
            if (filesMaxCountError) {
              toastMaxAttachments();
              return;
            }
            if (filesMaxSizeError) {
              toastMaxAttachmentSize();
              return;
            }
            setHasSubmittedPublish(true);
            handleSubmit();
          };

          const handleOnClickDraft = () => {
            if (state.isSubmitting) return;
            if (filesMaxCountError) {
              toastMaxAttachments();
              return;
            }
            if (filesMaxSizeError) {
              toastMaxAttachmentSize();
              return;
            }
            setHasSubmittedDraft(true);
            validateForm(values);
            handleOnDraft({ formData: values });
          };

          const handleOnClickBack = () => {
            if (state.isSubmitting) return;
            navigateBack();
          };

          return (
            <>
              <CTabs activeItemKey={"info"}>
                <CTabList variant="tabs">
                  <CTab itemKey="info">
                    <span className={`${isInfoTabError ? "text-red-500" : ""}`}>
                      <CIcon icon={cilInfo} /> {t("PageListingCreateEdit.info")}
                    </span>
                  </CTab>
                  <CTab itemKey="attachments">
                    <span className={`${filesTabError ? "text-red-500" : ""}`}>
                      <CIcon icon={cilFile} />{" "}
                      {t("PageListingCreateEdit.attachments")}
                    </span>
                  </CTab>
                </CTabList>
                <CTabContent>
                  <CTabPanel itemKey="info" id="info-tab-panel">
                    <Form>
                      <div className="flex flex-col pt-2">
                        <ListingCreateBasicInformation
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          onGroupIdChanged={handleGroupChange}
                          isGroupSelectionRestricted={isRestrictedGroup}
                          hasSubmittedDraft={state.hasSubmittedDraft}
                          hasSubmittedPublish={state.hasSubmittedPublish}
                          groupId={+(groupId || 0)}
                          dataGroupName={dataGroupName}
                          dataGroups={dataGroups}
                          isLoadingGroups={isLoadingGroups}
                          isLoadingGroupName={isLoadingGroupName}
                        />

                        <ListingCreateSpecifications
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          hasSubmittedDraft={state.hasSubmittedDraft}
                          hasSubmittedPublish={state.hasSubmittedPublish}
                          isRestrictedBudget={state.isRestrictedBudget}
                          setIsRestrictedBudget={setIsRestrictedBudget}
                          isWithDeadline={state.isWithDeadline}
                          setIsWithDeadline={setIsWithDeadline}
                          setFieldValue={setFieldValue}
                        />

                        <ListingCreateLocation
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          hasSubmittedDraft={state.hasSubmittedDraft}
                          hasSubmittedPublish={state.hasSubmittedPublish}
                        />

                        <ListingCreateSettings
                          values={values}
                          setFieldValue={setFieldValue}
                          hasSubmittedDraft={state.hasSubmittedDraft}
                          hasSubmittedPublish={state.hasSubmittedPublish}
                        />
                      </div>
                    </Form>
                  </CTabPanel>
                  <CTabPanel itemKey="attachments" id="attachments-tab-panel">
                    <div className="flex flex-col gap-3 pt-6">
                      <ListingCreateAttachments
                        files={state.files}
                        handleAttachmentToDelete={handleAttachmentToDelete}
                        handlerFilesOnChange={handlerFilesOnChange}
                        pageRef={pageRef}
                      />
                    </div>
                  </CTabPanel>
                </CTabContent>
              </CTabs>

              <ListingCreateButtons
                disabled={state.isSubmitting}
                onClickCancel={handleOnClickBack}
                onClickDraft={handleOnClickDraft}
                onClickPublish={handleOnClickPublish}
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

type TListingCreateFormState = {
  files: File[];
  hasSubmittedPublish: boolean;
  hasSubmittedDraft: boolean;
  isSubmitting: boolean;
  isRestrictedBudget: boolean;
  isWithDeadline: boolean;
};

export type TListingCreateForm = {
  title: string;
  category: EListingCategory;
  Specifications?: TSpecification[];
  optional_specification?: string;
  country?: ECountry;
  budget?: number;
  attachments?: string[];
  deadline?: Date;
  is_hidden: boolean;
  group_id: number;
  requiredOfferType: EListingRequiredOfferType;
};

export type TGroupNameQuery = {
  name: string;
};

export default ListingCreate;
