import { TBreadcrumbKeys } from "@/components/app-breadcrumb";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TBreadcrumbsState = {
  breadcrumb: TBreadcrumbKeys;
};

const initialState: TBreadcrumbsState = {
  breadcrumb: "HOME",
};

export const breadcrumbsSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    setBreadcrumbsItems: (
      state,
      action: PayloadAction<TBreadcrumbsState["breadcrumb"]>
    ) => {
      state.breadcrumb = action.payload;
    },
  },
});

export const { setBreadcrumbsItems } = breadcrumbsSlice.actions;
export default breadcrumbsSlice.reducer;
