import { CustomPagination } from "@/components/forms/custom/custom-pagination/custom-pagination";
import { TableShadowWrapper } from "@/components/forms/custom/custom-table/table-shadow-wrapper";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { EEnrollmentStatus } from "@/enums/enrollment-enums";
import { authedFetch } from "@/utils/authed-fetch";
import { dateToYearMonthDay } from "@/utils/date-fns";
import { useAuth } from "@clerk/clerk-react";
import {
  cilCalendar,
  cilEnvelopeLetter,
  cilFlagAlt,
  cilSettings,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
  CTooltip,
} from "@coreui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { OfferStatusListingBadge } from "@/components/badges/offer-status-listing-badge";
import { TableNoData } from "@/components/forms/custom/custom-table/table-no-data";
import { EOfferStatus } from "@/enums/offer-enums";

type TColumn = {
  key: string;
  label: React.ReactNode;
};

type TQuery = {
  offers: {
    id: number,
    created_at: string;
    user_id: string;
    status: EOfferStatus;
    user_email: string;
  }[];
  count: number;
};

interface ListingViewEnrollmentsTableProps {
  onNewOffersCount?: (count: number) => void;
}

export const ListingViewEnrollmentsTable: React.FC<ListingViewEnrollmentsTableProps> = ({
  onNewOffersCount,
}) => {
  const { listingId = "0" } = useParams();
  const { t } = useAppTranslation();
  const { getToken } = useAuth();
  const [mouseOverIndex, setMouseOverIndex] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [pageSize /*, setPageSize */] = useState(10);
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery<TQuery>({
    queryKey: ["listing-enrollments", page, pageSize],
    queryFn: async ({ queryKey }) => {
      const [_key, _page, _pageSize] = queryKey;
      const pParam = _page ? `p=${_page}` : "";
      const psParam = _pageSize ? `ps=${_pageSize}` : "";

      const params = [pParam, psParam].filter((param) => param).join("&");
      return authedFetch({
        endpoint: `api/office/enrollment/offers/listing-view/${listingId}?${params}`,
        token: await getToken(),
      }).then((res) => res.json());
    },
  });

  useEffect(() => {
    if (!isLoading && onNewOffersCount && data?.offers) {
      const submittedOffers = data.offers.filter(
        (offer) => offer.status === EOfferStatus.SUBMITTED
      );
      const count = submittedOffers.length;
      onNewOffersCount(count);
    }
  }, [isLoading, data, onNewOffersCount]);

  const pageCount = Math.ceil((data?.count || 0) / 10);

  const columns: TColumn[] = [
    {
      key: "contact_email",
      label: (
        <div className="flex items-center gap-1 pl-2 flex-nowrap whitespace-nowrap">
          <CIcon icon={cilEnvelopeLetter} />{" "}
          {t("EnrollmentsTable.contactEmail")}
        </div>
      ),
    },
    {
      key: "enrolled_at",
      label: (
        <div className="flex items-center gap-1 flex-nowrap whitespace-nowrap">
          <CIcon icon={cilCalendar} /> {t("EnrollmentsTable.enrolledAt")}
        </div>
      ),
    },
    {
      key: "status",
      label: (
        <div className="flex items-center gap-1 flex-nowrap">
          <CIcon className="" icon={cilFlagAlt} /> {t("EnrollmentsTable.status")}
        </div>
      ),
    },
    {
      key: "actions",
      label: (
        <div className="flex items-center gap-1 pr-2 flex-nowrap">
          <CIcon icon={cilSettings} /> {t("CommonTables.actions")}
        </div>
      ),
    },
  ] as const;

  return (
    <div className="flex flex-col items-center w-full">
      <TableShadowWrapper>
        <CTable
          className={`bg-white shadow-lg rounded-lg  transition-all`}
          style={{
            marginBottom: 0,
          }}
        >
          <CTableBody
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto auto auto",
            }}
            onMouseLeave={() => {
              setMouseOverIndex(() => null);
            }}
          >
            <CTableRow
              className="text-base font-extrabold tracking-wider text-gray-900 uppercase bg-gray-200"
              style={{
                display: "contents",
              }}
              onMouseOver={() => {
                setMouseOverIndex(() => null);
              }}
            >
              {columns.map((column) => (
                <CTableHeaderCell
                  className="py-4 border-b-2 border-gray-400"
                  scope="col"
                  key={column.key}
                >
                  {column.label}
                </CTableHeaderCell>
              ))}
            </CTableRow>
            {isLoading ? (
              <CTableRow>
                {columns.map((_, index) => (
                  <CTableDataCell key={index}>
                    <DefaultSkeleton />
                  </CTableDataCell>
                ))}
              </CTableRow>
            ) : (
              data?.offers?.map((offer, _index) => (
                <CTableRow
                  className="text-gray-700 hover:bg-gray-100"
                  key={offer.user_id}
                  style={{
                    display: "contents",
                  }}
                  onMouseOver={() => {
                    setMouseOverIndex(() => _index);
                  }}
                  color={_index === mouseOverIndex ? "info" : ""}
                >
                  <CTableDataCell className="flex items-center ml-2">
                    {offer?.user_email}
                  </CTableDataCell>
                  <CTableDataCell className="flex items-center">
                    {dateToYearMonthDay(offer?.created_at)}
                  </CTableDataCell>
                  <CTableDataCell className="flex items-center text-xl">
                    <OfferStatusListingBadge status={offer?.status} t={t}/>
                  </CTableDataCell>
                  <CTableDataCell className="flex items-center justify-center">
                    <div className="flex gap-2">
                    <CButton
                        color="primary"
                        variant="outline"
                        onClick={() => {
                          navigate(`/listing/${listingId}/offer/${offer.id}/view`, {
                            state: {
                              status: offer.status,
                              previousState: 'offer-view', // if you need to pass additional state
                            },
                          });
                        }}
                      >
                        {t("CommonTables.show")}
                      </CButton>
                    </div>
                  </CTableDataCell>
                </CTableRow>
              ))
            )}
            <CTableRow>
              {!isLoading && !(data?.offers && data?.offers?.length > 0) && <TableNoData />}
            </CTableRow>
          </CTableBody>
        </CTable>
        {!isLoading && data?.count === 0 && (
          <div className="flex flex-col items-center justify-center h-64">
            <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg shadow-lg">
              <p className="text-xl font-bold text-gray-700">
                No Data Available
              </p>
              <p className="mt-2 text-center text-gray-500">
                There is currently no data to display. Create your first item!
              </p>
              <p className="mt-2 text-center text-gray-500">
                In case of issues, reach out to{" "}
                <a
                  href="mailto:support@machinevertical.com"
                  className="text-blue-500 underline"
                >
                  support@machinevertical.com
                </a>
              </p>
            </div>
          </div>
        )}
      </TableShadowWrapper>

      <CustomPagination
        pageCount={pageCount}
        currentPage={page}
        onPageChange={setPage}
      />
    </div>
  );
};
