import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { MyEnrollmentsTable } from "./components/my-enrollments-table";

export const PageEnrollments = () => {
  useBreadcrumbs("MY_ENROLLMENTS");

  return (
    <div>
      <MyEnrollmentsTable />
    </div>
  );
};
