type TAuthedFetchProps = {
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "HEAD";
  body?: any;
  endpoint: string;
  token: string | null;
  stringifyBody?: boolean;
  contentUrlEncoded?: boolean;
};

export const authedFetch = (props: TAuthedFetchProps) => {
  const {
    method = "GET",
    body,
    endpoint,
    token,
    stringifyBody = true,
    contentUrlEncoded = false,
  } = props;
  // @ts-ignore
  const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

  // const prodApiEndpoint =
  //   "https://www.master-7rqtwti-pqmiyv3rh6mbo.eu-5.platformsh.site";

  return fetch(`${apiEndpoint}/${endpoint}`, {
    method,
    body: stringifyBody ? JSON.stringify(body) : body,
    headers: {
      "Content-Type": contentUrlEncoded
        ? "application/x-www-form-urlencoded"
        : "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
