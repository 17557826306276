import { TListingEditForm } from "../listing-view-edit";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import {
  CountrySelect,
  mapCountryWithIconOption,
} from "@/components/forms/custom/custom-select/country-select";
import { ECountry } from "@/enums/location-enums";
import CIcon from "@coreui/icons-react";

type TListingViewLocationProps = {
  valueCountry: TListingEditForm["country"];
  errorCountry?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  hasSubmittedPublish: boolean;
  hasSubmittedDraft: boolean;
  isEditing: boolean;
  isLoading?: boolean;
};

export const ListingViewLocation = (props: TListingViewLocationProps) => {
  const {
    valueCountry,
    setFieldValue,
    hasSubmittedPublish,
    hasSubmittedDraft,
    isEditing,
    isLoading,
    errorCountry,
  } = props;
  const { t } = useAppTranslation();

  const hasSubmittedEither = hasSubmittedDraft || hasSubmittedPublish;

  const titleCountry = t("PageListingCreateEdit.Location.country");
  const titleRegion = t("PageListingCreateEdit.Location.region");

  const countryOption = mapCountryWithIconOption(valueCountry as ECountry);

  if (isLoading) {
    return (
      <div className="flex w-full gap-2 p-3 border border-gray-200 rounded-lg">
        <div className="flex-1">
          <label className="form-label">{titleCountry}</label>
          <DefaultSkeleton
            className="px-[0px] py-[0px] max-w-full"
            classNameInner="h-[30px] my-[4px]"
          />
        </div>
        <div className="flex-1">
          <label className="form-label">{titleRegion}</label>
          <DefaultSkeleton
            className="px-[0px] py-[0px] max-w-full"
            classNameInner="h-[30px] my-[4px]"
          />
        </div>
      </div>
    );
  }

  if (!isEditing) {
    return (
      <div className="flex w-full gap-2 p-3 border border-gray-200 rounded-lg">
        <div className="flex-1">
          <label className="form-label">{titleCountry}</label>
          <p className="px-[12px] py-[6px] mb-0 border rounded min-h-[38px]">
            {countryOption?.icon.length ? (
              <CIcon icon={countryOption.icon} />
            ) : null}{" "}
            {countryOption?.label}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-2 p-3 border border-gray-200 rounded-lg">
      <CountrySelect
        label={t("PageListingCreateEdit.Location.country")}
        value={valueCountry}
        onChange={setFieldValue}
        valid={!errorCountry && hasSubmittedEither}
        invalid={!!errorCountry && hasSubmittedEither}
        feedbackInvalid={errorCountry}
      />
    </div>
  );
};
