// GroupTile.tsx
import React from "react";
import { cilBan, cilFlagAlt, cilSearch } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CTooltip } from "@coreui/react";
import { dateToYearMonthDay } from "@/utils/date-fns";
import { Group } from "./types";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";

interface GroupTileProps {
  group: Group;
  onView: (id: number) => void;
  onDelete: (group: Group) => void;
}

export const GroupTile: React.FC<GroupTileProps> = ({
  group,
  onView,
  onDelete,
}) => {
  const { t } = useAppTranslation();

  return (
    <div className="p-4 bg-white rounded-lg shadow group-tile">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-bold">
          {group.is_default
            ? t("PageListingGroups.ListingGroupsTable.defaultName")
            : group.name}
        </h3>
        {group.is_default ? (
          <CIcon icon={cilFlagAlt} className="text-primary" />
        ) : (
          ""
        )}
      </div>

      <p className="text-gray-600">
        {t("PageListingGroups.ListingGroupsTable.createdBy")}:{" "}
        {group.created_by}
      </p>
      <p className="text-gray-600">
        {t("PageListingGroups.ListingGroupsTable.createdAt")}:{" "}
        {dateToYearMonthDay(group.created_at)}
      </p>
      <div className="flex mt-4 space-x-2">
        <CTooltip content={t("CommonTooltips.view")}>
          <CButton color="info" onClick={() => onView(group.id)}>
            <CIcon icon={cilSearch} className="text-white" />
          </CButton>
        </CTooltip>
        <CTooltip
          content={
            group.is_default
              ? t("CommonTooltips.cantDeleteDefaultGroup")
              : t("CommonTooltips.remove")
          }
        >
          <div>
            <CButton
              color={group.is_default ? "secondary" : "danger"}
              disabled={group.is_default}
              onClick={() => onDelete(group)}
            >
              <CIcon icon={cilBan} className="text-white" />
            </CButton>
          </div>
        </CTooltip>
      </div>
    </div>
  );
};
