import { ALLOWED_ATTACHMENT_FILETYPES } from "@/constants/form-constatns";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { CFormInput, CInputGroup, CInputGroupText } from "@coreui/react";
import { useRef } from "react";

type TCustomFileInputProps = React.ComponentProps<typeof CFormInput> & {
  showPreview?: boolean;
  files?: File[];
  divWrap?: boolean;
  divWrapProps?: React.ComponentProps<"div">;
  isEditing?: boolean;
  rightSide?: {
    element: React.ReactNode;
    isError: boolean;
  }[];
};

export const CustomFileInput = (props: TCustomFileInputProps) => {
  const {
    showPreview,
    files,
    label,
    multiple,
    className,
    isEditing,
    rightSide = [],
    ...propsRest
  } = props;
  const { t } = useAppTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  let inputText = t("AttachmentsTable.Input.noFileSelected");

  if (files && files.length) {
    inputText =
      files.length > 1
        ? t("AttachmentsTable.Input.countFiles", { count: files.length })
        : files[0].name;
  }

  if (isEditing) {
    return (
      <div>
        <CFormInput
          ref={inputRef}
          type="file"
          accept={ALLOWED_ATTACHMENT_FILETYPES.join(",")}
          multiple={multiple}
          {...propsRest}
          style={{ display: "none" }}
        />
        <CInputGroup
          className="cursor-pointer w-ful"
          onClick={() => inputRef.current?.click()}
        >
          <CInputGroupText>
            {t("AttachmentsTable.Input.selectFiles")}
          </CInputGroupText>
          <CFormInput
            value={inputText}
            onFocus={(e) => e.target.blur()}
            className="cursor-pointer "
          />
          {rightSide.length
            ? rightSide.map((item) => {
                let backgroundColor;
                if (item.isError) {
                  backgroundColor = "#ef4444";
                }

                let color;
                if (item.isError) {
                  color = "#fff";
                }

                return (
                  <CInputGroupText
                    style={{ backgroundColor, color, position: "relative" }}
                  >
                    {item.element}
                  </CInputGroupText>
                );
              })
            : null}
        </CInputGroup>
      </div>
    );
  }

  return null;
};
