import { TableShadowWrapper } from "@/components/forms/custom/custom-table/table-shadow-wrapper";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { authedFetch } from "@/utils/authed-fetch";
import { useAuth } from "@clerk/clerk-react";
import {
  CBadge,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableRow,
} from "@coreui/react";
import { useState } from "react";

type TAttachment = {
  indexOfNew?: number | null;
  fileUrl: string;
  name: string;
  size: string;
  type: string;
  uploadedAt: string;
  uploadedBy?: string;
  markedToDelete?: boolean;
  actions?: React.ReactNode;
};

type TAttachmentTableProps = {
  attachments: TAttachment[];
  isWithActions?: boolean;
  isEditing?: boolean;
  isRed?: boolean;
  isEquipmentProvider?: boolean;
};

export const AttachmentTable = ({
  attachments,
  isWithActions,
  isEditing,
  isRed,
  isEquipmentProvider,
}: TAttachmentTableProps) => {
  const { t } = useAppTranslation();
  const { getToken } = useAuth();
  const [mouseOverIndex, setMouseOverIndex] = useState<number | string | null>(
    null
  );

  const handlerOpenFile = async (fileUrl: string) => {
    authedFetch({
      endpoint: `api/${fileUrl}`,
      token: await getToken(),
    }).then((res) => {
      res.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      });
    });
  };

  if (attachments.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center sm:py-6">
        <span>{t("AttachmentsTable.noAttachments")}</span>
      </div>
    );
  }

  return (
    <>
      <TableShadowWrapper isRed={isRed}>
        <CTable
          className={`bg-white shadow-lg rounded-lg transition-all`}
          style={{
            marginBottom: 0,
          }}
        >
          <CTableBody
            style={{
              display: "grid",
              gridTemplateColumns: `1fr auto auto auto ${isEquipmentProvider ? "auto" : "auto"} ${isWithActions ? "auto" : ""}`,
              padding: "6px",
            }}
            onMouseLeave={() => {
              setMouseOverIndex(() => null);
            }}
          >
            <CTableRow
              className="text-sm font-extrabold tracking-wider text-gray-900 bg-gray-200"
              style={{
                display: "contents",
              }}
              onMouseOver={() => {
                setMouseOverIndex(() => null);
              }}
            >
              <CTableDataCell>{t("AttachmentsTable.name")}</CTableDataCell>
              <CTableDataCell>{t("AttachmentsTable.type")}</CTableDataCell>
              <CTableDataCell>{t("AttachmentsTable.size")}</CTableDataCell>
              <CTableDataCell>
                <div className=" whitespace-nowrap">
                  {t("AttachmentsTable.uploadedAt")}
                </div>
              </CTableDataCell>
                <CTableDataCell>
                </CTableDataCell>
              {isWithActions && (
                <CTableDataCell>{t("CommonTables.actions")}</CTableDataCell>
              )}
            </CTableRow>
            {attachments.map((attachment, index) => (
              <CTableRow
                className="text-gray-700 hover:bg-gray-100"
                key={attachment?.fileUrl ?? index}
                style={{
                  display: "contents",
                }}
                onMouseOver={() => {
                  setMouseOverIndex(() => index);
                }}
                color={
                  attachment.indexOfNew !== null
                    ? "success"
                    : attachment.markedToDelete
                      ? "danger"
                      : index === mouseOverIndex
                        ? "info"
                        : ""
                }
              >
                <CTableDataCell className="py-1">
                  <div
                    className={`flex flex-col items-start justify-center w-full h-full text-sm whitespace-nowrap`}
                    style={{
                      minHeight: isEditing ? "40px" : "31px",
                    }}
                  >
                    {attachment.indexOfNew === null ||
                    attachment.indexOfNew === undefined ? (
                      <button
                        className="text-blue-500 underline cursor"
                        type="button"
                        onClick={() => handlerOpenFile(attachment?.fileUrl)}
                      >
                        {attachment?.name}
                      </button>
                    ) : (
                      <a
                        href={attachment?.fileUrl}
                        target="_blank"
                        className=""
                      >
                        {attachment?.name}
                      </a>
                    )}

                    {attachment.markedToDelete && (
                      <span className="text-red-500">
                        {t("AttachmentsTable.willBeRemovedOnSave")}
                      </span>
                    )}
                    {attachment.indexOfNew !== null && (
                      <span className="text-green-500">
                        {t("AttachmentsTable.willBeAddedOnSave")}
                      </span>
                    )}
                  </div>
                </CTableDataCell>
                <CTableDataCell className="py-1">
                  <span className="flex items-center justify-start w-full h-full">
                    <CBadge color="secondary">{attachment?.type}</CBadge>
                  </span>
                </CTableDataCell>
                <CTableDataCell className="py-1">
                  <span className="flex items-center justify-end w-full h-full text-sm whitespace-nowrap">
                    {attachment?.size}
                  </span>
                </CTableDataCell>
                <CTableDataCell className="py-1">
                  <span className="flex items-center justify-start w-full h-full text-sm whitespace-nowrap">
                    {attachment?.uploadedAt}
                  </span>
                </CTableDataCell>
                  <CTableDataCell className="py-1">
                  </CTableDataCell>
                {isWithActions && (
                  <CTableDataCell className="flex items-center justify-center py-1">
                    {attachment?.actions}
                  </CTableDataCell>
                )}
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
      </TableShadowWrapper>
    </>
  );
};
