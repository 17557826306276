import { EListingStatus } from "@/enums/listing-enums";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { CBadge } from "@coreui/react";

type TCustomStatusBadgeProps = {
  status?: EListingStatus;
  size?: "sm";
};

export const CustomStatusBadge = (props: TCustomStatusBadgeProps) => {
  const { status } = props;
  const { t } = useAppTranslation();

  switch (status) {
    case EListingStatus.OPEN:
      return <CBadge color="warning">{t("Enums.ListingStatus.open")}</CBadge>;
    case EListingStatus.DRAFT:
      return (
        <CBadge color="secondary">{t("Enums.ListingStatus.draft")}</CBadge>
      );
    case EListingStatus.CLOSED:
      return <CBadge color="success">{t("Enums.ListingStatus.closed")}</CBadge>;
    default:
      return <CBadge color="secondary">{status}</CBadge>;
  }
};
