import {
  MAX_ATTACHMENT_SIZE,
  MAX_ATTACHMENTS,
} from "@/constants/form-constatns";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { useAppDispatch } from "@/store/hooks";
import { addToastMain, TToastState } from "@/store/toast/slice";
import { integerToFilesize } from "@/utils/integer-to-filesize";

export const useToastsListingCreate = () => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();

  const dispatchToastAction = ({
    mainToast = "custom_toast",
    mainToastColor,
    mainToastContent,
  }: {
    mainToast?: TToastState["mainToast"];
    mainToastColor: TToastState["mainToastColor"];
    mainToastContent: TToastState["mainToastContent"];
  }) => {
    dispatch(
      addToastMain({
        mainToast,
        mainToastColor,
        mainToastContent,
      })
    );
  };

  const toastBackendRensponseError = (res: Response) =>
    dispatchToastAction({
      mainToastColor: "danger",
      mainToastContent: `${res.status}: ${res.statusText}`,
    });

  const toastBackendError = (error: Error) =>
    dispatchToastAction({
      mainToastColor: "danger",
      mainToastContent: error.message,
    });

  const toastDraftCreated = () =>
    dispatchToastAction({
      mainToastColor: "success",
      mainToastContent: t("Toasts.Listing.draftCreateSuccess"),
    });

  const toastPublishCreated = () =>
    dispatchToastAction({
      mainToastColor: "success",
      mainToastContent: t("Toasts.Listing.createSuccess"),
    });

  const toastMaxAttachments = () =>
    dispatchToastAction({
      mainToastColor: "danger",
      mainToastContent: t("Toasts.Listing.maxAttachmentsAllowed", {
        count: MAX_ATTACHMENTS,
      }),
    });

  const toastMaxAttachmentSize = () =>
    dispatchToastAction({
      mainToastColor: "danger",
      mainToastContent: t("Toasts.Listing.fileTooLarge", {
        size: integerToFilesize(MAX_ATTACHMENT_SIZE),
      }),
    });

  return {
    toastBackendRensponseError,
    toastBackendError,
    toastDraftCreated,
    toastPublishCreated,
    toastMaxAttachments,
    toastMaxAttachmentSize,
  };
};
