import { CustomButton } from "@/components/forms/custom/custom-button/custom-button";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { cilBan, cilCheckCircle, cilFile } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

type TListingCreateButtonsProps = {
  disabled?: boolean;
  onClickCancel: () => void;
  onClickDraft: () => void;
  onClickPublish: () => void;
};

export const ListingCreateButtons = (props: TListingCreateButtonsProps) => {
  const { disabled, onClickCancel, onClickDraft, onClickPublish } = props;
  const { t } = useAppTranslation();

  return (
    <div className="flex justify-end gap-2 pt-5">
      <CustomButton
        type="button"
        color="secondary"
        variant="outline"
        className="flex-1 sm:flex-initial"
        disabled={disabled}
        onClick={onClickCancel}
      >
        <span className="flex items-center justify-center gap-1 min-w-16">
          <CIcon icon={cilBan} />
          <span>{t("CommonTables.cancel")}</span>
        </span>
      </CustomButton>
      <CustomButton
        type="button"
        disabled={disabled}
        onClick={onClickDraft}
        color="info"
        variant="outline"
        className="flex-1 sm:flex-initial"
        style={{
          padding: 0,
        }}
      >
        <span className="flex items-center justify-center gap-1 px-[12px] py-[6px] min-w-16 hover:text-white">
          <CIcon icon={cilFile} />
          <span>{t("PageListingCreateEdit.Buttons.saveAsDraft")}</span>
        </span>
      </CustomButton>
      <CustomButton
        type="button"
        color="info"
        className="flex-1 text-white sm:flex-initial"
        disabled={disabled}
        onClick={onClickPublish}
      >
        <span className="flex items-center justify-center gap-1 min-w-16">
          <CIcon icon={cilCheckCircle} />
          <span>{t("PageListingCreateEdit.Buttons.publish")}</span>
        </span>
      </CustomButton>
    </div>
  );
};
