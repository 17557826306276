import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export const dateToUTC = (date: Date) =>
  Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

export const dateUTCtoEETString = (date: Date | string) =>
  new Date(date).toLocaleString("en-GB", {
    timeZone: "Europe/Istanbul",
  });

export const dateUtcToLocal = (utcTimestamp: string): string => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const localDate = dayjs.utc(utcTimestamp).local().format("YYYY-MM-DD HH:mm");
  return localDate;
};

export const dateToYearMonthDay = (date: Date | string) => {
  if (date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const getMonth = d.getMonth() + 1;
    const month = ("0" + getMonth).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  } else {
    return "-";
  }
};

export const dateToYearMonthDayTime = (date: Date | string) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const getMonth = d.getMonth() + 1;
  const month = ("0" + getMonth).slice(-2);
  const day = ("0" + d.getDate()).slice(-2);
  const hour = ("0" + d.getHours()).slice(-2);
  const minute = ("0" + d.getMinutes()).slice(-2);
  return `${year}-${month}-${day} ${hour}:${minute}`;
};
