import { TListingEditForm } from "../listing-view-edit";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { EListingRequiredOfferType } from "@/enums/listing-enums";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { OfferTypeBadge } from "@/components/badges/listing-offer-type-badge";
import { RequiredAsterist } from "@/components/forms/custom/required-asterisk/required-asterisk";
import { CFormCheck } from "@coreui/react";

type TListingCustomOfferTypeSelectProps = {
  value: TListingEditForm["requiredOfferType"];
  error?: string;
  hasSubmittedEither: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  isLoading?: boolean;
  isEditing: boolean;
};

export const ListingViewEditOfferTypeSelect = (
  props: TListingCustomOfferTypeSelectProps
) => {
  const {
    value,
    error,
    hasSubmittedEither,
    setFieldValue,
    isLoading,
    isEditing,
  } = props;
  const { t } = useAppTranslation();

  const offerTypeLabel = t("PageListingCreateEdit.BasicInformation.offerType");

  if (isLoading) {
    return (
      <div className="mb-4">
        <label className="form-label">{offerTypeLabel}</label>
        <DefaultSkeleton
          className="px-[0px] py-[0px] max-w-full"
          classNameInner="h-[30px]"
        />
      </div>
    );
  }

  if (!isEditing) {
    return (
      <div className="mb-4 ">
        <label className="form-label">{offerTypeLabel}</label>

        <p className="px-2 pt-[6px] pb-[4px] h-[38px] text-xl">
          <OfferTypeBadge offerType={value as EListingRequiredOfferType} />
        </p>
      </div>
    );
  }

  return (
    <div className="mt-6 mb-4">
          <label className="form-label">
            {t("PageListingCreateEdit.BasicInformation.listingRequiredOfferType")}
            <RequiredAsterist />
          </label>
          <div>
            <CFormCheck
              type="radio"
              name="equipmentOfferType"
              id="equipmentOfferTypeBoth"
              value={EListingRequiredOfferType.Both}
              label={t("Enums.ListingRequiredOfferType.both")}
              checked={value === EListingRequiredOfferType.Both}
              onChange={() => setFieldValue("requiredOfferType", EListingRequiredOfferType.Both)}
            />
            <CFormCheck
              type="radio"
              name="equipmentOfferType"
              id="equipmentOfferTypeNewOnly"
              value={EListingRequiredOfferType.NewOnly}
              label={t("Enums.ListingRequiredOfferType.newOnly")}
              checked={value === EListingRequiredOfferType.NewOnly}
              onChange={() => setFieldValue("requiredOfferType", EListingRequiredOfferType.NewOnly)}
            />
            <CFormCheck
              type="radio"
              name="equipmentOfferType"
              id="equipmentOfferTypeUsedOnly"
              value={EListingRequiredOfferType.UsedOnly}
              label={t("Enums.ListingRequiredOfferType.usedOnly")}
              checked={value === EListingRequiredOfferType.UsedOnly}
              onChange={() => setFieldValue("requiredOfferType", EListingRequiredOfferType.UsedOnly)}
            />
          </div>
        </div>
  );
};