import { authedFetch } from "@/utils/authed-fetch";
import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";

export type TQueryExistingAttachments = {
  attachment_url: string;
  display_name: string;
  created_at: string;
  created_by: string;
  size: number;
};

type TQueryExistingAttachmentsProps = {
  offerId?: number;
  listingId?: number;
};

export const useQueryExistingAttachments = ({
  offerId, listingId
}: TQueryExistingAttachmentsProps) => {
  const { getToken } = useAuth();

  return useQuery<TQueryExistingAttachments[]>({
    queryKey: ["existing-offer-attachments", offerId],
    queryFn: async () => {
      const token = await getToken();
      return authedFetch({
        endpoint: listingId ? `api/office/offer-attachment/listing/${listingId}/offer/${offerId}/attachments` : `api/provider/offer-attachment/${offerId}/attachments`,
        token,
      }).then((res) => res.json());
    },
    enabled: !!offerId,
  });
};
