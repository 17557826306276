import { CFormSwitch } from "@coreui/react";
import { TListingEditForm } from "../listing-view-edit";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";

type TListingViewSettingsProps = {
  valueHidden: TListingEditForm["is_hidden"];
  onHiddenChange: () => void;
  hasSubmittedEither: boolean;
  isLoading?: boolean;
  isEditing: boolean;
};

const titleHidden = "Hidden";
const titleParticipants = "Participants";

export const ListingViewSettings = (props: TListingViewSettingsProps) => {
  const {
    valueHidden,
    onHiddenChange,
    hasSubmittedEither,
    isLoading,
    isEditing,
  } = props;

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4 p-3 border border-gray-200 rounded-lg">
        <div className="">
          <label className="form-label">{titleHidden}</label>
          <DefaultSkeleton
            className="px-[0px] py-[0px] max-w-12"
            classNameInner="h-[24px] my-[2px]"
          />
        </div>
        <div className="">
          <label className="form-label">{titleParticipants}</label>
          <DefaultSkeleton
            className="px-[0px] pt-[0px] pb-[16px] max-w-full"
            classNameInner="h-[28px] mb-0 mt-0"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 p-3 border border-gray-200 rounded-lg">
      <div className="">
        <label className="form-label">{titleHidden}</label>
        <CFormSwitch
          size="xl"
          valid={hasSubmittedEither}
          onChange={onHiddenChange}
          value={valueHidden ? 1 : 0}
          checked={valueHidden}
          disabled={isEditing}
        />
      </div>
    </div>
  );
};
