import { ECountry } from "@/enums/location-enums";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { cifEe, cifIt, cifLt, cifLv, cifPl } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { twMerge } from "tailwind-merge";

type TCountryOption = {
  icon: string[];
  label: string;
  value: ECountry;
};

type TCountrySelectProps = {
  label: string;
  value?: ECountry;
  onChange: (field: string, value: any, shouldValidate?: boolean) => void;
  feedbackInvalid?: string;
  valid: boolean;
  invalid: boolean;
};

export const mapCountryWithIconOption = (country: ECountry) => {
  const { t } = useAppTranslation();

  switch (country) {
    case ECountry.LT:
      return {
        icon: cifLt,
        label: t("Enums.Country.lt"),
        value: ECountry.LT,
      };
    case ECountry.PL:
      return {
        icon: cifPl,
        label: t("Enums.Country.pl"),
        value: ECountry.PL,
      };
    case ECountry.LV:
      return {
        icon: cifLv,
        label: t("Enums.Country.lv"),
        value: ECountry.LV,
      };
    case ECountry.EE:
      return {
        icon: cifEe,
        label: t("Enums.Country.ee"),
        value: ECountry.EE,
      };
    case ECountry.IT:
      return {
        icon: cifIt,
        label: t("Enums.Country.it"),
        value: ECountry.IT,
      };
    default:
      return {
        icon: [],
        label: country,
        value: ECountry.LT,
      };
  }
};

export const CountrySelect = ({
  label,
  value,
  onChange,
  feedbackInvalid,
  invalid,
  valid,
}: TCountrySelectProps) => {
  const { t } = useAppTranslation();

  const options: TCountryOption[] = [
    ECountry.LT,
    ECountry.PL,
    ECountry.LV,
    ECountry.EE,
    ECountry.IT,
  ].map((country) => mapCountryWithIconOption(country));

  const displayOption = options.find((option) => option.value === value);

  const display = displayOption!! ? (
    <div className="inline-block">
      <CIcon icon={displayOption?.icon} size="xl" className="mb-[-3px]" />
      <span className="px-1">{displayOption?.label}</span>
    </div>
  ) : (
    <div className="inline-block">
      <span>{t("CommonSelect.emptyCountry")}</span>
    </div>
  );

  const isValidStyles = valid ? "border-success" : "";
  const isInvalidStyles = invalid ? "border-danger" : "";

  return (
    <div className="flex flex-col">
      <label className="form-label">{label}</label>

      <CDropdown>
        <CDropdownToggle
          variant="outline"
          className={twMerge(
            "p-1 bg-white h-[38px]",
            isValidStyles,
            isInvalidStyles
          )}
        >
          {display}
        </CDropdownToggle>

        <CDropdownMenu>
          {options.map((option) => (
            <CDropdownItem
              key={option.value}
              className="cursor-pointer"
              active={option.value === value}
              onClick={() => onChange("country", option.value)}
            >
              <CIcon icon={option.icon} size="xl" className="mb-[-3px]" />{" "}
              {option.label}
            </CDropdownItem>
          ))}
        </CDropdownMenu>
      </CDropdown>
      {invalid && (
        <label className="text-sm text-danger">{feedbackInvalid}</label>
      )}
    </div>
  );
};
