import { DEFAULT_TOAST_DURATION } from "@/constants/toast-constants";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { clearToastMain } from "@/store/toast/slice";
import { CToast, CToastBody, CToastClose } from "@coreui/react";
import { useEffect, useMemo } from "react";
import CIcon from "@coreui/icons-react";
import { cilCheckCircle, cilWarning } from "@coreui/icons";

export const CustomToast = () => {
  const dispatch = useAppDispatch();
  const mainToastContent = useAppSelector(
    (state) => state.toast.mainToastContent
  );
  const mainToastColor = useAppSelector((state) => state.toast.mainToastColor);
  const mainToastDuration = useAppSelector(
    (state) => state.toast.mainToastDuration
  );

  const contentMemoized = useMemo(() => mainToastContent, []);
  const colorMemoized = useMemo(() => mainToastColor, []);
  const durationMemoized = useMemo(() => mainToastDuration, []);

  let bgColor;
  let iconRef;

  switch (colorMemoized) {
    case "success":
      bgColor = "bg-green-500";
      iconRef = cilCheckCircle;
      break;
    case "danger":
      bgColor = "bg-red-500";
      iconRef = cilWarning;
      break;
    case "warning":
      bgColor = "bg-yellow-500";
      break;
    case "info":
      bgColor = "bg-blue-500";
      
      break;
    default:
      bgColor = "bg-gray-500";
  }

  useEffect(() => {
    dispatch(clearToastMain());
  }, []);

  return (
    <CToast
      autohide={true}
      visible={true}
      animation={true}
      delay={durationMemoized ?? DEFAULT_TOAST_DURATION}
      className="align-items-center rounded-md shadow-lg"
    >
      <div className={`d-flex ${bgColor} text-white`}>
        <CToastBody><CIcon icon={iconRef} className="me-2 my-auto" /> {contentMemoized}</CToastBody>
        <CToastClose className="me-2 m-auto" />
      </div>
    </CToast>
  );
};
