import { FormikErrors } from "formik";
import { TListingCreateForm } from "../listing-create";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { CountrySelect } from "@/components/forms/custom/custom-select/country-select";

type TListingCreateLocationProps = {
  values: TListingCreateForm;
  errors: FormikErrors<TListingCreateForm>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  hasSubmittedDraft: boolean;
  hasSubmittedPublish: boolean;
};

export const ListingCreateLocation = ({
  values,
  errors,
  setFieldValue,
  hasSubmittedDraft,
  hasSubmittedPublish,
}: TListingCreateLocationProps) => {
  const { t } = useAppTranslation();
  const hasSubmittedEither = hasSubmittedDraft || hasSubmittedPublish;

  return (
    <>
      <h5 className="px-2 pt-4 pb-0">{t("PageListingCreateEdit.location")}</h5>

      <div className="flex gap-2 p-3 border border-gray-200 rounded-lg">
        <CountrySelect
          label={t("PageListingCreateEdit.Location.country")}
          value={values.country}
          onChange={setFieldValue}
          valid={!errors.country && hasSubmittedEither}
          invalid={!!errors.country && hasSubmittedEither}
          feedbackInvalid={errors.country}
        />
      </div>
    </>
  );
};
