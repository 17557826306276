import { integerToFilesize } from "@/utils/integer-to-filesize";

import { CustomFileInput } from "@/components/attachments/custom-file-input/custom-file-input";
import {
  MAX_ATTACHMENT_SIZE,
  MAX_ATTACHMENTS,
} from "@/constants/form-constatns";
import { Trans } from "react-i18next";
import { ChangeEvent, useEffect, useRef } from "react";
import { useToastsDiscover } from "../../../hooks/use-toasts-discover";

type TOfferCreateEditViewFileInputProps = {
  files: File[];
  totalSizeSum: number;
  totalEstimatedAttachmentsCount: number;
  setFiles: (files: File[]) => void;
  isTooManyEstimatedAttachments: boolean;
};

export const OfferCreateEditViewFileInput = ({
  files,
  totalSizeSum,
  totalEstimatedAttachmentsCount,
  setFiles,
  isTooManyEstimatedAttachments,
}: TOfferCreateEditViewFileInputProps) => {
  const { toastMaxAttachments, toastMaxAttachmentSize } = useToastsDiscover();
  const fileInputFormRef = useRef<HTMLFormElement>(null);

  const clearFiles = ({ cb }: { cb?: () => void }) => {
    setFiles([]);
    cb?.();
  };

  const handlerFilesOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const tempState: File[] = [...files];
    if (e.target.files!.length > MAX_ATTACHMENTS) {
      toastMaxAttachments();
    } else {
      for (let i = 0, len = e.target.files!.length; i < len; i++) {
        if (e.target.files![i].size < MAX_ATTACHMENT_SIZE) {
          tempState.push(e.target.files![i]);
        } else {
          clearFiles({
            cb: () => {
              toastMaxAttachmentSize();
            },
          });
          break;
        }
      }
      setFiles([...tempState]);
    }
  };

  useEffect(() => {
    if (files.length === 0) {
      fileInputFormRef.current?.reset();
    }
  }, [files]);

  return (
    <form ref={fileInputFormRef}>
      <CustomFileInput
        id="file"
        multiple
        isEditing
        files={files}
        onChange={handlerFilesOnChange}
        rightSide={[
          {
            element: (
              <span className="flex gap-1">
                <span className="font-bold">
                  {integerToFilesize(totalSizeSum)}
                </span>
                <span>/</span>
                <span className="font-bold">
                  {integerToFilesize(MAX_ATTACHMENT_SIZE)}
                </span>
              </span>
            ),
            isError: totalSizeSum > MAX_ATTACHMENT_SIZE,
          },
          {
            element: (
              <span>
                <Trans
                  i18nKey={
                    "AttachmentsTable.Input.offerWillHaveCountAttachments"
                  }
                  values={{ count: totalEstimatedAttachmentsCount }}
                  components={{ 1: <span className="font-bold" /> }}
                />
              </span>
            ),
            isError: isTooManyEstimatedAttachments,
          },
        ]}
      />
    </form>
  );
};
