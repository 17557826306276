export enum EUserAccountType {
  CLIENT_USER = "CLIENT_USER",
  ADMIN_USER = "MV_ADMIN_USER",
}

export enum EUserContactInfoType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export enum EUserEPIndustryType {
  FURNITURE = "FURNITURE",
  WINDOWS = "WINDOWS",
  HOUSE_PARTS = "HOUSE_PARTS",
  TEXTILE = "TEXTILE",
}
