import { TListingEditForm } from "../listing-view-edit";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { dateToYearMonthDay } from "@/utils/date-fns";
import CIcon from "@coreui/icons-react";
import { cilCalendar } from "@coreui/icons";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import {
  CFormInput,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";

type TListingViewDeadlineProps = {
  dataValue: TListingEditForm["deadline"];
  value: TListingEditForm["deadline"];
  error?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setIsWithDeadline: (isWithDeadline: boolean) => void;
  isWithDeadline: boolean;
  hasSubmittedPublish: boolean;
  hasSubmittedDraft: boolean;
  isEditing: boolean;
  isLoading?: boolean;
};

export const ListingViewDeadline = (props: TListingViewDeadlineProps) => {
  const {
    dataValue,
    value,
    error,
    setFieldValue,
    handleChange,
    setIsWithDeadline,
    isWithDeadline,
    hasSubmittedPublish,
    hasSubmittedDraft,
    isEditing,
    isLoading,
  } = props;
  const { t } = useAppTranslation();

  const labelTitle = t("PageListingCreateEdit.Specifications.deadline");

  const valid =
    !error && (hasSubmittedPublish || (hasSubmittedDraft && !!value));
  const invalid =
    !!error && (hasSubmittedPublish || (hasSubmittedDraft && !!value));

  if (isLoading) {
    return (
      <div className="mb-4">
        <label className="form-label">{labelTitle}</label>
        <DefaultSkeleton
          className="px-[0px] py-[0px] w-[140px]"
          classNameInner="h-[30px]"
        />
      </div>
    );
  }

  if (!isEditing && value) {
    return (
      <div className="mb-4 ">
        <label className="form-label">{labelTitle}</label>
        <p className="px-1 py-[7px] flex items-center gap-2 font-bold">
          <CIcon icon={cilCalendar} />
          {dateToYearMonthDay(value || "")}
        </p>
      </div>
    );
  }

  if (!isEditing && !value) return null;

  return (
    <div className="mb-4 max-w-64">
      <label className="form-label">
        {t("PageListingCreateEdit.Specifications.deadline")}
      </label>
      <div className="flex">
        <CInputGroup className="w-20 rounded sm:flex-1">
          <CInputGroupText
            style={
              isWithDeadline
                ? {}
                : {
                    border: "transparent",
                  }
            }
          >
            <CFormSwitch
              size="xl"
              onChange={() => {
                setIsWithDeadline(!isWithDeadline);
                if (isWithDeadline) {
                  setFieldValue("deadline", null);
                } else {
                  setFieldValue("deadline", dataValue ? dataValue : null);
                }
              }}
              checked={isWithDeadline}
            />
          </CInputGroupText>
          {isWithDeadline && (
            <>
              <CFormInput
                className="!rounded-r-md"
                id="deadline"
                type="date"
                disabled={!isWithDeadline}
                value={value?.toString?.() || ""}
                onChange={handleChange}
                min={
                  new Date(new Date().setDate(new Date().getDate() + 1))
                    .toISOString()
                    .split("T")[0]
                }
                valid={valid}
                invalid={invalid}
                feedbackInvalid={error}
              />
            </>
          )}
        </CInputGroup>
      </div>
    </div>
  );
};
