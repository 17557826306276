import { EListingCategory } from "@/enums/listing-enums";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { twMerge } from "tailwind-merge";

type TCategoryOption = {
  label: string;
  value: EListingCategory;
};

type TCategorySelectProps = {
  label?: string;
  value?: EListingCategory;
  onChange: (category?: EListingCategory) => void;
  feedbackInvalid?: string;
  valid: boolean;
  invalid: boolean;
};

export const CategorySelect = ({
  label,
  value,
  onChange,
  feedbackInvalid,
  invalid,
  valid,
}: TCategorySelectProps) => {
  const { t } = useAppTranslation();

  const options: TCategoryOption[] = [
    {
      label: t("Enums.ListingCategory.cnc"),
      value: EListingCategory.CNC,
    },
    {
      label: t("Enums.ListingCategory.woodMachinery"),
      value: EListingCategory.WOOD_MACHINERY,
    },
    {
      label: t("Enums.ListingCategory.woodScanners"),
      value: EListingCategory.WOOD_SCANNERS,
    },
    {
      label: t("Enums.ListingCategory.furnitureMachinery"),
      value: EListingCategory.FURNITURE_MACHINERY,
    },
    {
      label: t("Enums.ListingCategory.metalMachinery"),
      value: EListingCategory.METAL_MACHINERY,
    },
    {
      label: t("Enums.ListingCategory.software"),
      value: EListingCategory.SOFTWARE,
    },
    {
      label: t("Enums.ListingCategory.otherEquipment"),
      value: EListingCategory.OTHER_EQUIPMENT,
    },
  ];

  const displayOption = options.find((option) => option.value === value);

  const display = (
    <div className="inline-block after:right-0">
      {displayOption ? (
         <span className="px-1">{displayOption?.label}</span>
      ) : (
        <span>{t("CommonSelect.emptyCategory")}</span>
      )}
    </div>
  );

  const isValidStyles = valid ? "outline-green-500" : "";
  const isInvalidStyles = invalid ? "outline-red-500" : "";

  return (
    <div
      className={twMerge(
        "flex flex-col rounded outline outline-slate-200",
        isValidStyles,
        isInvalidStyles
      )}
    >
      {label ? <label className="form-label">{label}</label> : null}

      <CDropdown className="flex justify-between w-full">
        <CDropdownToggle
          // variant="outline"
          className={`p-1 bg-white h-[38px] w-full`}
        >
          {display}
        </CDropdownToggle>
        <CDropdownMenu>
          {options.map((option) => (
            <CDropdownItem
              key={option.value}
              className="cursor-pointer"
              active={option.value === value}
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </CDropdownItem>
          ))}
        </CDropdownMenu>
      </CDropdown>

      {invalid && (
        <label className="text-sm text-danger">{feedbackInvalid}</label>
      )}
    </div>
  );
};
