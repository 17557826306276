import { EOfferStatus } from "@/enums/offer-enums";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { skip } from "node:test";
import { Fragment, PropsWithChildren } from "react";

type TStepInProcessProps = {
  status?: EOfferStatus;
};

type TBallProps = {
  numberOfStep: number;
  isActive: boolean;
  isCurrent: boolean;
} & PropsWithChildren;

type TLinePrpps = {
  isActive: boolean;
};

const Circle = (props: TBallProps) => {
  const { isActive, children, numberOfStep, isCurrent } = props;
  return (
    <div className="flex flex-col items-center flex-1">
      <div
        className={`rounded-full font-bold text-white w-10 h-10 ${isActive ? "bg-green-500" : "bg-slate-400"} ${isCurrent ? "scale-110" : ""} flex items-center justify-center`}
      >
        {numberOfStep}
      </div>
      <div
        className={`text-center ${isActive ? "text-green-500" : "text-slate-400"} ${isCurrent ? "border-b-2 font-bold border-green-500" : ""}`}
      >
        {children}
      </div>
    </div>
  );
};

const Line = (props: TLinePrpps) => {
  const { isActive } = props;
  return (
    <div
      className={`border-t flex-1 h-1 mt-[20px] ${isActive ? "border-green-500" : ""}`}
    >
      {" "}
    </div>
  );
};

export const StepInProcessEnrollment = (props: TStepInProcessProps) => {
  const { t } = useAppTranslation();
  console.log("Props:", props);
  let finalStatus: EOfferStatus = EOfferStatus.NO_OFFER;

  if (props?.status === EOfferStatus.PROVIDER_REJECTED) {
    finalStatus = EOfferStatus.NO_OFFER;
  } else if (props?.status == EOfferStatus.CLIENT_ACCEPTED || props?.status == EOfferStatus.CLIENT_REJECTED){
    finalStatus = EOfferStatus.WAITING_FOR_CLIENT;
  } else if (props?.status) {
    finalStatus = props.status;
  }

  const lastStepTitle = props?.status == EOfferStatus.CLIENT_ACCEPTED 
  ? t("Enums.OfferStatus.CLIENT_ACCEPTED") : (props?.status == EOfferStatus.CLIENT_REJECTED ? t("Enums.OfferStatus.CLIENT_REJECTED") : "");

  console.log("lastStepTitle:", lastStepTitle);


  const processes = [
    {
      status: EOfferStatus.NO_OFFER,
      title: t("Enums.OfferStatus.NO_OFFER"),
    },
    {
      status: EOfferStatus.SUBMITTED,
      title: t("Enums.OfferStatus.SUBMITTED"),
    },
    {
      status: EOfferStatus.WAITING_FOR_CLIENT,
      title: t("Enums.OfferStatus.WAITING_FOR_CLIENT"),
    }
  ];

  const isConsidered = props?.status == EOfferStatus.CLIENT_ACCEPTED || props?.status == EOfferStatus.CLIENT_REJECTED;

  return (
    <div
      className={`flex items-start justify-between w-full gap-2 px-6 pt-4 pb-3 border border-gray-200 rounded-lg transition-all `}
    >
      {processes.map((process, index) => {
        const isActive =
          index <= processes.findIndex((p) => p.status === finalStatus);
        const isCurrent =
          index === processes.findIndex((p) => p.status === finalStatus);

        if (index === 0) {
          return (
            <Circle
              key={index}
              numberOfStep={index + 1}
              isActive={isActive}
              isCurrent={isCurrent}
            >
               {process.title}
            </Circle>
          );
        } else {
          return (
            <Fragment key={index}>
              <Line isActive={isActive} />
              <Circle
                numberOfStep={index + 1}
                isActive={isActive}
                isCurrent={isCurrent}
              >
                {isConsidered && process.status == EOfferStatus.WAITING_FOR_CLIENT ? lastStepTitle : process.title}
              </Circle>
            </Fragment>
          );
        }
      })}
    </div>
  );
};
