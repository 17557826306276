import { CustomButton } from "@/components/forms/custom/custom-button/custom-button";
import { EEnrollmentStatus } from "@/enums/enrollment-enums";
import { EOfferStatus } from "@/enums/offer-enums";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { cilCheckCircle } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useAuth, useUser } from "@clerk/clerk-react";
import {
  CTooltip
} from "@coreui/react";
import { EUserAccountType } from "@/enums/user-metadata";

type TPageDiscoverShowButtonsProps = {
  goBack: () => void;
  isEnrollmentInitiated: boolean;
  createdBy?: string;
  handleDelete: () => void;
  handleParticipate: () => void;
  latestOfferStatus: EOfferStatus;
  enrollmentStatus?: EEnrollmentStatus;
};

export const PageDiscoverShowButtons = ({
  goBack,
  isEnrollmentInitiated,
  createdBy,
  handleDelete,
  latestOfferStatus = EOfferStatus.NO_OFFER,
  handleParticipate,
  enrollmentStatus,
}: TPageDiscoverShowButtonsProps) => {
  const { t } = useAppTranslation();
  let finalStatus = EOfferStatus.NO_OFFER;
  if (latestOfferStatus) {
    finalStatus = latestOfferStatus;
  }
  const declineIsPossible = finalStatus == EOfferStatus.CLIENT_REJECTED || finalStatus == EOfferStatus.PROVIDER_REJECTED
  || finalStatus == EOfferStatus.NO_OFFER; 

  const isClientSubmitted = finalStatus == EOfferStatus.SUBMITTED;

  const {} = useAuth();
  const { user, isLoaded, isSignedIn } = useUser();

  const isClientUser =
  isLoaded &&
  isSignedIn &&
  user.unsafeMetadata?.accountType === EUserAccountType.CLIENT_USER;

  const isMyOwn = createdBy === user?.id;

  const declineToolTipContent = declineIsPossible ? 
  t("PageDiscoverShow.Buttons.Tooltip.declineAction")
   : (isClientSubmitted ? t("PageDiscoverShow.Buttons.Tooltip.declineSubmitted") : t("PageDiscoverShow.Buttons.Tooltip.declineAccepted"));

  return (
    <>
      <CustomButton
        color="secondary"
        variant="outline"
        type="button"
        onClick={goBack}
      >
        {t("PageDiscoverShow.Buttons.goBack")}
      </CustomButton>
      {isEnrollmentInitiated ? (
        <CTooltip content={declineToolTipContent}>
          <span>
          <CustomButton
            color="danger"
            type="button"
            onClick={handleDelete}
            disabled = {!declineIsPossible}
            className="text-white"
          >
            {t("PageDiscoverShow.Buttons.decline")}
          </CustomButton>
          </span>
        </CTooltip>
      ) : null}

{!isMyOwn && (
<CTooltip content={"Start adding offers to this listing"}>
  <span>
      <CustomButton
        color="info"
        className="text-white"
        type="button"
        onClick={handleParticipate}
        disabled={!!enrollmentStatus || isMyOwn}
      >
        {!!enrollmentStatus ? (
          <span className="flex items-center gap-1">
            <CIcon icon={cilCheckCircle} />
            <span>{t("PageDiscoverShow.Buttons.alreadyParticipating")}</span>
          </span>
        ) : (
          t("PageDiscoverShow.Buttons.participate")
        )}
      </CustomButton>
      </span>
      </CTooltip>
      )}
    </>
  );
};
