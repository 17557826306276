import { useAppSelector } from "@/store/hooks";
import { CToaster } from "@coreui/react";
import { useRef } from "react";
import { ToastMap } from "./toast-map";

export const MainToaster = () => {
  const toaster = useRef(null);
  const mainToast = useAppSelector((state) => state.toast.mainToast);

  const toastElement = ToastMap.find((x) => x.name === mainToast)?.element;

  return (
    <CToaster
      className="p-3"
      placement="top-center"
      push={toastElement}
      ref={toaster}
    />
  );
};
