export const MAX_ATTACHMENTS = 20;
export const MAX_ATTACHMENT_SIZE = 20 * 1024 * 1024;
export const ALLOWED_ATTACHMENT_FILETYPES = [
  ".png",
  ".jpg",
  ".jpeg",
  ".pdf",
  ".doc",
  ".docx",
  ".txt",
  ".zip",
  ".rar",
] as const;

export const MIN_PARTICIPANTS = 1;
export const MAX_PARTICIPANTS = 20;
