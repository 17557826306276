import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TModalConfirmationState = {
  isOpen?: boolean;
  title?: string;
  message?: string;
  messageSecondary?: string;
  buttons?: {
    confirm: {
      label: string;
      onClick: () => void;
      color?:
        | "primary"
        | "secondary"
        | "success"
        | "danger"
        | "warning"
        | "info";
      variant?: "outline" | "ghost";
      className?: string;
    };
    cancel: {
      label: string;
      onClick?: () => void;
      color?:
        | "primary"
        | "secondary"
        | "success"
        | "danger"
        | "dark"
        | "warning"
        | "info";
      variant?: "outline" | "ghost";
      className?: string;
    };
  };
};

type TModalListingPreviewState = {
  isOpen?: boolean;
  listingId?: number;
};

export type TModalState = {
  confirmationModal: TModalConfirmationState;
  listingPreviewModal: TModalListingPreviewState;
};

const initialState: TModalState = {
  confirmationModal: {},
  listingPreviewModal: {},
};

export const modalSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setModalConfirmation: (
      state,
      action: PayloadAction<TModalConfirmationState>
    ) => {
      state.confirmationModal = action.payload;
    },
    setModalListingPreview: (
      state,
      action: PayloadAction<TModalListingPreviewState>
    ) => {
      state.listingPreviewModal = action.payload;
    },
  },
});

export const { setModalConfirmation, setModalListingPreview } =
  modalSlice.actions;
export default modalSlice.reducer;
