import { SUPPORT_EMAIL } from "@/constants/app-constants";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { Trans } from "react-i18next";

export const TableNoData = () => {
  const { t } = useAppTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-64">
      <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg shadow-lg">
        <p className="text-xl font-bold text-gray-700">
          {t("CommonTables.noData")}
        </p>
        <p className="mt-2 text-center text-gray-500">
          <Trans
            i18nKey={"CommonTables.inCaseOfErrorContactSupport"}
            values={{ email: SUPPORT_EMAIL }}
            components={{
              1: (
                <a
                  href="mailto:support@machinevertical.com"
                  className="text-blue-500 underline"
                />
              ),
            }}
          />
        </p>
      </div>
    </div>
  );
};
