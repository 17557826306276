import {
  MAX_ATTACHMENT_SIZE,
  MAX_ATTACHMENTS,
} from "@/constants/form-constatns";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { useAppDispatch } from "@/store/hooks";
import { addToastMain, TToastState } from "@/store/toast/slice";
import { integerToFilesize } from "@/utils/integer-to-filesize";

export const useToastsDiscover = () => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();

  const dispatchToastAction = ({
    mainToast = "custom_toast",
    mainToastColor,
    mainToastContent,
  }: {
    mainToast?: TToastState["mainToast"];
    mainToastColor: TToastState["mainToastColor"];
    mainToastContent: TToastState["mainToastContent"];
  }) => {
    dispatch(
      addToastMain({
        mainToast,
        mainToastColor,
        mainToastContent,
      })
    );
  };

  const toastBackendError = (message: string) =>
    dispatchToastAction({
      mainToastColor: "danger",
      mainToastContent: message,
    });

  const toastAttachmentsAreRequired = () =>
    dispatchToastAction({
      mainToastColor: "danger",
      mainToastContent: t("Toasts.Offer.attachmentsAreRequired"),
    });

  const toastMaxAttachments = () =>
    dispatchToastAction({
      mainToastColor: "danger",
      mainToastContent: t("Toasts.Offer.maxAttachmentsAllowed", {
        count: MAX_ATTACHMENTS,
      }),
    });

  const toastMaxAttachmentSize = () =>
    dispatchToastAction({
      mainToastColor: "danger",
      mainToastContent: t("Toasts.Offer.fileTooLarge", {
        size: integerToFilesize(MAX_ATTACHMENT_SIZE),
      }),
    });

  const toastOfferAlreadyExists = () =>
    dispatchToastAction({
      mainToastColor: "danger",
      mainToastContent: t("Toasts.Offer.alreadyExists"),
    });

  const toastOfferCreated = () =>
    dispatchToastAction({
      mainToastColor: "success",
      mainToastContent: t("Toasts.Offer.created"),
    });

  const toastOfferEdited = () =>
    dispatchToastAction({
      mainToastColor: "success",
      mainToastContent: t("Toasts.Offer.edited"),
    });

  const toastOfferRejected = () =>
    dispatchToastAction({
      mainToastColor: "success",
      mainToastContent: t("Toasts.Offer.rejected"),
    });

  const toastOfferFailedToReject = () =>
    dispatchToastAction({
      mainToastColor: "danger",
      mainToastContent: t("Toasts.Offer.failedToReject"),
    });

  return {
    toastAttachmentsAreRequired,
    toastMaxAttachments,
    toastMaxAttachmentSize,
    toastOfferAlreadyExists,
    toastOfferCreated,
    toastOfferEdited,
    toastBackendError,
    toastOfferRejected,
    toastOfferFailedToReject,
  };
};
