import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { cilArrowCircleLeft, cilCheckCircle } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton } from "@coreui/react";

type TOfferCreateEditViewButtonsProps = {
  onSubmit: () => void;
  onCancel: () => void;
  isLoading: boolean;
};

export const OfferCreateEditViewButtons = ({
  onSubmit,
  onCancel,
  isLoading,
}: TOfferCreateEditViewButtonsProps) => {
  const { t } = useAppTranslation();

  return (
    <div className="flex justify-end gap-2">
      <CButton color="secondary" onClick={onCancel} disabled={isLoading}>
        <span className="flex items-center gap-1">
          <CIcon icon={cilArrowCircleLeft} />
          <span>{t("CommonTables.cancel")}</span>
        </span>
      </CButton>
      <CButton color="primary" onClick={onSubmit} disabled={isLoading}>
        <span className="flex items-center gap-1">
          <CIcon icon={cilCheckCircle} />
          <span>{t("CommonTables.submit")}</span>
        </span>
      </CButton>
    </div>
  );
};
