import React, { useEffect, useState } from "react";
import { CFormTextarea, CFormInput, CInputGroup, CInputGroupText, CFormSwitch, CTooltip, CFormText } from "@coreui/react";
import { TListingCreateForm } from "../listing-create";
import { FormikErrors } from "formik";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { TSpecification } from "../../types/listing-types";
import { CustomButton } from "@/components/forms/custom/custom-button/custom-button";
import CIcon from "@coreui/icons-react";
import { cilTrash } from "@coreui/icons";

type TListingCreateSpecificationsProps = {
  values: TListingCreateForm;
  errors: FormikErrors<TListingCreateForm>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  hasSubmittedDraft: boolean;
  hasSubmittedPublish: boolean;
  isRestrictedBudget: boolean;
  setIsRestrictedBudget: (isRestrictedBudget: boolean) => void;
  isWithDeadline: boolean;
  setIsWithDeadline: (isWithDeadline: boolean) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

export const ListingCreateSpecifications = ({
  values,
  errors,
  handleChange,
  hasSubmittedDraft,
  hasSubmittedPublish,
  isRestrictedBudget,
  setIsRestrictedBudget,
  isWithDeadline,
  setIsWithDeadline,
  setFieldValue,
}: TListingCreateSpecificationsProps) => {
  const { t } = useAppTranslation();

  // Initialize `specifications` state with an empty array
  const [specifications, setSpecifications] = useState<TSpecification[]>([]);

  // Ensure specifications are set correctly when values.specification change
  useEffect(() => {
    setSpecifications(Array.isArray(values?.Specifications) ? values.Specifications : []);
  }, [values?.Specifications]);

  const handleAddSpecification = () => {
    const newSpecifications = [...specifications, { value: "", is_optional: false }];
    setSpecifications(newSpecifications);
    setFieldValue("Specifications", newSpecifications); // Update Formik field value
  };

  const handleDeleteSpecification = (index: number) => {
    const modifiedSpecs = specifications.filter((_, i) => i !== index)
    setSpecifications(modifiedSpecs);
    setFieldValue("Specifications", modifiedSpecs); // Update Formik field value
  };

  const handleSpecificationChange = (index: number, field: keyof TSpecification, value: string | boolean) => {
    const newSpecifications = [...specifications];
    newSpecifications[index] = { ...newSpecifications[index], [field]: value };

    setSpecifications(newSpecifications);
    setFieldValue("Specifications", newSpecifications);
  };

  // Function to handle auto-growing textarea
  const handleAutoGrow = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = event.target;
    textarea.style.height = 'auto'; // Reset height to auto to recompute height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set height to match scroll height
  };

  const maxCharacters = 255; // Maximum number of characters allowed

  const deadlineValid =
    !errors.deadline && (hasSubmittedPublish || (hasSubmittedDraft && !!values.deadline));

  const budgetValid =
    !errors.budget && (hasSubmittedPublish || (hasSubmittedDraft && !!values.budget));
  const budgetInvalid =
    !!errors.budget && (hasSubmittedPublish || (hasSubmittedDraft && !!values.budget)) && isRestrictedBudget;

  return (
    <>
      <h5 className="px-2 pt-4 pb-0">{t("PageListingCreateEdit.specifications")}</h5>
      <div className="p-3 border border-gray-200 rounded-lg">
        {/* Dynamic Specifications Section */}
        
          <CustomButton type="button" color={(hasSubmittedPublish || hasSubmittedDraft) && specifications?.length == 0 && errors?.Specifications ? 'danger' : 'primary'} onClick={handleAddSpecification} className="mb-4">
            <CTooltip content={t("PageListingCreateEdit.Specifications.add_tooltip")}>
            <span className="flex items-center justify-center gap-1 min-w-16">
              <span> + {t("PageListingCreateEdit.Specifications.add")}</span>
            </span>
            </CTooltip>
          </CustomButton>
        {(hasSubmittedPublish || hasSubmittedDraft) && specifications?.length == 0 && errors?.Specifications && (
                  <div className="d-block is-invalid" style={{ padding: '0.5rem 1rem', color: 'red' }}>
                    {errors.Specifications}
                  </div>
        )}
        {specifications.map((specification, index) => (
          <div className="mb-3 d-flex align-items-center" key={index} style={{ gap: '1rem' }}>
            <CInputGroup className="d-flex align-items-center" style={{ width: '100%' }}>
            <div
        style={{
          width: '2rem',
          textAlign: 'center',
          borderRight: '1px solid #ced4da', // Separator line
          padding: '0.5rem',
          fontWeight: 'bold',
        }}
      >
        {index + 1}
      </div>  
              {/* Combined Border and Alignment */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'stretch',
                  border: '1px solid #ced4da', // Uniform border for all elements
                  borderRadius: '0.25rem', // Apply same border radius to all elements
                  overflow: 'hidden', // Clip child borders
                  flexGrow: 1,
                }}
              >
                {/* Textarea Input Field */}
                <CFormTextarea
                  invalid={(hasSubmittedDraft || hasSubmittedPublish) && errors?.Specifications && errors.Specifications[index] !== undefined}
                  valid={(hasSubmittedDraft || hasSubmittedPublish) && hasSubmittedPublish && errors?.Specifications && errors.Specifications[index] == undefined}
                  value={specification.value}
                  placeholder={t("PageListingCreateEdit.Specifications.value_placeholder")}
                  onChange={(e) => {
                    handleSpecificationChange(index, "value", e.target.value);
                    handleAutoGrow(e); // Auto-grow as the user types
                  }}
                  maxLength={maxCharacters} // Restrict maximum characters to 255
                  minLength={1}
                  required
                  rows={1} // Initial row size
                  style={{
                    flexGrow: 1, // Allows the textarea to grow as needed
                    minWidth: '150px', // Set a minimum width for the textarea
                    resize: 'none', // Disable manual resizing
                    border: 'none', // Remove individual border for uniform row styling
                  }}
                />
                {/* Character Counter with Matching Border */}
                <div
                  className="d-flex align-items-center"
                  style={{
                    fontSize: '0.8rem',
                    padding: '0.5rem 1rem',
                    borderLeft: '1px solid #ced4da', // Only show left border to separate
                  }}
                >
                  {specification.value.length}/{maxCharacters}
                </div>
                {/* Optional Toggle Switch with Matching Border */}
                <div
                  className="d-flex align-items-center"
                  style={{
                    padding: '0.5rem 1rem',
                    borderLeft: '1px solid #ced4da', // Only show left border to separate
                  }}
                >
                  <span className="me-2">{t("PageListingCreateEdit.Specifications.optional")}</span>

                  <CTooltip content={t("UI.Buttons.optional_specification_toggle")}>
                  <CFormSwitch
                    size="lg"
                    onChange={(e) => handleSpecificationChange(index, "is_optional", e.target.checked)}
                    checked={specification.is_optional}
                  />
                  </CTooltip>
                </div>
                {/* Delete Button */}
                <CTooltip content={t("UI.Buttons.remove_specification")}>
                <CInputGroupText>
                      <CustomButton
                        type="button"
                        color="danger"
                        onClick={() => handleDeleteSpecification(index)}>
                        <CIcon icon={cilTrash} />
                      </CustomButton>
                    
                </CInputGroupText>
                </CTooltip>

              </div>
              {errors.Specifications && errors.Specifications[index]?.value && (
                  <div className="invalid-feedback d-block" style={{ padding: '0.5rem 1rem', color: 'red' }}>
                    {errors.Specifications[index].value}
                  </div>
                )}
            </CInputGroup>
          </div>
        ))}

        {/* Budget Section */}
        <div className="my-4 max-w-60">
          <label className="form-label">{t("PageListingCreateEdit.Specifications.restrictedBudget")}</label>
          <div className="flex">
            <CInputGroup className="sm:flex-1">
              <CInputGroupText>
                <CFormSwitch
                  size="xl"
                  onChange={() => {
                    setIsRestrictedBudget(!isRestrictedBudget);
                    if (!isRestrictedBudget) {
                      setFieldValue("budget", null);
                    }
                  }}
                  checked={isRestrictedBudget}
                />
              </CInputGroupText>
              {isRestrictedBudget && (
                <>
                  <CFormInput
                    id="budget"
                    type="number"
                    disabled={!isRestrictedBudget}
                    value={values.budget}
                    onChange={handleChange}
                    valid={budgetValid}
                    invalid={budgetInvalid}
                  />
                  <CInputGroupText>€</CInputGroupText>
                </>
              )}
            </CInputGroup>
          </div>
          {budgetInvalid && <div className="invalid-feedback">{errors.budget}</div>}
        </div>

        {/* Deadline Section */}
        <div className="mb-4 max-w-64">
          <label className="form-label">{t("PageListingCreateEdit.Specifications.deadline")}</label>
          <div className="flex">
            <CInputGroup className="w-20 rounded sm:flex-1">
              <CInputGroupText>
                <CFormSwitch
                  size="xl"
                  onChange={() => {
                    setIsWithDeadline(!isWithDeadline);
                    if (!isWithDeadline) {
                      setFieldValue("deadline", null);
                    }
                  }}
                  checked={isWithDeadline}
                />
              </CInputGroupText>
              {isWithDeadline && (
                <>
                  <CFormInput
                    className="!rounded-r-md"
                    id="deadline"
                    type="date"
                    disabled={!isWithDeadline}
                    value={values.deadline?.toString?.() || ""}
                    onChange={handleChange}
                    min={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split("T")[0]}
                    valid={deadlineValid}
                    invalid={!!errors.deadline}
                  />
                </>
              )}
            </CInputGroup>
          </div>
          {!!errors.deadline && <div className="invalid-feedback">{errors.deadline}</div>}
        </div>
      </div>
    </>
  );
};
