import { OfferStatusBadge } from "@/components/badges/offer-status-badge";
import { TableShadowWrapper } from "@/components/forms/custom/custom-table/table-shadow-wrapper";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { EOfferStatus } from "@/enums/offer-enums";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { dateToYearMonthDayTime } from "@/utils/date-fns";
import { cilPencil, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableRow,
  CTooltip,
} from "@coreui/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOfferReject } from "../../hooks/use-offer-reject";
import { useOfferModals } from "../../hooks/use-offer-modals";
import { useToastsDiscover } from "../../hooks/use-toasts-discover";

type TOffer = {
  id: number;
  status: EOfferStatus;
  created_by: string;
  enrollment_id: number;
  created_at: string;
  attachment_count: number;
};

type TDiscoverShowOffersTableProps = {
  offers: TOffer[];
  isLoading: boolean;
  refetch: () => void;
};

export const DiscoverShowOffersTable = ({
  offers,
  isLoading,
  refetch,
}: TDiscoverShowOffersTableProps) => {
  const { t } = useAppTranslation();
  const { listingId } = useParams();
  const navigate = useNavigate();
  const [mouseOverIndex, setMouseOverIndex] = useState<number | null>(null);
  const { handleDispatchOfferRejectModal } = useOfferModals();
  const { toastOfferRejected, toastOfferFailedToReject } = useToastsDiscover();
  const handleClickEdit = (offerId: number, enrollmentId: number) => {
    navigate(`/discover/${listingId}/offers/${enrollmentId}/${offerId}/edit`);
  };

  const { mutate: mutateReject } = useOfferReject({
    onSuccess: () => {
      toastOfferRejected();
      refetch();
    },
    onError: () => {
      toastOfferFailedToReject();
    },
  });

  const columns = [
    {
      key: "created_at",
      label: t("PageDiscoverShow.OffersTable.created_at"),
    },
    {
      key: "status",
      label: t("PageDiscoverShow.OffersTable.status"),
    },
    {
      key: "actions",
      label: t("CommonTables.actions"),
    },
  ];

  return (
    <>
      <TableShadowWrapper>
        <CTable style={{ marginBottom: 0 }}>
        <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-800">
          {t("PageDiscoverShow.OffersTable.heading")}
        </h2>
      </div>

          <CTableBody
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr auto",
            }}
            onMouseLeave={() => {
              setMouseOverIndex(() => null);
            }}
          >
            <CTableRow
              className="text-base font-extrabold tracking-wider text-gray-900 uppercase bg-gray-200"
              style={{
                display: "contents",
              }}
              onMouseOver={() => {
                setMouseOverIndex(() => null);
              }}
            >
              {columns.map((column) => (
                <CTableDataCell
                  className="py-4 border-b-2 border-gray-400"
                  scope="col"
                  key={column.key}
                >
                  {column.label}
                </CTableDataCell>
              ))}
            </CTableRow>
            {isLoading
              ? columns.map((_) => (
                  <CTableRow>
                    <CTableDataCell>
                      <DefaultSkeleton />
                    </CTableDataCell>
                  </CTableRow>
                ))
              : offers.map((offer, _index) => {
                  const isMousedOver = _index === mouseOverIndex;
                  const isActive =
                    offer.status === EOfferStatus.SUBMITTED ||
                    offer.status === EOfferStatus.CLIENT_ACCEPTED;

                  const idleColor = isActive ? "" : "danger";

                  return (
                    <CTableRow
                      onMouseOver={() => {
                        setMouseOverIndex(() => _index);
                      }}
                      key={offer.id}
                      style={{
                        display: "contents",
                      }}
                      color={isMousedOver ? "info" : idleColor}
                    >
                      <CTableDataCell className="flex items-center justify-center w-full">
                        {dateToYearMonthDayTime(offer.created_at)}
                      </CTableDataCell>
                      <CTableDataCell className="flex items-center justify-start w-full">
                        <OfferStatusBadge status={offer.status} />
                      </CTableDataCell>

                      <CTableDataCell className="flex items-center justify-center w-full gap-1">
                        <CTooltip content={t("CommonTables.edit")}>
                          <span>
                            <CButton
                              color="primary"
                              size="sm"
                              type="button"
                              disabled={offer.status !== EOfferStatus.SUBMITTED}
                              onClick={() => {
                                if (offer.status === EOfferStatus.SUBMITTED) {
                                  handleClickEdit(
                                    offer.id,
                                    offer.enrollment_id
                                  );
                                }
                              }}
                            >
                              <CIcon icon={cilPencil} />
                            </CButton>
                          </span>
                        </CTooltip>

                        <CTooltip
                          content={t(
                            "Modals.Offer.RejectConfirmation.Buttons.confirm"
                          )}
                        >
                          <span>
                            <CButton
                              color="danger"
                              size="sm"
                              type="button"
                              disabled={offer.status !== EOfferStatus.SUBMITTED}
                              onClick={() => {
                                if (offer.status === EOfferStatus.SUBMITTED) {
                                  handleDispatchOfferRejectModal({
                                    onConfirm: () => {
                                      mutateReject({
                                        enrollmentId: offer.enrollment_id,
                                        offerId: offer.id,
                                      });
                                    },
                                  });
                                }
                              }}
                            >
                              <CIcon icon={cilTrash} />
                            </CButton>
                          </span>
                        </CTooltip>
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
          </CTableBody>
        </CTable>
      </TableShadowWrapper>
    </>
  );
};
