import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { useNavigate, useParams } from "react-router-dom";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { OfferCreateEditViewFileTable } from "../../../equipment-provider/discover/show/offer/components/offer-create-edit-view-file-table";
import { useQueryExistingAttachments } from "../../../equipment-provider/discover/show/offer/hooks/use-query-existing-attachments";
import { CButton, CSpinner } from "@coreui/react";
import { useOfficeOfferStatusChange } from "./hooks/use-offer-status-change";
import { EOfferStatus } from "@/enums/offer-enums";
import { useState } from "react";
import { useLocation } from 'react-router-dom';


export const PageListingViewOffer = () => {
  useBreadcrumbs("OFFER_VIEW");
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { status, previousState } = location.state || {};

  const isSubmitted = status === EOfferStatus.SUBMITTED;

  const { listingId, offerId } = useParams();
  const [isSubmittingAccept, setIsSubmittingAccept] = useState(false);
  const [isSubmittingReject, setIsSubmittingReject] = useState(false);

  const statusMutation = useOfficeOfferStatusChange({
    onError: () => {
      setIsSubmittingAccept(false);
      setIsSubmittingReject(false);
    },
    onSuccess: () => {
      setIsSubmittingAccept(false);
      setIsSubmittingReject(false);
      navigate(-1);
    },
  });

  const handleAcceptOffer = (
    offerId: number,
    listingId: number,
  ) => {
    setIsSubmittingAccept(true);
    statusMutation.mutate({
      id: Number(offerId),
      listingId: listingId,
      status: EOfferStatus.CLIENT_ACCEPTED
    });
  };

  const handleRejectOffer = (
    offerId: number,
    listingId: number,
  ) => {
    setIsSubmittingReject(true);
    statusMutation.mutate({
      id: Number(offerId),
      listingId: listingId,
      status: EOfferStatus.CLIENT_REJECTED
    });
  };

  const { data: existingAttachments } = useQueryExistingAttachments({
    offerId: Number(offerId),
    listingId: Number(listingId),
  });

  return (
    <div>
      <h4 className="mt-4">{t("PageOfferCreateEditView.attachments")}</h4>
      <div>
        <OfferCreateEditViewFileTable
          isOffice={true}
          existingAttachments={existingAttachments}
          isEditing={false}  // Set to false to disable editing
          files={[]}
          setFiles={() => {}}
          setAttachmentUrlsToDelete={() => {}}
          attachmentUrlsToDelete={[]}
          isTooManyEstimatedAttachments={false}
        />
      </div>

      <div className="flex justify-end gap-2 pt-5">
      <CButton
 type="button"
 color="dark"
 variant="outline"
    onClick={() => navigate(-1)}
    disabled={isSubmittingAccept || isSubmittingReject}
    className="flex-1 sm:flex-initial">
      <span>Go Back</span>
  </CButton>
  
      {isSubmitted && (
      <>
      <CButton
    color="primary"
    type="submit"
    onClick={() => handleAcceptOffer(offerId, listingId)}
    disabled={isSubmittingAccept || isSubmittingReject}
    className="flex-1 sm:flex-initial">
    {isSubmittingAccept && <CSpinner as="span" size="sm" aria-hidden="true" />}
      <span>Accept Offer</span>
  </CButton>
  <CButton
    color="danger"
    type="submit"
    onClick={() => handleRejectOffer(offerId, listingId)}
    disabled={isSubmittingAccept || isSubmittingReject}
    className="flex-1 sm:flex-initial">
       {isSubmittingReject && <CSpinner as="span" size="sm" aria-hidden="true" />}
      <span>Reject Offer</span>
  </CButton>
  </>
      )}
      </div>
    </div>
  );
};
