import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import { queryOffer } from "../query";
import { useToastsDiscover } from "../../../hooks/use-toasts-discover";

type TUseMutationCreateOffer = {
  comment: string;
  files: File[];
  enrollmentId?: number;
  attachmentUrlsToDelete: string[];
  offerId?: number;
  afterSuccess: () => void;
};

export const useMutationCreateOffer = ({
  comment,
  files,
  enrollmentId,
  offerId,
  attachmentUrlsToDelete,
  afterSuccess,
}: TUseMutationCreateOffer) => {
  const { getToken } = useAuth();
  const { toastBackendError, toastOfferCreated } = useToastsDiscover();

  return useMutation({
    mutationKey: ["create-offer"],
    mutationFn: async () => {
      const token = (await getToken()) || "";
      const res = await queryOffer({
        comment,
        files,
        token,
        enrollmentId,
        attachmentUrlsToDelete,
        offerId,
      });
      if (res.status >= 400) {
        toastBackendError(await res.json().then((data) => data.message));
        return;
      }
      if (res.status === 200 || res.status === 201) {
        toastOfferCreated();
        afterSuccess();
        return;
      }
    },
  });
};
