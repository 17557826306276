import { CButton } from "@coreui/react";

export type TCustomButtonProps = {
  //
} & React.ComponentProps<typeof CButton>;

export const CustomButton = (props: TCustomButtonProps) => {
  const { as, ...propsRest } = props;
  return <CButton {...propsRest} />;
};
