import { twMerge } from "tailwind-merge";

type TDefaultSkeletonProps = {
  className?: string;
  classNameInner?: string;
};

type TStringSkeletonProps = {
  className?: string;
};

export const DefaultSkeleton = (props: TDefaultSkeletonProps) => {
  const { className, classNameInner } = props;
  return (
    <div role="status" className={twMerge("max-w-sm animate-pulse", className)}>
      <div
        className={twMerge(
          "h-4 bg-gray-200 rounded-full dark:bg-gray-700 my-2",
          classNameInner
        )}
      ></div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const StringSkeleton = (props: TStringSkeletonProps) => {
  const { className } = props;
  return (
    <div
      className={twMerge(
        "h-auto my-1 bg-gray-200 rounded-full animate-pulse dark:bg-gray-700 inline-block",
        className
      )}
    ></div>
  );
};
