import { useAuth } from "@clerk/clerk-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CBadge,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { CategoryBadge } from "@/components/badges/category-badge";
import { CustomButton } from "@/components/forms/custom/custom-button/custom-button";
import { dateToYearMonthDay } from "@/utils/date-fns";
import { CustomPagination } from "@/components/forms/custom/custom-pagination/custom-pagination";
import { useState } from "react";
import { EListingCategory } from "@/enums/listing-enums";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "@/hooks/use-debounce";
import { authedFetch } from "@/utils/authed-fetch";
import CIcon from "@coreui/icons-react";
import { cilX, cilWarning, cilCheckCircle, cilInfo} from "@coreui/icons";
import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import { OfferStatusBadge } from "@/components/badges/offer-status-badge";
import {
  EEnrollmentMyEnrollmentsFilters,
  EEnrollmentStatus,
} from "@/enums/enrollment-enums";
import { EOfferStatus } from "@/enums/offer-enums";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { SortArrow } from "@/components/forms/custom/custom-table/sort-arrow";
import { TableShadowWrapper } from "@/components/forms/custom/custom-table/table-shadow-wrapper";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { CategorySelect } from "@/components/forms/custom/custom-select/category-select";

type TColumn = {
  key: string;
  label: React.ReactNode;
};

type TEnrollment = {
  id: number;
  category: EListingCategory;
  title: string;
  country: string;
  deadline: string;
  created_at: string;
  status: EEnrollmentStatus;
  offers_count: number;
  latest_offer_status?: EOfferStatus;
};

type TQuery = {
  count: number;
  enrollments: TEnrollment[];
  cardCounts: {
    initiated: number;
    offered: number;
    closed: number;
  };
};

type TSpreadableParams = {
  p?: string;
  ps?: string;
  orderBy?: string;
  orderDir?: string;
  title?: string;
  status?: string;
  category?: string;
};

export const MyEnrollmentsTable = () => {
  const { t } = useAppTranslation();
  const { getToken } = useAuth();
  const [mouseOverIndex, setMouseOverIndex] = useState<number | null>(null);
  const [mouseOverIndexNoOffers, setMouseOverIndexNoOffers] = useState<number | null>(null);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const p = parseInt(searchParams.get("p") || "1");
  const ps = parseInt(searchParams.get("ps") || "0");
  const orderBy = searchParams.get("orderBy") || "";
  const orderDir = searchParams.get("orderDir") || "";
  const titleRaw = searchParams.get("title") || "";
  const status = (searchParams.get("status") ||
    "") as EEnrollmentMyEnrollmentsFilters;
  const category =
    (searchParams.get("category") as EListingCategory) ||
    ("" as EListingCategory);

  const spreadableParams: TSpreadableParams = {};
  if (p && p <= 1) spreadableParams.p = p.toString();
  if (ps) spreadableParams.ps = ps.toString();
  if (orderBy) spreadableParams.orderBy = orderBy;
  if (orderDir) spreadableParams.orderDir = orderDir;
  if (titleRaw) spreadableParams.title = titleRaw;
  if (status) spreadableParams.status = status;
  if (category) spreadableParams.category = category;

  delete spreadableParams.p;

  const setPage = (page: number) => {
    if (page > 1) {
      setSearchParams({ ...spreadableParams, p: page.toString() });
    } else {
      delete spreadableParams.p;
      setSearchParams({ ...spreadableParams });
    }
  };
  const setOrder = (orderBy: string, orderDir: string) => {
    setSearchParams({ ...spreadableParams, orderBy, orderDir });
  };
  const setStatus = (status: EEnrollmentMyEnrollmentsFilters | "") => {
    if (status) {
      setSearchParams({ ...spreadableParams, status });
    } else {
      delete spreadableParams.status;
      setSearchParams({ ...spreadableParams });
    }
  };

  const title = useDebounce(titleRaw, 250);

  const { data, isLoading } = useQuery<TQuery>({
    queryKey: [
      "my-enrollments",
      p,
      ps,
      orderBy,
      orderDir,
      title,
      category,
      status,
    ],
    queryFn: async ({ queryKey }) => {
      const [_, p, ps, orderBy, orderDir, title, category, status] = queryKey;

      const pParam = p ? `p=${p}` : "";
      const psParam = ps ? `ps=${ps}` : "";
      const orderByParam = orderBy ? `orderBy=${orderBy}` : "";
      const orderDirParam = orderDir ? `orderDir=${orderDir}` : "";
      const titleParam = title ? `title=${title}` : "";
      const categoryParam = category ? `category=${category}` : "";
      const statusParam = status ? `status=${status}` : "";

      const params =
        p || ps || orderBy || orderDir || title || category || status
          ? `?${[
              pParam,
              psParam,
              orderByParam,
              orderDirParam,
              titleParam,
              categoryParam,
              statusParam,
            ]
              .filter((param) => param)
              .join("&")}`
          : "";

      const response = await authedFetch({
        endpoint: `api/provider/enrollment/my-enrollments${params}`,
        token: await getToken(),
      });
      return response.json();
    },
  });

  const pageCount = Math.ceil((data?.count || 0) / 10);

  const count = data?.count || 0;

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setSearchParams({ ...spreadableParams, title: e.target.value });
    } else {
      delete spreadableParams.title;
      setSearchParams({ ...spreadableParams });
    }
  };

  const handleChangeCategory = (selectedCategory?: EListingCategory) => {
    if (selectedCategory && category !== selectedCategory) {
      setSearchParams({ ...spreadableParams, category: selectedCategory });
    } else {
      delete spreadableParams.category;
      setSearchParams({ ...spreadableParams });
    }
  };

  const handleClearFilterBadge = (key: keyof TSpreadableParams) => {
    delete spreadableParams[key];
    setSearchParams({ ...spreadableParams });
  };
  const handleClearFilterAll = () => {
    delete spreadableParams.title;
    delete spreadableParams.category;
    setSearchParams({
      ...spreadableParams,
    });
  };

  const sortArrow = (type: string) => {
    return (
      <SortArrow
        columnName={type}
        orderBy={orderBy}
        orderDir={orderDir}
        onClick={setOrder}
      />
    );
  };

  // Define columns for both tables
  const columns: TColumn[] = [
    {
      key: "id",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>#</span>
          {sortArrow("id")}
        </div>
      ),
    },
    {
      key: "title",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>{t("PageMyEnrollments.SearchEnrollmentsTable.title")}</span>
          {sortArrow("title")}
        </div>
      ),
    },
    {
      key: "category",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>{t("PageMyEnrollments.SearchEnrollmentsTable.category")}</span>
          {sortArrow("category")}
        </div>
      ),
    },
    {
      key: "deadline",
      label: (
        <div className="flex items-center h-full gap-1">
          <span className="flex flex-col text-sm">
            {t("PageMyEnrollments.SearchEnrollmentsTable.availableUntil")}
          </span>
          {sortArrow("deadline")}
        </div>
      ),
    },
    {
      key: "actions",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>{t("CommonTables.actions")}</span>
        </div>
      ),
    },
  ];

   // Define columns for both tables
   const columnsNoOffers: TColumn[] = [
    {
      key: "id",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>#</span>
          {sortArrow("id")}
        </div>
      ),
    },
    {
      key: "title",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>{t("PageMyEnrollments.SearchEnrollmentsTable.title")}</span>
          {sortArrow("title")}
        </div>
      ),
    },
    {
      key: "category",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>{t("PageMyEnrollments.SearchEnrollmentsTable.category")}</span>
          {sortArrow("category")}
        </div>
      ),
    },
    {
      key: "cta",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>{t("PageMyEnrollments.SearchEnrollmentsTable.cta")}</span>
        </div>
      ),
    },
    {
      key: "actions",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>{t("CommonTables.actions")}</span>
        </div>
      ),
    },
  ];

  const getActionMessage = (item) => {
    console.log(item)
    if (item.offers_count === 0) {
      return "No offer was ever sent yet, please add your first offer!";
    } else if (item.latest_offer_status === "CLIENT_REJECTED" || item.latest_offer_status === "PROVIDER_REJECTED") {
      return "No offers are active anymore, please add new";
    }
    return ""; // Return an empty string if no action is needed
  };

  // Split enrollments into two arrays based on your criteria
  const enrollmentsRequireOffer = data?.enrollments?.filter(
    (enrollment) =>
      enrollment.offers_count === 0 ||
      enrollment.latest_offer_status === EOfferStatus.CLIENT_REJECTED ||
      enrollment.latest_offer_status === EOfferStatus.PROVIDER_REJECTED
  );

  const enrollmentsWithOffers = data?.enrollments?.filter(
    (enrollment) =>
      enrollment.offers_count > 0 &&
      (enrollment.latest_offer_status === EOfferStatus.CLIENT_ACCEPTED ||
        enrollment.latest_offer_status === EOfferStatus.SUBMITTED)
  );

  return (
    <>
      {/* Filters */}
      <h3 className="flex pb-3 items-center">
        {t("PageMyEnrollments.myEnrollments")}
      </h3>

      <div className="flex flex-wrap items-center gap-3 px-3 py-3 bg-white rounded-t-xl">
        <div className="flex items-center gap-2">
          <span className="font-bold">
            {t("PageDiscover.SearchListingsTable.title")}:{" "}
          </span>
          <div>
            <CustomInput
              placeholder={t("CommonTables.searchDots")}
              value={titleRaw}
              onChange={handleChangeTitle}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setSearchParams({ ...spreadableParams, search: "" });
                }
              }}
              divWrap
              clearable
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <span className="font-bold">
            {t("PageDiscover.SearchListingsTable.category")}:{" "}
          </span>
          <CategorySelect
            invalid={false}
            valid={false}
            value={category}
            onChange={handleChangeCategory}
          />
        </div>
      </div>
      {title || category ? (
        <div className="flex px-3 pb-3 bg-white">
          <div className="flex items-stretch gap-2 p-2 bg-gray-100 rounded-lg">
            {title && (
              <CBadge color="info">
                <span className="flex items-center gap-1">
                  {`${t("PageDiscover.SearchListingsTable.title")}: ${title}`}
                  <CIcon
                    icon={cilX}
                    size="sm"
                    onClick={() => handleClearFilterBadge("title")}
                    className="cursor-pointer"
                  />
                </span>
              </CBadge>
            )}
            {category && (
              <CBadge color="info">
                <span className="flex items-center gap-1">
                  {`${t("PageDiscover.SearchListingsTable.category")}: ${category}`}
                  <CIcon
                    icon={cilX}
                    size="sm"
                    onClick={() => handleClearFilterBadge("category")}
                    className="cursor-pointer"
                  />
                </span>
              </CBadge>
            )}
            <CBadge color="danger">
              <CIcon
                icon={cilX}
                size="sm"
                onClick={() => handleClearFilterAll()}
                className="cursor-pointer"
              />
            </CBadge>
          </div>
        </div>
      ) : null}
      <div className="self-stretch h-[5px] bg-white mb-[-5px]" />

      {/* First Table: Enrollments requiring offer */}
      {enrollmentsRequireOffer?.length > 0 && (
      <h4
  className="mt-6 mb-2 p-3 rounded-lg flex items-center justify-center gap-2 text-white bg-orange-500 font-bold text-lg animate-bounce shadow-lg"
>
  <CIcon icon={cilWarning} className="text-white mr-2" /> {/* CoreUI alert icon */}
  {t("PageMyEnrollments.EnrollmentsRequireOffer")}
</h4>
      )}
      {enrollmentsRequireOffer?.length > 0 && (
      <TableShadowWrapper>
        <CTable className="border border-orange-500  bg-white rounded-lg shadow-lg">
          <CTableBody
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr auto auto auto",
            }}
            onMouseLeave={() => {
              setMouseOverIndexNoOffers(() => null);
            }}
          >
            {/* Table Header */}
            <CTableRow
              className="text-base font-extrabold tracking-wider text-gray-900 uppercase bg-gray-200"
              style={{
                display: "contents",
              }}
              onMouseOver={() => {
                setMouseOverIndexNoOffers(() => null);
              }}
            >
              {columnsNoOffers.map((column) => (
                <CTableHeaderCell
                  className="py-4 border-b-2 border-gray-400"
                  scope="col"
                  key={column.key}
                >
                  {column.label}
                </CTableHeaderCell>
              ))}
            </CTableRow>
            {isLoading
              ? columnsNoOffers.map((_, index) => (
                  <CTableDataCell key={index}>
                    <DefaultSkeleton />
                  </CTableDataCell>
                ))
              : enrollmentsRequireOffer?.map((item, _index) => (
                  <CTableRow
                    onMouseOver={() => {
                      setMouseOverIndexNoOffers(() => _index);
                    }}
                    key={item.id}
                    style={{
                      display: "contents",
                    }}
                    color={_index === mouseOverIndexNoOffers ? "info" : ""}
                  >
                    <CTableDataCell className="flex items-center h-full">
                      {item.id}
                    </CTableDataCell>
                    <CTableDataCell className="flex items-center h-full">
                      {item.title}
                    </CTableDataCell>
                    <CTableDataCell className="flex items-center h-full">
                      <CategoryBadge category={item.category} />
                    </CTableDataCell>
                    <CTableDataCell className="flex items-center h-full">
                    <div className="relative flex items-center">
                      <CIcon icon={cilInfo} className="text-orange-500 text-2xl animate-pulse" />
                      <span className="ml-2 text-sm text-orange-600">{getActionMessage(item)}</span>
                    </div>
                    </CTableDataCell>
                    <CTableDataCell className="flex items-center h-full gap-2">
                      <CustomButton
                        variant="outline"
                        className="border-orange-500 text-orange-500 hover:bg-orange-100"
                        color="info"
                        onClick={() => {
                          navigate(`/discover/${item.id}`, {
                            state: "discover-preview",
                          });
                        }}
                      >
                        <div className="flex items-center gap-1">
                        <span class="relative flex h-1 w-3">
  <span class="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-orange-400 opacity-75"></span>
  <span class="relative inline-flex rounded-full h-2 w-2 bg-orange-500"></span>
</span>
{t("CommonTables.fixIt")}
                        </div>
                        
                      </CustomButton>
                    </CTableDataCell>
                  </CTableRow>
                ))}
          </CTableBody>
        </CTable>
      </TableShadowWrapper>
      )}
      <hr className="my-4 border-t border-gray-900" />


      {/* Second Table: Enrollments with offers */}
      {enrollmentsWithOffers && enrollmentsWithOffers?.length > 0 && (
        <>
      <h4 className="mt-6 mb-2 p-3 rounded-lg flex items-center justify-center gap-2 text-white bg-green-500 font-bold text-lg shadow-lg">
        <CIcon icon={cilCheckCircle} className="text-white mr-2" /> {/* CoreUI check icon */}
        {t("PageMyEnrollments.EnrollmentsWithOffers")}
      </h4>

      <TableShadowWrapper>
        <CTable className="bg-white rounded-lg shadow-lg">
          <CTableBody
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr auto auto auto auto",
            }}
            onMouseLeave={() => {
              setMouseOverIndex(() => null);
            }}
          >
            {/* Table Header */}
            <CTableRow
              className="text-base font-extrabold tracking-wider text-gray-900 uppercase bg-gray-200"
              style={{
                display: "contents",
              }}
              onMouseOver={() => {
                setMouseOverIndex(() => null);
              }}
            >
              {columns
                .slice(0, -1)
                .concat({
                  key: "offer_status",
                  label: (
                    <div className="flex items-center h-full gap-1">
                      <span>
                        {t("PageMyEnrollments.SearchEnrollmentsTable.offerStatus")}
                      </span>
                    </div>
                  ),
                })
                .concat(columns.slice(-1))
                .map((column) => (
                  <CTableHeaderCell
                    className="py-4 border-b-2 border-gray-400"
                    scope="col"
                    key={column.key}
                  >
                    {column.label}
                  </CTableHeaderCell>
                ))}
            </CTableRow>
            {/* Table Rows */}
            {isLoading
              ? columns.map((_, index) => (
                  <CTableDataCell key={index}>
                    <DefaultSkeleton />
                  </CTableDataCell>
                ))
              : enrollmentsWithOffers?.map((item, _index) => (
                  <CTableRow
                    onMouseOver={() => {
                      setMouseOverIndex(() => _index);
                    }}
                    key={item.id}
                    style={{
                      display: "contents",
                    }}
                    color={_index === mouseOverIndex ? "info" : ""}
                  >
                    <CTableDataCell className="flex items-center h-full">
                      {item.id}
                    </CTableDataCell>
                    <CTableDataCell className="flex items-center h-full">
                      {item.title}
                    </CTableDataCell>
                    <CTableDataCell className="flex items-center h-full">
                      <CategoryBadge category={item.category} />
                    </CTableDataCell>
                    <CTableDataCell className="flex items-center h-full">
                      {dateToYearMonthDay(item.deadline)}
                    </CTableDataCell>
                    <CTableDataCell className="flex items-center h-full">
                      {item.latest_offer_status ? (
                        <OfferStatusBadge status={item.latest_offer_status} />
                      ) : (
                        t("PageMyEnrollments.NoOffer")
                      )}
                    </CTableDataCell>
                    <CTableDataCell className="flex items-center h-full gap-2">
                      <CustomButton
                        variant="outline"
                        color="info"
                        onClick={() => {
                          navigate(`/discover/${item.id}`, {
                            state: "discover-preview",
                          });
                        }}
                      >
                        {t("CommonTables.show")}
                      </CustomButton>
                    </CTableDataCell>
                  </CTableRow>
                ))}
          </CTableBody>
        </CTable>
      </TableShadowWrapper>
      </>
      )}
      {/* Pagination, adjust as needed */}
      <CustomPagination
        pageCount={pageCount}
        currentPage={p}
        onPageChange={setPage}
      />
      {!isLoading && count === 0 && (
        <div className="flex flex-col items-center justify-center h-64">
          <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg shadow-lg">
            <p className="text-xl font-bold text-gray-700">No Data Available</p>
            <p className="mt-2 text-center text-gray-500">
              There is currently no data to display. Create your first item!
            </p>
            <p className="mt-2 text-center text-gray-500">
              In case of issues, reach out to{" "}
              <a
                href="mailto:support@machinevertical.com"
                className="text-blue-500 underline"
              >
                support@machinevertical.com
              </a>
            </p>
          </div>
        </div>
      )}
    </>
  );
};
