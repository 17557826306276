import { EUserAccountType } from "@/enums/user-metadata";
import { useDefaultUserGroup } from "@/hooks/use-default-user-group";
import { useAuth, useUser } from "@clerk/clerk-react";

type TPageAuthWrapperProps = {
  defaultElement?: JSX.Element;
  clientUserElement?: JSX.Element;
  adminUserElement?: JSX.Element;
};

export const PageAuthSwitch = (props: TPageAuthWrapperProps) => {
  const { defaultElement, adminUserElement: adminUserElement, clientUserElement: clientUserElement } = props;
  const {} = useAuth();
  const { user, isLoaded, isSignedIn } = useUser();
  
  const isClientUser = 
    isLoaded &&
    isSignedIn &&
    user.unsafeMetadata?.accountType === EUserAccountType.CLIENT_USER;
  
  const isAdminUser = 
    isLoaded &&
    isSignedIn &&
    user.unsafeMetadata?.accountType === EUserAccountType.ADMIN_USER;

  useDefaultUserGroup({ enabled: isClientUser || isAdminUser });

  if (isClientUser && clientUserElement) {
    return clientUserElement;
  }
  if (isAdminUser && adminUserElement) {
    return adminUserElement;
  }
  if (isLoaded && !isSignedIn && !user) {
    return defaultElement;
  }

  return <></>;
};
