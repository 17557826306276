import {
  MAX_ATTACHMENT_SIZE,
  MAX_ATTACHMENTS,
} from "@/constants/form-constatns";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { integerToFilesize } from "@/utils/integer-to-filesize";
import { Trans } from "react-i18next";

type TOfferCreateEditViewTableValidationsProps = {
  isTooManyEstimatedAttachments: boolean;
};

export const OfferCreateEditViewTableValidations = ({
  isTooManyEstimatedAttachments,
}: TOfferCreateEditViewTableValidationsProps) => {
  const { t } = useAppTranslation();

  return (
    <div className="flex flex-col items-center w-full py-4">
      <span
        className={`${isTooManyEstimatedAttachments ? "text-red-500" : ""}`}
      >
        <Trans
          i18nKey={"AttachmentsTable.Input.offersHaveNoMoreThan"}
          values={{ count: MAX_ATTACHMENTS }}
          components={{ 1: <span className="font-bold" /> }}
        />
      </span>
      {isTooManyEstimatedAttachments ? (
        <span className="text-red-500">
          {t("AttachmentsTable.Input.pleaseRemoveToMeetMaxRequirement", {
            count: MAX_ATTACHMENTS,
          })}
        </span>
      ) : null}
      <span>
        <Trans
          i18nKey={"AttachmentsTable.Input.maxSizeAllowed"}
          values={{ size: integerToFilesize(MAX_ATTACHMENT_SIZE) }}
          components={{ 1: <span className="font-bold" /> }}
        />
      </span>
    </div>
  );
};
