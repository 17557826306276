import { useEffect, useRef, useState } from "react";
import {
  ListingsTable,
  TListingsTableProps,
} from "./components/listings-table";
import { CustomPagination } from "@/components/forms/custom/custom-pagination/custom-pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { useAppDispatch } from "@/store/hooks";
import { useQuery } from "@tanstack/react-query";
import { authedFetch } from "@/utils/authed-fetch";
import { CButton, CFormSwitch, CTooltip } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilBan, cilSearch } from "@coreui/icons";
import { dateUtcToLocal } from "@/utils/date-fns";
import { CustomStatusBadge } from "@/components/forms/custom/custom-badge/custom-status-badge";
import { setModalConfirmation } from "@/store/modal/slice";
import { useHideListingMutation } from "@/views/pages/office-user/hooks/use-hide-listing-mutation";
import { useDeleteListingMutation } from "@/views/pages/office-user/hooks/use-delete-listing-mutation";
import { EListingCategory, EListingStatus } from "@/enums/listing-enums";
import { GroupsAndListingsButtons } from "../components/groups-and-listings-buttons";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import { useDebounce } from "@/hooks/use-debounce";
import { TableShadowWrapper } from "@/components/forms/custom/custom-table/table-shadow-wrapper";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { CategorySelect } from "@/components/forms/custom/custom-select/category-select";

type TQuery = {
  listings: {
    id: number;
    group_id: number;
    title: string;
    created_at: string;
    created_by: string;
    status: EListingStatus;
    category: EListingCategory;
    is_hidden: 0 | 1;
  }[];
  count: number;
};

type TSpreadableParams = {
  p?: string;
  ps?: string;
  title?: string;
  category?: string;
  orderBy?: string;
  orderDir?: string;
};

const OfficeUserListings = () => {
  useBreadcrumbs("LISTINGS");
  const { t } = useAppTranslation();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const pageRef = useRef(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const p = parseInt(searchParams.get("p") || "1");
  const ps = parseInt(searchParams.get("ps") || "0");
  const titleRaw = searchParams.get("title") || "";
  const category =
    (searchParams.get("category") as EListingCategory) ||
    ("" as EListingCategory);
  const orderBy = searchParams.get("orderBy") || "";
  const orderDir = searchParams.get("orderDir") || "";

  const spreadableParams: TSpreadableParams = {};
  if (p && p <= 1) spreadableParams.p = p.toString();
  if (ps) spreadableParams.ps = ps.toString();
  if (titleRaw) spreadableParams.title = titleRaw;
  if (category) spreadableParams.category = category;
  if (orderBy) spreadableParams.orderBy = orderBy;
  if (orderDir) spreadableParams.orderDir = orderDir;

  const title = useDebounce(titleRaw, 250);

  const { data, isLoading, refetch } = useQuery<TQuery>({
    queryKey: ["listings", p, ps, orderBy, orderDir, title, category],
    queryFn: async ({ queryKey }) => {
      const [_, p, ps, orderBy, orderDir, title, category] = queryKey;
      const pParam = p ? `p=${p}` : "";
      const psParam = ps ? `ps=${ps}` : "";
      const orderByParam = orderBy ? `orderBy=${orderBy}` : "";
      const orderDirParam = orderDir ? `orderDir=${orderDir}` : "";
      const titleParam = title ? `title=${title}` : "";
      const categoryParam = category ? `category=${category}` : "";

      const params =
        p || ps || orderBy || orderDir || title || category
          ? `?${[
              pParam,
              psParam,
              orderByParam,
              orderDirParam,
              titleParam,
              categoryParam,
            ]
              .filter((param) => param)
              .join("&")}`
          : "";
      return authedFetch({
        endpoint: `api/office/listing${params}`,
        token: await getToken(),
      }).then((res) => res.json());
    },
  });

  const pageCount = Math.ceil((data?.count || 0) / 10);

  const hideMutation = useHideListingMutation({ refetch });
  const deleteMutation = useDeleteListingMutation({ refetch });

  const setPage = (page: number) =>
    setSearchParams({ ...spreadableParams, p: page.toString() });

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setSearchParams({ ...spreadableParams, title: e.target.value });
    } else {
      delete spreadableParams.title;
      setSearchParams({ ...spreadableParams });
    }
  };

  const handleChangeCategory = (selectedCategory?: EListingCategory) => {
    if (selectedCategory && selectedCategory !== category) {
      setSearchParams({ ...spreadableParams, category: selectedCategory });
    } else {
      delete spreadableParams.category;
      setSearchParams({ ...spreadableParams });
    }
  };

  const handleDelete = (id: number) => {
    deleteMutation.mutate({ id });
  };

  const handleHide = (id: number, is_hidden: boolean) => {
    hideMutation.mutate({ id, is_hidden: !is_hidden });
  };

  const dispatchDeleteModal = ({ id, title }: { id: number; title: string }) =>
    setModalConfirmation({
      isOpen: true,
      title: t("Modals.Listing.DeleteConfirmation.title"),
      message: t("Modals.Listing.DeleteConfirmation.message"),
      messageSecondary: `#${id}: ${title}`,
      buttons: {
        cancel: {
          label: t("CommonTables.cancel"),
          color: "dark",
          variant: "outline",
        },
        confirm: {
          label: t("CommonTables.delete"),
          color: "danger",
          onClick: () => handleDelete(id),
        },
      },
    });

  const dispatchHideModal = ({
    id,
    title,
    is_hidden,
  }: {
    id: number;
    title: string;
    is_hidden: boolean;
  }) =>
    setModalConfirmation({
      isOpen: true,
      title: is_hidden
        ? t("Modals.Listing.HideRevealConfirmation.titleReveal")
        : t("Modals.Listing.HideRevealConfirmation.titleHide"),
      message: is_hidden
        ? t("Modals.Listing.HideRevealConfirmation.messageReveal")
        : t("Modals.Listing.HideRevealConfirmation.messageHide"),
      messageSecondary: `#${id}: ${title}`,
      buttons: {
        cancel: {
          label: t("CommonTables.cancel"),
          color: "secondary",
          variant: "outline",
        },
        confirm: {
          label: is_hidden ? t("CommonTables.reveal") : t("CommonTables.hide"),
          color: is_hidden ? "info" : "danger",
          onClick: () => handleHide(id, is_hidden),
        },
      },
    });

  const tbody: TListingsTableProps["tbody"] =
    data?.listings?.map?.((item) => ({
      id: item.id,
      group_id: item?.group_id,
      status: (
        <h5>
          <CustomStatusBadge status={item?.status} />
        </h5>
      ),
      title: item?.title,
      category: item?.category,
      created_by: item?.created_by,
      created_at: (
        <span className="whitespace-nowrap">
          {dateUtcToLocal(item?.created_at)}
        </span>
      ),
      hidden: (
        <div className="flex justify-center">
          <CFormSwitch
            checked={!!item.is_hidden}
            size="xl"
            color="info"
            onChange={() => {}}
            onClick={() => {
              dispatch(
                dispatchHideModal({
                  id: item.id,
                  title: item.title,
                  is_hidden: !!item.is_hidden,
                })
              );
            }}
          />
        </div>
      ),
      actions: (
        <div className="flex items-center w-auto h-full gap-1">
          <CTooltip
            content={t("CommonTooltips.view")}
            container={pageRef.current}
          >
            <CButton
              color="info"
              onClick={() => {
                navigate(
                  `/listing-group/${item?.group_id}/listing/${item?.id}`
                );
              }}
            >
              <span className="text-white">
                <CIcon icon={cilSearch} />
              </span>
            </CButton>
          </CTooltip>

          <CTooltip
            content={t("CommonTooltips.remove")}
            container={pageRef.current}
          >
            <CButton
              color="danger"
              onClick={() => {
                dispatch(
                  dispatchDeleteModal({ id: item.id, title: item.title })
                );
              }}
            >
              <span className="text-white">
                <CIcon icon={cilBan} />
              </span>
            </CButton>
          </CTooltip>
        </div>
      ),
    })) || [];

  useEffect(() => {
    if ((p < 0 || p > pageCount) && !isLoading) {
      setPage(1);
    }
    if (firstLoad) {
      setFirstLoad(false); // needed for reliable ref mount
    }
  }, [p, pageCount, firstLoad, isLoading]);

  return (
    <div className="max-w-full" ref={pageRef}>
      <GroupsAndListingsButtons activeState="listings" />

      <div className="flex justify-between pt-3 pb-2">
        <div>
          <h1>{t("PageListings.NavButtons.listings")}</h1>
        </div>
      </div>

      <div className="flex items-center gap-3 px-3 py-3 bg-white rounded-t-xl">
        <div className="flex items-center gap-2">
          <span className="font-bold">
            {t("PageListings.ListingsTable.title")}:{" "}
          </span>
          <div>
            <CustomInput
              placeholder={t("CommonTables.searchDots")}
              value={titleRaw}
              onChange={handleChangeTitle}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setSearchParams({ ...spreadableParams, search: "" });
                }
              }}
              divWrap
              clearable
            />
          </div>
        </div>

        <div className="flex items-center gap-2">
          <span className="font-bold">
            {t("PageListings.ListingsTable.category")}:{" "}
          </span>
          <CategorySelect
            valid={false}
            invalid={false}
            value={category}
            onChange={handleChangeCategory}
          />
        </div>
      </div>

      <div className="flex flex-col items-center max-w-full">
        <div className="self-stretch h-[5px] bg-white mb-[-5px]" />
        <TableShadowWrapper>
          <ListingsTable
            tbody={tbody}
            isLoading={isLoading}
            count={data?.count || 0}
          />
        </TableShadowWrapper>

        <CustomPagination
          pageCount={pageCount}
          currentPage={p}
          onPageChange={setPage}
        />
      </div>
    </div>
  );
};

export default OfficeUserListings;
