import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { setModalConfirmation } from "@/store/modal/slice";

export const ConfirmationModal = () => {
  // const { title, message, buttons, buttonProps, tooltip } = props;
  const dispatch = useAppDispatch();
  const modalState = useAppSelector((state) => state.modal.confirmationModal);
  const isOpen = modalState.isOpen;
  const title = modalState.title;
  const message = modalState.message;
  const messageSecondary = modalState.messageSecondary;
  const buttons = modalState.buttons;

  const onModalClose = () => {
    dispatch(setModalConfirmation({ isOpen: false }));
  };

  return (
    <CModal visible={isOpen} onClose={onModalClose} color="primary">
      <CModalHeader closeButton>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p>{message}</p>
        <h6>{messageSecondary}</h6>
      </CModalBody>
      <CModalFooter>
        <CButton
          type="button"
          color={buttons?.cancel.color ?? "secondary"}
          variant={buttons?.cancel.variant ?? "outline"}
          className={buttons?.cancel?.className}
          onClick={() => {
            buttons?.cancel?.onClick?.();
            onModalClose();
          }}
        >
          {buttons?.cancel.label}
        </CButton>
        <CButton
          color={buttons?.confirm.color ?? "danger"}
          variant={buttons?.confirm.variant}
          className={buttons?.confirm?.className}
          onClick={() => {
            buttons?.confirm?.onClick?.();
            onModalClose();
          }}
        >
          <span
            className={
              buttons?.confirm.color === "info" ||
              buttons?.confirm.color === "danger"
                ? "text-white"
                : ""
            }
          >
            {buttons?.confirm.label}
          </span>
        </CButton>
      </CModalFooter>
    </CModal>
  );
};
