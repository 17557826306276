export enum EListingStatus {
  OPEN = "OPEN",
  DRAFT = "DRAFT",
  CLOSED = "CLOSED",
}

export enum EListingCategory {
  CNC = "CNC",
  WOOD_MACHINERY = "WOOD_MACHINERY",
  WOOD_SCANNERS = "WOOD_SCANNERS",
  FURNITURE_MACHINERY = "FURNITURE_MACHINRY",
  METAL_MACHINERY = "METAL_MACHINERY",
  SOFTWARE = "SOFTWARE",
  OTHER_EQUIPMENT = "OTHER EQUIPMENT",
}

export enum EListingRequiredOfferType {
  Both = "both",
  NewOnly = "new_only",
  UsedOnly = "used_only",
}
