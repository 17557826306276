type TQueryOffer = {
  enrollmentId?: number;
  comment?: string;
  files: File[];
  attachmentUrlsToDelete: string[];
  token: string;
  offerId?: number;
};

export const queryOffer = async ({
  enrollmentId,
  comment,
  files,
  attachmentUrlsToDelete,
  token,
  offerId,
}: TQueryOffer) => {
  const attachmentFormData = new FormData();
  files.forEach((file) => {
    attachmentFormData.append("file", file);
  });

  if (enrollmentId) {
    attachmentFormData.append("enrollment_id", enrollmentId.toString());
  }

  if (comment) {
    attachmentFormData.append("comment", comment);
  }

  if (offerId) {
    attachmentFormData.append("offer_id", offerId.toString());
  }

  if (attachmentUrlsToDelete.length) {
    attachmentUrlsToDelete.forEach((fileUrl) => {
      attachmentFormData.append("files_to_delete", fileUrl);
    });
  }

  // @ts-ignore
  const endpoint = import.meta.env.VITE_API_ENDPOINT;
  const attachmentsRes = await fetch(
    `${endpoint}/api/provider/enrollment/${enrollmentId}/offer`,
    {
      method: "POST",
      body: attachmentFormData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return attachmentsRes;
};
