import { CFormTextarea, CInputGroup, CFormSwitch, CInputGroupText, CTooltip, CFormText } from "@coreui/react";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { TSpecification } from "../../types/listing-types";
import { TListingEditForm } from "../listing-view-edit";
import { useEffect, useState } from "react";
import { FormikErrors } from "formik";
import { CustomButton } from "@/components/forms/custom/custom-button/custom-button";
import CIcon from "@coreui/icons-react";
import { cilTrash, cilAsterisk, cilCheckCircle } from "@coreui/icons";

type TListingViewSpecificationsProps = {
  valueSpecifications?: TSpecification[];
  errors: FormikErrors<TListingEditForm>;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  hasSubmittedDraft: boolean;
  hasSubmittedPublish: boolean;
  handleDelete: (id: number) => void;
  isProvider: boolean;
  hasSubmittedEither: boolean;
  isEditing: boolean;
  isLoading?: boolean;
};

export const ListingViewSpecifications = (
  props: TListingViewSpecificationsProps
) => {
  const {
    valueSpecifications,
    errors,
    handleDelete,
    handleChange,
    setFieldValue,
    isProvider,
    hasSubmittedDraft,
    hasSubmittedPublish,
    hasSubmittedEither,
    isEditing,
    isLoading,
  } = props;
  const { t } = useAppTranslation();

  const [specifications, setSpecifications] = useState<TSpecification[]>(valueSpecifications || []);
  const isProviderRole = isProvider ?? false;

  const titleRequired = t("PageListingCreateEdit.Specifications.required");
  const titleOptional = t("PageListingCreateEdit.Specifications.optional");

  const handleAddSpecification = () => {
    const newSpecifications = [{ value: "", is_optional: false }, ...specifications];
    setSpecifications(newSpecifications);
    setFieldValue("Specifications", newSpecifications); // Update Formik field value
  };

  const handleDeleteSpecification = (index: number) => {
    let specToDelete = specifications[index];
    if (specToDelete.id) {
      handleDelete(specToDelete.id);
    }
    // Filter out the specification at the given index
    const updatedSpecifications = specifications.filter((_, i) => i !== index);

    // Update state and form values with the new specifications array
    setSpecifications(updatedSpecifications);
    setFieldValue("Specifications", updatedSpecifications); // Use the updated array here
  };

  const handleSpecificationChange = (index: number, field: keyof TSpecification, value: string | boolean) => {
    const newSpecifications = [...specifications];
    newSpecifications[index] = { ...newSpecifications[index], [field]: value };
    console.log('changed!');
    setSpecifications(newSpecifications);
    setFieldValue("Specifications", newSpecifications);
  };

  const handleAutoGrow = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = event.target;
    textarea.style.height = 'auto'; // Reset height to auto to recompute height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set height to match scroll height
  };

  useEffect(() => {
    if (valueSpecifications) {
          setSpecifications(valueSpecifications);
    }
  }, [valueSpecifications]);

  const maxCharacters = 255; // Maximum number of characters allowed

  if (isLoading) {
    return (
      <>
        <div className="mb-4">
          <label className="form-label pt-[9px]">{titleRequired}</label>
          <DefaultSkeleton
            className="px-[0px] py-[0px] max-w-full h-[62px]"
            classNameInner="h-full rounded-lg mt-0 mb-0"
          />
        </div>
        <div className="mb-4">
          <label className="form-label pt-[9px]">{titleOptional}</label>
          <DefaultSkeleton
            className="px-[0px] py-[0px] max-w-full h-[62px] "
            classNameInner="h-full rounded-lg mt-0 mb-0"
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="p-3 border border-gray-200 rounded-lg">
      {!isProvider && (<CustomButton disabled={!isEditing} type="button" color={specifications.length == 0 && errors?.Specifications ? 'danger' : 'primary'} onClick={handleAddSpecification} className="mb-3">
        <CTooltip content={t("PageListingCreateEdit.Specifications.add_tooltip")}>
        <span className="flex items-center justify-center gap-1 min-w-16">
          <span> + {t("PageListingCreateEdit.Specifications.add")}</span>
        </span>
        </CTooltip>
        </CustomButton>
        )}
        {specifications.length == 0 && errors?.Specifications && (
                  <div className="d-block is-invalid" style={{ padding: '0.5rem 1rem', color: 'red' }}>
                    {errors?.Specifications}
                  </div>
        )}
        {specifications.map((specification, index) => (
          <div className="mb-3 d-flex align-items-center" key={index} style={{ gap: '1rem' }}>
            <CInputGroup className="d-flex align-items-center" style={{ width: '100%' }}>
            <div
        style={{
          width: '2rem',
          textAlign: 'center',
          borderRight: '1px solid #ced4da', // Separator line
          padding: '0.5rem',
          fontWeight: 'bold',
        }}
      >
        {index + 1}
      </div>
              {/* Combined Border and Alignment */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'stretch',
                  border: '1px solid #ced4da', // Uniform border for all elements
                  borderRadius: '0.25rem', // Apply same border radius to all elements
                  overflow: 'hidden', // Clip child borders
                  flexGrow: 1,
                }}
                className={errors?.Specifications && errors.Specifications[index]?.value ? "is-invalid" : ""}
              >
                {/* Textarea Input Field */}
                <CFormTextarea
                  invalid={errors?.Specifications && errors?.Specifications[index]?.value !== undefined}
                  valid={errors?.Specifications && errors?.Specifications[index]?.value == undefined}
                  disabled={!isEditing}
                  value={specification.value}
                  placeholder="Specification Value"
                  onChange={(e) => {
                    handleChange(e);
                    handleSpecificationChange(index, "value", e.target.value);
                    handleAutoGrow(e); // Auto-grow as the user types
                  }}
                  maxLength={maxCharacters} // Restrict maximum characters to 255
                  minLength={1}
                  required
                  rows={1} // Initial row size
                  style={{
                    flexGrow: 1, // Allows the textarea to grow as needed
                    minWidth: '150px', // Set a minimum width for the textarea
                    resize: 'none', // Disable manual resizing
                    border: 'none', // Remove individual border for uniform row styling
                  }}
                />
                {/* Character Counter with Matching Border */}
                {isEditing && (<div
                  className="d-flex align-items-center"
                  style={{
                    fontSize: '0.8rem',
                    padding: '0.5rem 1rem',
                    borderLeft: '1px solid #ced4da', // Only show left border to separate
                  }}
                >
                  {specification.value.length}/{maxCharacters}
                </div>
                )}
                {/* Optional Toggle Switch with Matching Border */}
                <div
                  className="d-flex align-items-center"
                  style={{
                    padding: '0.5rem 1rem',
                    borderLeft: '1px solid #ced4da', // Only show left border to separate
                  }}
                >
                  {isProvider && (
                    <span className="me-2">
                    {!specification.is_optional ? (
                      <>
                       <CIcon icon={cilCheckCircle} className="me-1" />
                    {t("PageListingCreateEdit.Specifications.optional_provider_yes")}
                    </>
                     ) : (
                      <>
                    <CIcon icon={cilAsterisk} className="me-1 text-danger" />
                    {t("PageListingCreateEdit.Specifications.optional_provider_no")}
                    </>
                    )}
                  </span>
                  )}
                  {!isProvider && (
                    <>
                  <span className="me-2">{t("PageListingCreateEdit.Specifications.optional")}</span>
                   <CTooltip content={t("UI.Buttons.optional_specification_toggle")}>
                  <CFormSwitch
                    disabled={!isEditing}
                    size="lg"
                    onChange={(e) => {
                      handleChange(e);
                      handleSpecificationChange(index, "is_optional", e.target.checked)
                    }}
                    checked={specification.is_optional}
                  />
                  </CTooltip>
                  </>
                  )}
                </div>
                {/* Delete Button */}
                {isEditing && (
                <CTooltip content={t("UI.Buttons.remove_specification")}>
                <CInputGroupText>
                  <CustomButton
                    disabled={!isEditing}
                    type="button"
                    color="danger"
                    onClick={() => handleDeleteSpecification(index)}
                  >
                    <CIcon icon={cilTrash} />
                  </CustomButton>
                </CInputGroupText>
                </CTooltip>
                )}
              </div>
              {errors?.Specifications && errors.Specifications[index]?.value && (
                  <div className="is-invalid d-block" style={{ padding: '0.5rem 1rem', color: 'red' }}>
                    {errors.Specifications[index].value}
                  </div>
                )}
            </CInputGroup>
          </div>
        ))}
        </div>
        </>
  )
};
