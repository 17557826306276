import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TGroupState = {
  defaultGroupId?: number;
};

const initialState: TGroupState = {
  defaultGroupId: undefined,
};

export const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    setDefaultGroupId: (
      state,
      action: PayloadAction<TGroupState["defaultGroupId"]>
    ) => {
      state.defaultGroupId = action.payload;
    },
  },
});

export const { setDefaultGroupId } = groupSlice.actions;
export default groupSlice.reducer;
