// DiscoverTable.tsx

import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@clerk/clerk-react';
import { useDebounce } from '@/hooks/use-debounce';
import { SortArrow } from '@/components/forms/custom/custom-table/sort-arrow';
import { authedFetch } from '@/utils/authed-fetch';
import { dateToYearMonthDay } from '@/utils/date-fns';
import { useAppTranslation } from '@/locales/helpers/translation-helpers';
import { CategoryBadge } from '@/components/badges/category-badge';
import CustomInput from '@/components/forms/custom/custom-input/custom-input';
import { CategorySelect } from '@/components/forms/custom/custom-select/category-select';
import { CustomPagination } from '@/components/forms/custom/custom-pagination/custom-pagination';
import { DefaultSkeleton } from '@/components/loaders/default-skeleton';
import {
  EListingCategory,
  EListingRequiredOfferType,
} from '@/enums/listing-enums';
import { ECountry } from '@/enums/location-enums'; // Import ECountry enum
import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilFilter } from '@coreui/icons';
import {
  cifLt,
  cifPl,
  cifLv,
  cifEe,
  cifIt,
  // Add more country icons as needed
} from '@coreui/icons'; // Import country icons

type TColumn = {
  key: string;
  label: React.ReactNode;
};

type TQuery = {
  count: number;
  listings: {
    id: number;
    category: EListingCategory;
    title: string;
    country: ECountry;
    deadline: string;
    created_at: string;
  }[];
};

type TSpreadableParams = {
  p?: string;
  ps?: string;
  title?: string;
  orderBy?: string;
  orderDir?: string;
  country?: ECountry;
  category?: string;
  requiredOfferType?: EListingRequiredOfferType;
};

export const DiscoverTable = () => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { t } = useAppTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [mouseOverIndex, setMouseOverIndex] = useState<number | null>(null);
  const [filtersVisible, setFiltersVisible] = useState<boolean>(true); // Toggle for filters visibility

  // Extract search parameters
  const p = parseInt(searchParams.get('p') || '1');
  const ps = parseInt(searchParams.get('ps') || '0');
  const requiredOfferTypeValue: EListingRequiredOfferType = searchParams.get('requiredOfferType') as EListingRequiredOfferType;

  const titleRaw = searchParams.get('title') || '';
  const countryRaw = searchParams.get('country') || '';
  const country = (countryRaw as ECountry) || undefined;
  const category =
    (searchParams.get('category') as EListingCategory) ||
    ('' as EListingCategory);
  const orderBy = searchParams.get('orderBy') || '';
  const orderDir = searchParams.get('orderDir') || '';

  // Debounce inputs
  const title = useDebounce(titleRaw, 250);

  // Prepare spreadable params
  const spreadableParams: TSpreadableParams = {};
  if (p && p > 1) spreadableParams.p = p.toString();
  if (ps) spreadableParams.ps = ps.toString();
  if (title) spreadableParams.title = title;
  if (country) spreadableParams.country = country;
  if (orderBy) spreadableParams.orderBy = orderBy;
  if (orderDir) spreadableParams.orderDir = orderDir;
  if (category) spreadableParams.category = category;
  if (requiredOfferTypeValue) {
    spreadableParams.requiredOfferType = requiredOfferTypeValue;
  }

  const setPage = (page: number) =>
    setSearchParams({ ...spreadableParams, p: page.toString() });

  const setOrder = (orderBy: string, orderDir: string) =>
    setSearchParams({ ...spreadableParams, orderBy, orderDir });

  // Fetch data using react-query
  const { data, isLoading } = useQuery<TQuery>({
    queryKey: [
      'discover-table',
      p,
      ps,
      orderBy,
      orderDir,
      title,
      country,
      category,
      requiredOfferTypeValue,
    ],
    queryFn: async ({ queryKey }) => {
      const [
        _,
        p,
        ps,
        orderBy,
        orderDir,
        title,
        country,
        category,
        requiredOfferTypeValue,
      ] = queryKey;

      const params = new URLSearchParams();
      if (p) params.append('p', p.toString());
      if (ps) params.append('ps', ps.toString());
      if (orderBy) params.append('orderBy', orderBy.toString());
      if (orderDir) params.append('orderDir', orderDir.toString());
      if (title) params.append('title', title.toString());
      if (country) params.append('country', country.toString());
      if (category) params.append('category', category.toString());
      if (requiredOfferTypeValue) {
        params.append('requiredOfferType', requiredOfferTypeValue);
      }

      const response = await authedFetch({
        endpoint: `api/provider/discover?${params.toString()}`,
        token: await getToken(),
      });
      return response.json();
    },
  });

  const pageCount = Math.ceil((data?.count || 0) / 10);

  const requiredOfferTypeOptions = [
    {
      label: t('Enums.ListingRequiredOfferType.searchFilter.newOnly'),
      value: EListingRequiredOfferType.NewOnly,
    },
    {
      label: t('Enums.ListingRequiredOfferType.searchFilter.usedOnly'),
      value: EListingRequiredOfferType.UsedOnly,
    },
  ];

  const count = data?.count || 0;

  // Country Options
  type TCountryOption = {
    icon: string[];
    label: string;
    value: ECountry;
  };

  const mapCountryWithIconOption = (country: ECountry): TCountryOption => {
    switch (country) {
      case ECountry.LT:
        return {
          icon: cifLt,
          label: t('Enums.Country.lt'),
          value: ECountry.LT,
        };
      case ECountry.PL:
        return {
          icon: cifPl,
          label: t('Enums.Country.pl'),
          value: ECountry.PL,
        };
      case ECountry.LV:
        return {
          icon: cifLv,
          label: t('Enums.Country.lv'),
          value: ECountry.LV,
        };
      case ECountry.EE:
        return {
          icon: cifEe,
          label: t('Enums.Country.ee'),
          value: ECountry.EE,
        };
      case ECountry.IT:
        return {
          icon: cifIt,
          label: t('Enums.Country.it'),
          value: ECountry.IT,
        };
      // Add more cases as needed
      default:
        return {
          icon: [],
          label: country,
          value: country,
        };
    }
  };

  const countryOptions: TCountryOption[] = [
    ECountry.LT,
    ECountry.PL,
    ECountry.LV,
    ECountry.EE,
    ECountry.IT,
    // Add more countries as needed
  ].map((country) => mapCountryWithIconOption(country));

  // Handlers for input changes
  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParams({ ...spreadableParams, title: e.target.value });
  };

  const handleChangeCountry = (value: ECountry) => {
    if (value) {
      setSearchParams({ ...spreadableParams, country: value });
    } else {
      delete spreadableParams.country;
      setSearchParams({ ...spreadableParams });
    }
  };

  const handleRequiredOfferTypeChange = (
    value: EListingRequiredOfferType
  ) => {
      setSearchParams({
        ...spreadableParams,
        requiredOfferType: value,
      });
    
  };

  const handleChangeCategory = (selectedCategory?: EListingCategory) => {
    if (selectedCategory && selectedCategory !== category) {
      setSearchParams({ ...spreadableParams, category: selectedCategory });
    } else {
      delete spreadableParams.category;
      setSearchParams({ ...spreadableParams });
    }
  };

  const handleClearFilterBadge = (key: keyof TSpreadableParams) => {
    delete spreadableParams[key];
    setSearchParams({ ...spreadableParams });
  };

  const handleClearFilterAll = () => {
    delete spreadableParams.title;
    delete spreadableParams.country;
    delete spreadableParams.category;
    delete spreadableParams.requiredOfferType;
    setSearchParams({ ...spreadableParams });
  };

  const sortArrow = (type: string) => (
    <SortArrow
      columnName={type}
      orderBy={orderBy}
      orderDir={orderDir}
      onClick={setOrder}
    />
  );

  const mapRequiredOfferTypeLabel = (type : EListingRequiredOfferType) => {
    switch (requiredOfferTypeValue) {
      case EListingRequiredOfferType.NewOnly:
        return t(
          'Enums.ListingRequiredOfferType.searchFilter.newOnly'
        );
      case EListingRequiredOfferType.UsedOnly:
        return t(
          'Enums.ListingRequiredOfferType.searchFilter.usedOnly'
        );
      default:
        return type;
    }
  }

  const columns: TColumn[] = [
    {
      key: 'id',
      label: (
        <div className="flex items-center gap-1">
          <span>#</span>
          {sortArrow('id')}
        </div>
      ),
    },
    {
      key: 'title',
      label: (
        <div className="flex items-center gap-1">
          <span>{t('PageDiscover.SearchListingsTable.title')}</span>
          {sortArrow('title')}
        </div>
      ),
    },
    {
      key: 'category',
      label: (
        <div className="flex items-center gap-1">
          <span>{t('PageDiscover.SearchListingsTable.category')}</span>
          {sortArrow('category')}
        </div>
      ),
    },
    {
      key: 'country',
      label: (
        <div className="flex items-center gap-1">
          <span>{t('PageDiscover.SearchListingsTable.country')}</span>
          {sortArrow('country')}
        </div>
      ),
    },
    {
      key: 'deadline',
      label: (
        <div className="flex items-center gap-1">
          <span>{t('PageDiscover.SearchListingsTable.availableUntil')}</span>
          {sortArrow('deadline')}
        </div>
      ),
    },
    {
      key: 'actions',
      label: (
        <div className="flex items-center gap-1">
          <span>{t('CommonTables.actions')}</span>
        </div>
      ),
    },
  ];

  return (
    <div className="bg-gray-50 p-6 rounded-lg shadow-md">
      {/* Header Section */}
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-2xl font-bold">{t('PageDiscover.searchListings')}</h3>
        {/* Filters Toggle Button */}
        <CButton
          color="primary"
          variant="ghost"
          onClick={() => setFiltersVisible(!filtersVisible)}
          aria-label="Toggle Filters"
        >
          <CIcon icon={cilFilter} size="lg" />
          <span className="ml-2">
            {filtersVisible
              ? t('PageDiscover.hideFilters')
              : t('PageDiscover.showFilters')}
          </span>
        </CButton>
      </div>

      {/* Filters Section */}
      {filtersVisible && (
        <div className="bg-white p-4 rounded-lg shadow mb-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {/* Title Filter */}
            <div>
              <label className="font-bold" htmlFor="title-filter">
                {t('PageDiscover.SearchListingsTable.title')}
              </label>
              <CustomInput
                id="title-filter"
                placeholder={t('CommonTables.searchDots')}
                value={titleRaw}
                onChange={handleChangeTitle}
                clearable
                divWrap
              />
            </div>

            {/* Category Filter */}
            <div>
              <label className="font-bold" htmlFor="category-filter">
                {t('PageDiscover.SearchListingsTable.category')}
              </label>
              <CategorySelect
                id="category-filter"
                invalid={false}
                valid={false}
                value={category}
                onChange={handleChangeCategory}
              />
            </div>

            {/* Country Filter with Dropdown */}
            <div>
              <label className="font-bold" htmlFor="country-filter">
                {t('PageDiscover.SearchListingsTable.country')}
              </label>
              <CDropdown className="flex justify-between w-full">
                <CDropdownToggle
                  className={`p-1 bg-white h-[38px] w-full flex flex-col rounded outline outline-slate-200`}
                >
                  {country ? (
                    <div className="flex items-center">
                      <CIcon
                        icon={mapCountryWithIconOption(country).icon}
                        size="lg"
                        className="mr-2"
                      />
                      {mapCountryWithIconOption(country).label}
                    </div>
                  ) : (
                    <span>{t('CommonSelect.emptyCountry')}</span>
                  )}
                </CDropdownToggle>
                <CDropdownMenu>
                  {countryOptions.map((option) => (
                    <CDropdownItem
                      key={option.value}
                      onClick={() => handleChangeCountry(option.value)}
                      active={option.value === country}
                    >
                      <div className="flex items-center">
                        <CIcon
                          icon={option.icon}
                          size="lg"
                          className="mr-2"
                        />
                        {option.label}
                      </div>
                    </CDropdownItem>
                  ))}
                </CDropdownMenu>
              </CDropdown>
            </div>

            <div>
              <div className="flex flex-col mt-2 ml-2">
                {requiredOfferTypeOptions.map((option) => (
                  <label key={option.value} className="flex items-center gap-2">
                    <input
                      type="radio"
                      value={option.value}
                      checked={requiredOfferTypeValue === option.value}
                      onChange={(e) =>
                        handleRequiredOfferTypeChange(option.value)
                      }
                      className="form-checkbox"
                    />
                    {option.label}
                  </label>
                ))}
              </div>
            </div>
          </div>

          {/* Clear All Filters Button */}
          <div className="flex justify-end mt-4">
            <CButton
              color="secondary"
              variant="outline"
              onClick={handleClearFilterAll}
            >
              {t('PageDiscover.SearchListingsTable.clearFilters')}
            </CButton>
          </div>
        </div>
      )}

      {/* Result Count */}
      <div className="mb-4 text-gray-700">
        {isLoading
          ? t('PageDiscover.loading')
          : t('PageDiscover.resultCount', { count: data?.count || 0 })}
      </div>

      {/* Badges Section */}
      {(title || country || category || requiredOfferTypeValue) && (
        <div className="flex flex-wrap items-center gap-2 mb-4">
          {title && (
            <FilterBadge
              label={`${t('PageDiscover.SearchListingsTable.title')}: ${title}`}
              onClear={() => handleClearFilterBadge('title')}
            />
          )}
          {category && (
            <FilterBadge
              label={`${t('PageDiscover.SearchListingsTable.category')}: ${category}`}
              onClear={() => handleClearFilterBadge('category')}
            />
          )}
          {country && (
            <FilterBadge
              label={`${t('PageDiscover.SearchListingsTable.country')}: ${
                mapCountryWithIconOption(country).label
              }`}
              onClear={() => handleClearFilterBadge('country')}
            />
          )}
          {requiredOfferTypeValue && (
            <FilterBadge
              label={`${mapRequiredOfferTypeLabel(requiredOfferTypeValue)}`}
              onClear={() => handleClearFilterBadge('requiredOfferType')}
            />
          )}
        </div>
      )}

      {/* Table */}
      <div className="overflow-x-auto">
        <CTable hover responsive className="bg-white rounded-lg shadow-lg">
          <CTableBody>
            <CTableRow className="bg-gray-100">
              {columns.map((column) => (
                <CTableHeaderCell
                  key={column.key}
                  className="py-3 px-4 text-left font-semibold text-gray-700"
                >
                  {column.label}
                </CTableHeaderCell>
              ))}
            </CTableRow>
            {isLoading
              ? Array.from({ length: 5 }).map((_, index) => (
                  <CTableRow key={index}>
                    {columns.map((column) => (
                      <CTableDataCell key={column.key} className="py-4 px-4">
                        <DefaultSkeleton />
                      </CTableDataCell>
                    ))}
                  </CTableRow>
                ))
              : data?.listings?.length > 0
              ? data.listings.map((item, _index) => (
                  <CTableRow
                    key={item?.id}
                    className="hover:bg-gray-50 transition-colors"
                    onMouseOver={() => setMouseOverIndex(_index)}
                    onMouseLeave={() => setMouseOverIndex(null)}
                  >
                    <CTableDataCell className="py-4 px-4">
                      {item?.id}
                    </CTableDataCell>
                    <CTableDataCell className="py-4 px-4">
                      {item?.title}
                    </CTableDataCell>
                    <CTableDataCell className="py-4 px-4">
                      <CategoryBadge category={item?.category} />
                    </CTableDataCell>
                    <CTableDataCell className="py-4 px-4">
                      <div className="flex items-center">
                        <CIcon
                          icon={mapCountryWithIconOption(item?.country).icon}
                          size="lg"
                          className="mr-2"
                        />
                        {mapCountryWithIconOption(item?.country).label}
                      </div>
                    </CTableDataCell>
                    <CTableDataCell className="py-4 px-4">
                      {dateToYearMonthDay(item?.deadline)}
                    </CTableDataCell>
                    <CTableDataCell className="py-4 px-4">
                      <CButton
                        color="primary"
                        variant="outline"
                        onClick={() => {
                          navigate(`/discover/${item.id}`, {
                            state: 'discover-preview',
                          });
                        }}
                      >
                        {t('CommonTables.show')}
                      </CButton>
                    </CTableDataCell>
                  </CTableRow>
                ))
              : !isLoading && (
                  <CTableRow>
                    <CTableDataCell
                      colSpan={columns.length}
                      className="text-center py-4"
                    >
                      {t('CommonTables.noData')}
                    </CTableDataCell>
                  </CTableRow>
                )}
          </CTableBody>
        </CTable>
      </div>

      {/* Pagination */}
      {pageCount > 1 && (
        <div className="flex justify-center mt-6">
          <CustomPagination
            pageCount={pageCount}
            currentPage={p}
            onPageChange={setPage}
          />
        </div>
      )}
    </div>
  );
};

/* FilterBadge Component */
type FilterBadgeProps = {
  label: string;
  onClear: () => void;
};

const FilterBadge: React.FC<FilterBadgeProps> = ({ label, onClear }) => (
  <span className="flex items-center bg-blue-100 text-blue-700 px-3 py-1 rounded-full">
    {label}
    <button
      onClick={onClear}
      className="ml-2 text-blue-500 hover:text-blue-700 focus:outline-none"
      aria-label="Clear filter"
    >
      &times;
    </button>
  </span>
);
