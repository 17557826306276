import { useAuth } from "@clerk/clerk-react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik, validateYupSchema } from "formik";
import { TSpecification } from "../types/listing-types";
import {
  MAX_ATTACHMENT_SIZE,
  MAX_ATTACHMENTS,
} from "@/constants/form-constatns";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  listingCreateDraftSchema,
  listingCreateSchema,
} from "../create/schema";
import {
  CTab,
  CTabContent,
  CTabList,
  CTabPanel,
  CTabs,
  CTooltip,
} from "@coreui/react";
import { EListingCategory, EListingRequiredOfferType, EListingStatus } from "@/enums/listing-enums";
import { authedFetch } from "@/utils/authed-fetch";
import CIcon from "@coreui/icons-react";
import { cilInfo, cilFile, cilUser } from "@coreui/icons";
import { StepInProcess } from "@/components/modals/components/step-in-process";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { editListingFetcher } from "./query";
import { ListingViewCustomInputTitle } from "./components/listing-view-custom-input-title";
import { ListingViewCustomSelectCategory } from "./components/listing-view-custom-select-category";
import { ListingViewSpecifications } from "./components/listing-view-specifications";
import { ListingViewDeadline } from "./components/listing-view-deadline";
import { ListingViewRestrictedBudget } from "./components/listing-view-restricted-budget";
import { ListingViewLocation } from "./components/listing-view-location";
import { ListingViewSettings } from "./components/listing-view-settings";
import { ListingViewButtons } from "./components/listing-view-buttons";
import { ListingViewEnrollmentsTable } from "./components/listing-view-enrollments-table";
import { useToastsListingViewEdit } from "./hooks/use-toasts-listing-view-edit";
import { ListingViewAttachments } from "./components/listing-view-attachments";
import { useModalsListingViewEdit } from "./hooks/use-modals-listing-view-edit";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { ECountry } from "@/enums/location-enums";
import { ListingViewEditOfferTypeSelect } from "./components/listing-view-edit-offer-type";

type TQuery = {
  id: number;
  title: string;
  created_at: string;
  created_by: string;
  status: EListingStatus;
  category: EListingCategory;
  Specifications: TSpecification[];
  is_hidden: 1 | 0;
  budget: number;
  deadline: string;
  setup_id: number;
  country: string;
  location_id: number;
  enrollment_count: number;
  requiredOfferType: EListingRequiredOfferType;
  ListingAttachment: {
    attachment_url: string;
    display_name: string;
    size: number;
    created_at: string;
    created_by: string;
    indexOfNew?: number; // for new files, NOT used for queried files
  }[];
};

const ListingViewEdit = () => {
  useBreadcrumbs("LISTING__VIEW");
  const { t } = useAppTranslation();
  const {
    toastBackendResError,
    toastListingClosed,
    toastMaxAttachments,
    toastMaxAttachmentSize,
    toastDraftEdited,
    toastEdited,
    toastStringError,
  } = useToastsListingViewEdit();
  const { modalPublishWithoutAttachments, modalEditCancelConfirmation } =
    useModalsListingViewEdit();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { listingId = "0", groupId = "0" } = useParams();
  const pageRef = useRef(null);
  const [attachmentUrlsToDelete, setAttachmentUrlsToDelete] = useState<
    string[]
  >([]);

  const [specsToDelete, setDeletedSpecificationIds] = useState<number[]>([]);

  const initialState: TListingEditFormState = {
    files: [],
    hasSubmitted: false,
    hasSubmittedDraft: false,
    isSubmitting: false,
    isRestrictedBudget: false,
    isWithDeadline: false,
    isEditingMode: false,
  };

  const [state, setState] = useState(initialState);
  const [newOffersCount, setNewOffersCount] = useState(0);
  const hasSubmittedEither = state.hasSubmitted || state.hasSubmittedDraft;
  const setFiles = (files: TListingEditFormState["files"]) =>
    setState((p) => ({ ...p, files }));
  const setHasSubmitted = (
    hasSubmitted: TListingEditFormState["hasSubmitted"]
  ) => setState((p) => ({ ...p, hasSubmitted }));
  const setHasSubmittedDraft = (
    hasSubmittedDraft: TListingEditFormState["hasSubmittedDraft"]
  ) => setState((p) => ({ ...p, hasSubmittedDraft }));
  const setIsSubmitting = (
    isSubmitting: TListingEditFormState["isSubmitting"]
  ) => setState((p) => ({ ...p, isSubmitting }));
  const setIsWithDeadline = (
    isWithDeadline: TListingEditFormState["isWithDeadline"]
  ) => setState((p) => ({ ...p, isWithDeadline }));

  const draftSchema = listingCreateDraftSchema({
    isRestrictedBudget: state.isRestrictedBudget,
    isWithDeadline: state.isWithDeadline,
  });
  const publishSchema = listingCreateSchema({
    isRestrictedBudget: state.isRestrictedBudget,
    isWithDeadline: state.isWithDeadline,
  });

  const { data, isLoading, refetch } = useQuery<TQuery>({
    queryKey: [`listings-edit`, listingId],
    queryFn: async ({ queryKey }) => {
      const [_, listingId] = queryKey;
      return authedFetch({
        endpoint: `api/office/listing/edit/${listingId}`,
        token: await getToken(),
      }).then((res) => res.json());
    },
  });

  const estimatedAttachmentCount =
    (data?.ListingAttachment || []).length +
    state.files.length -
    attachmentUrlsToDelete.length;
  const flagTriggerNoAttachmentsConfirmation = estimatedAttachmentCount === 0;

  const initialValues: TListingEditForm = {
    title: data?.title || "",
    category: data?.category || ("" as EListingCategory),
    Specifications: data?.Specifications || [] as TSpecification[],
    country: (data?.country as ECountry) || ("" as ECountry),
    budget: data?.budget || 0,
    images: [],
    deadline: data?.deadline || undefined,
    is_hidden: !!data?.is_hidden,
    requiredOfferType: (data?.requiredOfferType as EListingRequiredOfferType) || ("" as EListingRequiredOfferType),
  };

  const navigateBack = () => {
    setState((state) => ({
      ...state,
      isEditingMode: false,
    }));
  };

  const stopPublishingMutation = useMutation({
    mutationFn: async () => {
      return authedFetch({
        endpoint: `api/office/listing/close/${listingId}`,
        method: "PATCH",
        token: await getToken(),
      }).then((res) => {
        if (res.status >= 400) {
          toastBackendResError(res);
        } else {
          toastListingClosed(listingId);
          refetch();
        }
      });
    },
  });

  const listingDraftMutation = useMutation({
    mutationKey: ["listing-create-draft"],
    mutationFn: async ({ formData }: { formData: TListingEditForm }) => {
      setIsSubmitting(true);
      if (data?.status === EListingStatus.OPEN) {
        throw new Error(t("PageListingCreateEdit.Toast.draftOpenErr"));
      }
      const { budget, deadline, ...formDataRest } = formData;
      return editListingFetcher({
        formData: {
          ...formDataRest,
          locationId: data?.location_id,
          setupId: data?.setup_id,
          is_hidden: !!formData.is_hidden,
          budget: state.isRestrictedBudget ? budget : 0,
          deadline: state.isWithDeadline ? deadline : undefined,
        },
        status: EListingStatus.DRAFT,
        token: await getToken(),
        listingId: parseInt(listingId),
        specsToDelete: specsToDelete,
        groupId: parseInt(groupId),
        files: state.files,
        filesToDelete: attachmentUrlsToDelete,
      }).then((res) => {
        if (res.status >= 400) {
          toastBackendResError(res);
          setIsSubmitting(false);
        }
      });
    },
    onError: () => {
      setIsSubmitting(false);
    },
    onSuccess: () => {
      toastDraftEdited();
      setIsSubmitting(false);
      navigateBack();
    },
  });
  const listingMutation = useMutation({
    mutationKey: ["listing-create"],
    mutationFn: async ({ formData }: { formData: TListingEditForm }) => {
      setIsSubmitting(true);
      const { budget, deadline, ...formDataRest } = formData;
      return editListingFetcher({
        formData: {
          ...formDataRest,
          locationId: data?.location_id,
          setupId: data?.setup_id,
          is_hidden: !!formData.is_hidden,
          budget: state.isRestrictedBudget ? budget : 0,
          deadline: state.isWithDeadline ? deadline : undefined,
        },
        status: EListingStatus.OPEN,
        token: await getToken(),
        listingId: parseInt(listingId),
        groupId: parseInt(groupId),
        specsToDelete: specsToDelete,
        filesToDelete: attachmentUrlsToDelete,
        files: state.files,
      });
    },
    onError: (error) => {
      toastStringError(error.message);
      setIsSubmitting(false);
    },
    onSuccess: () => {
      toastEdited();
      setIsSubmitting(false);
      navigateBack();
    },
  });

  const handleOnMoveToClosed = () => {
    stopPublishingMutation.mutate();
  };

  const handleAttachmentToDelete = ({
    url,
    indexOfNew,
  }: {
    url: string;
    indexOfNew?: number;
  }) => {
    if (indexOfNew !== undefined && indexOfNew !== null) {
      return removeFileByIndex(indexOfNew);
    }
    setAttachmentUrlsToDelete((prev) => {
      if (prev.includes(url)) {
        return prev.filter((item) => item !== url);
      }
      return [...prev, url];
    });
  };

  const handleDeleteSpecifications = (idToDelete: number) => {
    setDeletedSpecificationIds([...specsToDelete, idToDelete]);
  }

  const handleOnDraft = ({ formData }: { formData: TListingEditForm }) => {
    validateYupSchema(formData, draftSchema)
      .then((values) => {
        listingDraftMutation.mutate({
          formData: {
            title: values.title || "",
            category: values.category || ("" as EListingCategory),
            Specifications: values.Specifications || [] as TSpecification[],
            country: values.country || ("" as ECountry),
            deadline: values.deadline,
            is_hidden: !!values.is_hidden,
            budget: (values?.budget as number) > 0 ? values.budget : 0,
            requiredOfferType: values?.requiredOfferType || (EListingRequiredOfferType.Both),
          },
        });
      })
      .catch(() => {});
  };

  const removeFileByIndex = (index: number) => {
    const tempState = state.files.filter((_file, _index) => _index !== index);
    setFiles(tempState);
  };

  const handlerFilesOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const tempState: TListingEditFormState["files"] = [...state.files];
    const isTooManyNewAttachments =
      e.target.files!.length + state.files.length > MAX_ATTACHMENTS;
    if (isTooManyNewAttachments) {
      toastMaxAttachments();
    } else {
      let isInvalidSize = false;
      for (let i = 0, len = e.target.files!.length; i < len; i++) {
        if (e.target.files![i].size < MAX_ATTACHMENT_SIZE) {
          tempState.push(e.target.files![i]);
        } else {
          isInvalidSize = true;
          toastMaxAttachmentSize();
          break;
        }
      }
      if (!isInvalidSize) {
        setFiles([...tempState]);
      }
    }
  };

  const handleOnClickEdit = () => {
    setState((state) => ({
      ...state,
      isEditingMode: !state.isEditingMode,
    }));
  };

  useEffect(() => {
    if (data) {
      setState((state) => ({
        ...state,
        isRestrictedBudget: !!data.budget,
        isWithDeadline: !!data.deadline,
      }));
      setDeletedSpecificationIds([]);
    }
  }, [data]);

  return (
    <div className="max-w-[1000px]" ref={pageRef}>
      <Formik<TListingEditForm>
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (formData, { validateForm }) => {
          const validateAndMutate = () => {
            validateForm(formData);
            listingMutation.mutate({ formData });
          };
          if (flagTriggerNoAttachmentsConfirmation) {
            modalPublishWithoutAttachments({ onConfirm: validateAndMutate });
          } else {
            validateAndMutate();
          }
        }}
        validateOnChange={state.hasSubmitted || state.hasSubmittedDraft}
        validateOnMount={true}
        validateOnBlur={true}
        validationSchema={publishSchema}
      >
        {({
          values,
          handleChange,
          errors,
          handleSubmit,
          validateForm,
          setFieldValue,
          resetForm,
          dirty,
        }) => {
          // Tab error flags
          const isInfoTabErrorPublish =
            state.hasSubmitted &&
            (errors.title ||
              errors.category ||
              errors.Specifications ||
              errors.country ||
              errors.deadline);
          const isInfoTabErrorDraft =
            state.hasSubmittedDraft &&
            (errors.title ||
              errors.category ||
              (errors.Specifications && values.Specifications) ||
              (errors.country && values.category) ||
              (errors.deadline && values.deadline));
          const isInfoTabError = isInfoTabErrorPublish || isInfoTabErrorDraft;

          const filesMaxSizeError =
            state.files.reduce((sum, file) => sum + file.size, 0) +
              (data?.ListingAttachment.reduce(
                (sum, file) => sum + file.size,
                0
              ) || 0) >
            MAX_ATTACHMENT_SIZE;

          const filesMaxCountError =
            state.files.length + (data?.ListingAttachment?.length || 0) >
            MAX_ATTACHMENTS;

          const filesTabError = filesMaxCountError || filesMaxSizeError;

          const handleOnClickDraft = () => {
            if (state.isSubmitting || isLoading) return;
            if (filesMaxCountError) {
              toastMaxAttachments();
              return;
            }
            if (filesMaxSizeError) {
              toastMaxAttachmentSize();
              return;
            }
            setHasSubmittedDraft(true);
            validateForm(values);
            handleOnDraft({ formData: values });
          };

          const handleOnClickPublish = () => {
            if (state.isSubmitting || isLoading) return;
            if (filesMaxCountError) {
              toastMaxAttachments();
              return;
            }
            if (filesMaxSizeError) {
              toastMaxAttachmentSize();
              return;
            }
            setHasSubmitted(true);
            handleSubmit();
          };

          const handleOnClickCancelEditIfClean = () => {
            if (!dirty) {
              setState((state) => ({
                ...state,
                isEditingMode: false,
                files: [],
              }));
              setAttachmentUrlsToDelete([]);
              setDeletedSpecificationIds([]);
              return;
            }
            modalEditCancelConfirmation({
              onConfirm: () => {
                setState((state) => ({
                  ...state,
                  isEditingMode: false,
                }));
                resetForm();
              },
            });
          };

          const navigateBackIfClean = () => {
            if (!dirty) return navigate(-1);

            modalEditCancelConfirmation({
              onConfirm: () => {
                resetForm();
                navigateBack();
              },
            });
          };

          return (
            <>
              <h3>
                {t("PageListingCreateEdit.listingId")}: #{listingId}
              </h3>
              <div className="pt-4 pb-2">
                <StepInProcess status={data?.status} />
              </div>
              <CTabs activeItemKey={"info"}>
                <CTabList variant="tabs">
                  <CTab itemKey="info">
                    <span className={`${isInfoTabError ? "text-red-500" : ""}`}>
                      <CIcon icon={cilInfo} /> {t("PageListingCreateEdit.info")}
                    </span>
                  </CTab>
                  <CTab itemKey="attachments">
                    <span className={`${filesTabError ? "text-red-500" : ""}`}>
                      <CIcon icon={cilFile} />{" "}
                      {t("PageListingCreateEdit.attachments")}
                    </span>
                  </CTab>
                  {state.isEditingMode ? (
                    <CTooltip
                      content={t("CommonTooltips.disabledWhileEditing")}
                    >
                      <div>
                      <CTab
      itemKey="enrollments"
      // @ts-ignore
      disabled={state.isEditingMode}
    >
      <div className="relative inline-flex items-center">
        <CIcon icon={cilUser} />
        <span className="ml-2">{t("PageListingCreateEdit.enrollments")}</span>

        {newOffersCount > 0 && (
          <span className="absolute -top-4 -right-12 bg-blue-500 text-white text-xs font-semibold py-1 px-2 rounded-full shadow-lg animate-bounce">
          {newOffersCount} {newOffersCount > 1 ? t("PageListingCreateEdit.new_enrollments") : t("PageListingCreateEdit.new_enrollment")}!
        </span>
        )}
      </div>
    </CTab>
                      </div>
                    </CTooltip>
                  ) : (
                    <CTab itemKey="enrollments">
 <div className="relative inline-flex items-center">
        <CIcon icon={cilUser} />
        <span className="ml-2">{t("PageListingCreateEdit.enrollments")}</span>

        {newOffersCount > 0 && (
          <span className="absolute -top-4 -right-12 bg-blue-500 text-white text-xs font-semibold py-1 px-2 rounded-full shadow-lg animate-bounce">
            {newOffersCount} {newOffersCount > 1 ? t("PageListingCreateEdit.new_enrollments") : t("PageListingCreateEdit.new_enrollment")}!
          </span>
        )}
      </div>
                    </CTab>
                  )}
                </CTabList>
                <CTabContent>
                  <CTabPanel itemKey="info" id="info-tab-panel">
                    <Form>
                      <div className="flex flex-col pt-2">
                        <h5 className="px-2 pt-4 pb-0">
                          {t("PageListingCreateEdit.basicInformation")}
                        </h5>

                        <div className="p-3 border border-gray-200 rounded-lg">
                          <ListingViewCustomInputTitle
                            handleChange={handleChange}
                            value={values.title}
                            error={errors.title}
                            hasSubmittedEither={hasSubmittedEither}
                            isEditing={state.isEditingMode}
                            isLoading={isLoading}
                          />
                          <ListingViewCustomSelectCategory
                            value={values.category}
                            error={errors.category}
                            hasSubmittedEither={hasSubmittedEither}
                            setFieldValue={setFieldValue}
                            isLoading={isLoading}
                            isEditing={state.isEditingMode}
                          />
                          <ListingViewEditOfferTypeSelect
                            value={values.requiredOfferType}
                            error={errors.category}
                            hasSubmittedEither={hasSubmittedEither}
                            setFieldValue={setFieldValue}
                            isLoading={isLoading}
                            isEditing={state.isEditingMode}
                          />
                        </div>

                        <h5 className="px-2 pt-4 pb-0">
                          {t("PageListingCreateEdit.specifications")}
                        </h5>

                        <div className="p-3 border border-gray-200 rounded-lg">
                          <ListingViewSpecifications
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleDelete={handleDeleteSpecifications}
                            valueSpecifications={values.Specifications}
                            errors={errors}
                            hasSubmittedDraft={state.hasSubmittedDraft}
                            hasSubmittedPublish={state.hasSubmitted}
                            hasSubmittedEither={hasSubmittedEither}
                            isEditing={state.isEditingMode}
                            isLoading={isLoading}
                          />

                          <ListingViewDeadline
                            dataValue={data?.deadline}
                            value={values.deadline}
                            error={errors.deadline}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            setIsWithDeadline={setIsWithDeadline}
                            isWithDeadline={state.isWithDeadline}
                            hasSubmittedDraft={state.hasSubmittedDraft}
                            hasSubmittedPublish={state.hasSubmitted}
                            isEditing={state.isEditingMode}
                            isLoading={isLoading}
                          />

                          <ListingViewRestrictedBudget
                            switchOnChange={() => {
                              setState((state) => ({
                                ...state,
                                isRestrictedBudget: !state.isRestrictedBudget,
                              }));
                            }}
                            dataValue={data?.budget}
                            value={values.budget}
                            error={errors.budget}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            isEditing={state.isEditingMode}
                            isLoading={isLoading}
                            isRestrictedBudget={state.isRestrictedBudget}
                            hasSubmittedEither={hasSubmittedEither}
                          />
                        </div>

                        <div>
                          <h5 className="px-2 pt-4 pb-0">
                            {t("PageListingCreateEdit.location")}
                          </h5>
                          <ListingViewLocation
                            valueCountry={values.country}
                            setFieldValue={setFieldValue}
                            hasSubmittedPublish={state.hasSubmitted}
                            hasSubmittedDraft={state.hasSubmittedDraft}
                            isEditing={state.isEditingMode}
                            isLoading={isLoading}
                            errorCountry={errors.country}
                          />
                        </div>

                        <div>
                          <h5 className="px-2 pt-4 pb-0">
                            {t("PageListingCreateEdit.settings")}
                          </h5>
                          <ListingViewSettings
                            valueHidden={values.is_hidden}
                            onHiddenChange={() => {
                              setFieldValue("is_hidden", !values.is_hidden);
                            }}
                            hasSubmittedEither={hasSubmittedEither}
                            isLoading={isLoading}
                            isEditing={state.isEditingMode}
                          />
                        </div>
                      </div>
                    </Form>
                  </CTabPanel>
                  <CTabPanel itemKey="attachments" id="attachments-tab-panel">
                    <div className="flex flex-col gap-3 pt-6">
                      <ListingViewAttachments
                        files={state.files}
                        isEditingMode={state.isEditingMode}
                        attachmentUrlsToDelete={attachmentUrlsToDelete}
                        handleAttachmentToDelete={handleAttachmentToDelete}
                        pageRef={pageRef}
                        attachmentsFromServer={data?.ListingAttachment}
                        handlerFilesOnChange={handlerFilesOnChange}
                      />
                    </div>
                  </CTabPanel>
                  <CTabPanel itemKey="enrollments" id="enrollments-tab-panel">
                    <div className="flex flex-col pt-2">
                      <h5 className="px-2 pt-4 pb-0 ">
                        {t("PageListingCreateEdit.enrollments")}
                      </h5>
                      <ListingViewEnrollmentsTable 
                        onNewOffersCount={(count) => setNewOffersCount(count)}
                      />
                    </div>
                  </CTabPanel>
                </CTabContent>
              </CTabs>

              <ListingViewButtons
                isEditing={state.isEditingMode}
                isLoading={isLoading}
                onSubmitPublish={handleOnClickPublish}
                onSubmitDraft={handleOnClickDraft}
                onClickBack={navigateBackIfClean}
                onClickEdit={handleOnClickEdit}
                onClickCancelEditing={handleOnClickCancelEditIfClean}
                onClickMoveToClosed={handleOnMoveToClosed}
                isSubmitting={state.isSubmitting}
                listingStatus={data?.status}
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

type TListingEditFormState = {
  files: File[];
  isRestrictedBudget: boolean;
  isWithDeadline: boolean;
  hasSubmitted: boolean;
  hasSubmittedDraft: boolean;
  isSubmitting: boolean;
  isEditingMode: boolean;
};

export type TListingEditForm = {
  title: string;
  category?: EListingCategory;
  Specifications?: TSpecification[];
  country?: ECountry;
  budget?: number;
  images?: string[];
  deadline?: string;
  setupId?: number;
  locationId?: number;
  is_hidden?: boolean;
  requiredOfferType: EListingRequiredOfferType;
};

export default ListingViewEdit;
