// GroupGrid.tsx
import React from "react";
import { Group, TQuery } from "./types";
import { GroupTile } from "./group-tile";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { useNavigate } from "react-router-dom";
import { CreateGroupTile } from "./create-group-tile";

interface GroupGridProps {
  data: TQuery | undefined;
  isLoading: boolean;
  onDelete: (group: Group) => void;
}

export const GroupGrid: React.FC<GroupGridProps> = ({
  data,
  isLoading,
  onDelete,
}) => {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-1 gap-6 p-6 groups-grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
      {isLoading ? (
        [...Array(8)].map((_, index) => (
          <div
            className="p-4 bg-white rounded-lg shadow group-tile-skeleton"
            key={index}
          >
            {/* Skeleton content */}
            <DefaultSkeleton />
          </div>
        ))
      ) : (
        <>
          <CreateGroupTile />
          {data?.groups?.map?.((group) => (
            <GroupTile
              key={group.id}
              group={group}
              onView={(id) => navigate(`/listing-group/${id}`)}
              onDelete={onDelete}
            />
          ))}
        </>
      )}
    </div>
  );
};
