import { useEffect, useState } from "react";

type TUsePageOutOfBoundsProps = {
  page: number;
  setPage: (page: number) => void;
  pageCount: number;
  isLoading: boolean;
  refetch: () => void;
};

export const usePageOutOfBounds = (props: TUsePageOutOfBoundsProps) => {
  const { page, setPage, pageCount, isLoading, refetch } = props;

  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    if ((page < 0 || page > pageCount) && !isLoading) {
      setPage(1);
    }
    if (firstLoad) {
      setFirstLoad(false); // needed for reliable ref mount
    } else {
      refetch();
    }
  }, [page, pageCount, firstLoad, isLoading]);
};
