import { cilArrowCircleBottom, cilArrowCircleTop } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

type TSortArrowProps = {
  orderDir?: string;
  orderBy?: string;
  columnName: string;
  onClick: (type: string, orderDir: "ASC" | "DESC") => void;
};

export const SortArrow = (props: TSortArrowProps) => {
  const { orderBy, orderDir, columnName, onClick } = props;

  return (
    <CIcon
      icon={
        orderBy === columnName && orderDir === "DESC"
          ? cilArrowCircleBottom
          : cilArrowCircleTop
      }
      style={
        orderBy === columnName
          ? ({
              "--ci-primary-color": "blue",
            } as any)
          : {}
      }
      className="cursor-pointer"
      onClick={() => {
        onClick(
          columnName,
          orderBy === columnName && orderDir === "DESC" ? "ASC" : "DESC"
        );
      }}
    />
  );
};
