import { EListingCategory } from "@/enums/listing-enums";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { CBadge } from "@coreui/react";

type TCategoryBadgeProps = {
  category?: EListingCategory;
};

export const CategoryBadge = (props: TCategoryBadgeProps) => {
  const { category = "" } = props;
  const { t } = useAppTranslation();

  let text: string = category;

  switch (category) {
    case EListingCategory.CNC:
      text = t("Enums.ListingCategory.cnc");
      break;
    case EListingCategory.WOOD_MACHINERY:
      text = t("Enums.ListingCategory.woodMachinery");
      break;
    case EListingCategory.WOOD_SCANNERS:
      text = t("Enums.ListingCategory.woodScanners");
      break;
    case EListingCategory.FURNITURE_MACHINERY:
      text = t("Enums.ListingCategory.furnitureMachinery");
      break;
    case EListingCategory.METAL_MACHINERY:
      text = t("Enums.ListingCategory.metalMachinery");
      break;
    case EListingCategory.SOFTWARE:
      text = t("Enums.ListingCategory.software");
      break;
    case EListingCategory.OTHER_EQUIPMENT:
      text = t("Enums.ListingCategory.otherEquipment");
      break;
    default:
      break;
  }

  return <CBadge color="secondary">{text}</CBadge>;
};
