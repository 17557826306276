import { authedFetch } from "@/utils/authed-fetch";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";

type TOfferReject = {
  onSuccess: () => void;
  onError: () => void;
};

export const useOfferReject = ({ onSuccess, onError }: TOfferReject) => {
  const { getToken } = useAuth();
  return useMutation({
    mutationFn: async ({
      enrollmentId,
      offerId,
    }: {
      enrollmentId: number;
      offerId: number;
    }) => {
      return await authedFetch({
        endpoint: `api/provider/enrollment/${enrollmentId}/offer/${offerId}`,
        method: "DELETE",
        token: await getToken(),
      }).then((res) => {
        if (res.status >= 400) {
          onError();
        } else {
          onSuccess();
        }
        return res;
      });
    },
  });
};
