import { setDefaultGroupId } from "@/store/group/slice";
import { useAppDispatch } from "@/store/hooks";
import { authedFetch } from "@/utils/authed-fetch";
import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";

type TUseDefaultUserGroup = {
  enabled: boolean;
};

export const useDefaultUserGroup = (props: TUseDefaultUserGroup) => {
  const { enabled } = props;
  const { userId, getToken, isSignedIn } = useAuth();
  const dispatch = useAppDispatch();

  useQuery({
    queryKey: ["defaultUserGroup", userId],
    queryFn: async () => {
      if (!isSignedIn) return null;
      console.log("isSignedIn", isSignedIn);
      const checkRes = await authedFetch({
        endpoint: "api/office/listing-group/default",
        token: await getToken(),
      }).then((res) => res.json());
      if (
        checkRes?.defaultGroupId !== undefined &&
        checkRes?.defaultGroupId !== null
      ) {
        dispatch(setDefaultGroupId(checkRes.defaultGroupId));
      }

      return null;
    },
    enabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
