import { createRoot } from "react-dom/client";
import "core-js";
import "./index.css";

import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import App from "./app";
import { CustomClerkProvider } from "./auth/custom-clerk-provider";
import { ReduxProvider } from "./store/redux-provider";
import EN from "./locales/en/translation.json";
import LT from "./locales/lt/translation.json";
import RU from "./locales/ru/translation.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: EN },
    lt: { translation: LT },
    ru: { translation: RU },
  },
  lng: "en",
  fallbackLng: false,
  compatibilityJSON: "v3",
  interpolation: { escapeValue: false },
});

createRoot(document.getElementById("root")!).render(
  <CustomClerkProvider>
    <ReduxProvider>
      <App />
    </ReduxProvider>
  </CustomClerkProvider>
);
