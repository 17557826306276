import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { useAppDispatch } from "@/store/hooks";
import {
  setModalConfirmation,
  TModalConfirmationState,
} from "@/store/modal/slice";

export const useModalsListingViewEdit = () => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();

  const dispatchConfirmationModalAction = ({
    title,
    message,
    buttons,
  }: {
    title: TModalConfirmationState["title"];
    message: TModalConfirmationState["message"];
    buttons: TModalConfirmationState["buttons"];
  }) => {
    dispatch(
      setModalConfirmation({
        isOpen: true,
        title,
        message,
        buttons,
      })
    );
  };

  const modalPublishWithoutAttachments = ({
    onConfirm,
  }: {
    onConfirm: () => void;
  }) =>
    dispatchConfirmationModalAction({
      title: t("Modals.Listing.PublishingWithoutAttachments.title"),
      message: t("Modals.Listing.PublishingWithoutAttachments.message"),
      buttons: {
        cancel: {
          label: t("CommonTables.cancel"),
          variant: "outline",
        },
        confirm: {
          label: t("PageListingCreateEdit.Buttons.publish"),
          color: "info",
          onClick: onConfirm,
        },
      },
    });

  const modalEditCancelConfirmation = ({
    onConfirm,
  }: {
    onConfirm: () => void;
  }) =>
    dispatchConfirmationModalAction({
      title: t("Modals.LeaveWithUnsavedChanges.title"),
      message: t("Modals.LeaveWithUnsavedChanges.message"),
      buttons: {
        cancel: {
          label: t("CommonTables.cancel"),
          variant: "outline",
        },
        confirm: {
          label: t("CommonTables.leave"),
          color: "danger",
          onClick: onConfirm,
        },
      },
    });

  return {
    modalPublishWithoutAttachments,
    modalEditCancelConfirmation,
  };
};
