import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";

export const PageMyFavorites = () => {
  useBreadcrumbs("MY_FAVORITES");

  return (
    <div>
      Here will be my starred listings
    </div>
  );
};
