import { TListingGroupCreateForm } from "./create-group";
import * as yup from "yup";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";

export const listingGroupCreateSchema = () => {
  const { t } = useAppTranslation();

  return yup.object<TListingGroupCreateForm>().shape({
    name: yup
      .string()
      .required(t("FormValidation.required"))
      .max(255, t("FormValidation.maxChars", { count: 255 })),
  });
};
