import { cilCalendar } from "@coreui/icons";

import { StringSkeleton } from "@/components/loaders/default-skeleton";
import { TDiscoverShowQuery } from "../page-discover-show";
import { CategoryBadge } from "@/components/badges/category-badge";
import { EListingCategory } from "@/enums/listing-enums";
import CIcon from "@coreui/icons-react";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { CBadge } from "@coreui/react";

type TDiscoverShowHeaderProps = {
  isLoading: boolean;
  isMyOwn: boolean;
  data?: TDiscoverShowQuery;
};

export const DiscoverShowHeader = ({
  isLoading,
  isMyOwn,
  data,
}: TDiscoverShowHeaderProps) => {
  const { t } = useAppTranslation();

  return (
    <div className="flex items-start justify-between">
      <div>
        <h3 className="mb-0">{isLoading ? (<StringSkeleton />
        ) : 
          <>
          {t("PageDiscoverShow.title")}:{" "}{data?.title}
          </>
        }
          </h3>
        <span className="flex items-center gap-2 text-slate-500">
          <span className="flex">
            {t("PageDiscoverShow.listingId")}:{" "}
            {isLoading ? (
              <StringSkeleton className="w-10 ml-3" />
            ) : (
              `#${data?.id}`
            )}
          </span>
        </span>
        <div className="flex h-6">
          {isLoading ? (
            <StringSkeleton className="w-20" />
          ) : (
            <div>
              <CategoryBadge category={data?.category as EListingCategory} />
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        {data?.deadline && (
          <>
            <CIcon icon={cilCalendar} />
            <div className="flex gap-1">
              <span>{t("PageDiscoverShow.availableUntil")}:</span>
              {isLoading ? (
                <StringSkeleton className="w-[95px]" />
              ) : (
                <span className="font-bold">{data?.deadline}</span>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
