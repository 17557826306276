import { CustomButton } from "@/components/forms/custom/custom-button/custom-button";
import { EListingStatus } from "@/enums/listing-enums";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { CSpinner } from '@coreui/react';
import {
  cilArrowThickLeft,
  cilArrowThickRight,
  cilBan,
  cilCheckCircle,
  cilFile,
  cilPen,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CTooltip } from "@coreui/react";

type TListingViewButtonsProps = {
  listingStatus?: EListingStatus;
  onSubmitPublish: () => void;
  onSubmitDraft: () => void;
  onClickEdit: () => void;
  onClickBack: () => void;
  onClickCancelEditing: () => void;
  onClickMoveToClosed: () => void;
  isSubmitting: boolean;
  isLoading: boolean;
  isEditing: boolean;
};

export const ListingViewButtons = (props: TListingViewButtonsProps) => {
  const {
    listingStatus,
    onSubmitPublish,
    onSubmitDraft,
    onClickEdit,
    onClickBack,
    onClickCancelEditing,
    onClickMoveToClosed,
    isSubmitting,
    isLoading,
    isEditing,
  } = props;
  const { t } = useAppTranslation();

  if (isEditing) {
    return (
      <div className="flex justify-end gap-2 pt-5">
        <CustomButton
          type="button"
          color="dark"
          variant="outline"
          className="flex-1 sm:flex-initial"
          disabled={isSubmitting || isLoading}
          onClick={onClickCancelEditing}
        >
          <span className="flex items-center justify-center gap-1 min-w-16">
            <CIcon icon={cilBan} />
            <span>{t("CommonTables.cancel")}</span>
          </span>
        </CustomButton>
        {listingStatus === EListingStatus.DRAFT && !isLoading && (
          <CustomButton
            type="button"
            disabled={isSubmitting || isLoading}
            onClick={onSubmitDraft}
            color="info"
            variant="outline"
            className="flex-1 sm:flex-initial"
          >
            <span className="flex items-center justify-center gap-1 min-w-16">
              <CIcon icon={cilFile} />
              <span>{t("PageListingCreateEdit.Buttons.saveAsDraft")}</span>
            </span>
          </CustomButton>
        )}
        <CustomButton
          type="button"
          color="info"
          className="items-center flex-1 text-white sm:flex-initial"
          disabled={isSubmitting || isLoading}
          onClick={onSubmitPublish}
        >
          <span className="flex items-center justify-center gap-1 min-w-16">
          {isSubmitting && <CSpinner as="span" size="sm" aria-hidden="true" />}
            {!isSubmitting && <CIcon icon={cilCheckCircle} />}
            <span>{t("PageListingCreateEdit.Buttons.publish")}</span>
          </span>
        </CustomButton>
      </div>
    );
  }

  return (
    <div className="flex justify-end gap-2 pt-5">
      <CustomButton
        type="button"
        color="dark"
        variant="outline"
        className="flex-1 sm:flex-initial"
        disabled={isSubmitting || isLoading}
        onClick={onClickBack}
      >
        <span className="flex items-center justify-center gap-1 min-w-16">
          <CIcon icon={cilArrowThickLeft} />
          <span>{t("CommonTables.back")}</span>
        </span>
      </CustomButton>
      {listingStatus === EListingStatus.CLOSED ? (
        <CTooltip content={t("CommonTooltips.thisListingIsClosed")}>
          <CButton
            type="button"
            color="info"
            className="flex-1 text-white sm:flex-initial"
            disabled
            onClick={() => {}}
          >
            <span className="flex items-center justify-center gap-1 min-w-16">
              <CIcon icon={cilPen} />
              <span>{t("CommonTables.edit")}</span>
            </span>
          </CButton>
        </CTooltip>
      ) : (
        <CustomButton
          type="button"
          color="info"
          className="flex-1 text-white sm:flex-initial"
          disabled={isSubmitting || isLoading}
          onClick={onClickEdit}
        >
          <span className="flex items-center justify-center gap-1 min-w-16">
            <CIcon icon={cilPen} />
            <span>{t("CommonTables.edit")}</span>
          </span>
        </CustomButton>
      )}

      {listingStatus === EListingStatus.OPEN && (
        <CustomButton
          type="button"
          color="info"
          className="text-white"
          onClick={onClickMoveToClosed}
        >
          <span className="flex items-center justify-center gap-1 min-w-16">
            <CIcon icon={cilArrowThickRight} />
            <span>{t("PageListingCreateEdit.Buttons.closeListing")}</span>
          </span>
        </CustomButton>
      )}
    </div>
  );
};
