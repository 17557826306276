import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { MainToaster } from "./components/toasts/main-toaster";
import { ReactQueryProvider } from "./auth/react-query-provider";
import { defaultRoutes } from "./router/routes";
import "./scss/style.scss";
import { ModalContainer } from "./components/modals/modal-container";

const router = createBrowserRouter(defaultRoutes);

const App = () => {

  return (
    <ReactQueryProvider>
      <RouterProvider router={router} />
      <MainToaster />
      <ModalContainer />
    </ReactQueryProvider>
  );
};

export default App;
