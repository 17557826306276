import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import { RequiredAsterist } from "@/components/forms/custom/required-asterisk/required-asterisk";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { TGroupNameQuery, TListingCreateForm } from "../listing-create";
import { FormikErrors } from "formik";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { CategorySelect } from "@/components/forms/custom/custom-select/category-select";
import { CFormSelect, CFormCheck} from '@coreui/react'; // CoreUI dropdown component
import { EListingRequiredOfferType } from "@/enums/listing-enums";

type TListingCreateBasicInformationProps = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: TListingCreateForm;
  errors: FormikErrors<TListingCreateForm>;
  groupId: number;
  dataGroupName?: TGroupNameQuery;
  dataGroups?: { id: number; name: string }[]; 
  onGroupIdChanged: (groupId: string) => void;
  isLoadingGroupName: boolean;
  isLoadingGroups: boolean,
  hasSubmittedDraft: boolean;
  hasSubmittedPublish: boolean;
  isGroupSelectionRestricted: boolean;
};

export const ListingCreateBasicInformation = ({
  handleChange,
  setFieldValue,
  onGroupIdChanged,
  values,
  isLoadingGroupName,
  isLoadingGroups,
  dataGroupName,
  dataGroups,
  errors,
  hasSubmittedDraft,
  hasSubmittedPublish,
  isGroupSelectionRestricted,
}: TListingCreateBasicInformationProps) => {
  const hasSubmittedEither = hasSubmittedDraft || hasSubmittedPublish;

  const isLoading = isLoadingGroupName || isLoadingGroups;
  const showSingleGroupInput = isGroupSelectionRestricted || (dataGroups && dataGroups?.groups.length === 1);
  if (dataGroups && dataGroups.groups?.length === 1) {
    onGroupIdChanged(dataGroups?.groups[0].id);
  }
  console.log("dataGroups:", dataGroups);
  const groupName: string = isGroupSelectionRestricted ? dataGroupName?.name : dataGroups?.groups[0].name;

  const { t } = useAppTranslation();

  return (
    <>
      <h5 className="px-2 pt-4 pb-0">
        {t("PageListingCreateEdit.basicInformation")}
      </h5>
      <div className="p-3 border border-gray-200 rounded-lg">
        {isLoading ? (
          <>
            <label className="form-label">
              {t("PageListingCreateEdit.BasicInformation.group")}
            </label>
            <DefaultSkeleton
              className="h-[38px] mb-4 w-full max-w-full"
              classNameInner="h-10 mt-0 w-full rounded-lg"
            />
          </>
        ) : showSingleGroupInput ? (
          <CustomInput
            className="mb-4"
            id="group"
            label={t("PageListingCreateEdit.BasicInformation.group")}
            type="text"
            value={`${groupName || ""}`}
            disabled
            divWrap
          />
        ) : (
          // Show the dropdown only if the groups size is greater than 1
          <div className="mb-4">
            <label htmlFor="group" className="form-label">
              {t("PageListingCreateEdit.BasicInformation.group")}<RequiredAsterist />
              <p className="ml-2 text-sm text-gray-500 italic" role="tooltip">
      {t("PageListingCreateEdit.BasicInformation.selectGroup")}
    </p>
            </label>
            <CFormSelect
              id="group"
              valid={!errors.group_id && hasSubmittedEither}
              invalid={!!errors.group_id && hasSubmittedEither}
              feedbackInvalid={errors.group_id}
              onChange={(e) => {
                onGroupIdChanged(e.target.value);
              }}
            >
              {dataGroups && dataGroups?.groups.map((group) => (
                <option key={group.id} value={group.id.toString()}>
                  {group.name}
                </option>
              ))}
            </CFormSelect>
          </div>
        )}
        <CustomInput
          className=""
          id="title"
          label={
            <>
              {t("PageListingCreateEdit.BasicInformation.title")}
              <RequiredAsterist />
            </>
          }
          type="text"
          value={values.title}
          onChange={handleChange}
          valid={!errors.title && hasSubmittedEither}
          invalid={!!errors.title && hasSubmittedEither}
          feedbackInvalid={errors.title}
          divWrap
          divWrapProps={{
            className: "min-h-[95px]",
          }}
        />

        <div className="inline-block">
        <label className="form-label">
            {t("PageListingCreateEdit.BasicInformation.selectCategory")}
            <RequiredAsterist />
          </label>
          <CategorySelect
            value={values.category}
            onChange={(selectedCategory) => {
              setFieldValue("category", selectedCategory);
            }}
            valid={!errors.category && hasSubmittedEither}
            invalid={!!errors.category && hasSubmittedEither}
          />
        </div>
        <div className="mt-6 mb-4">
          <label className="form-label">
            {t("PageListingCreateEdit.BasicInformation.listingRequiredOfferType")}
            <RequiredAsterist />
          </label>
          <div>
            <CFormCheck
              type="radio"
              name="equipmentOfferType"
              id="equipmentOfferTypeBoth"
              value={EListingRequiredOfferType.Both}
              label={t("Enums.ListingRequiredOfferType.both")}
              checked={values.requiredOfferType === EListingRequiredOfferType.Both}
              onChange={() => setFieldValue("requiredOfferType", EListingRequiredOfferType.Both)}
            />
            <CFormCheck
              type="radio"
              name="equipmentOfferType"
              id="equipmentOfferTypeNewOnly"
              value={EListingRequiredOfferType.NewOnly}
              label={t("Enums.ListingRequiredOfferType.newOnly")}
              checked={values.requiredOfferType === EListingRequiredOfferType.NewOnly}
              onChange={() => setFieldValue("requiredOfferType", EListingRequiredOfferType.NewOnly)}
            />
            <CFormCheck
              type="radio"
              name="equipmentOfferType"
              id="equipmentOfferTypeUsedOnly"
              value={EListingRequiredOfferType.UsedOnly}
              label={t("Enums.ListingRequiredOfferType.usedOnly")}
              checked={values.requiredOfferType === EListingRequiredOfferType.UsedOnly}
              onChange={() => setFieldValue("requiredOfferType", EListingRequiredOfferType.UsedOnly)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
