import { Fragment } from "react/jsx-runtime";
import { ConfirmationModal } from "./confirmation-modal";

const modalsArray = [<ConfirmationModal />];

export const ModalContainer = () => {
  return (
    <>
      {modalsArray.map((modal, _index) => {
        return <Fragment key={_index}>{modal}</Fragment>;
      })}
    </>
  );
};
