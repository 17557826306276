import { TListingEditForm } from "../listing-view-edit";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { CategoryBadge } from "@/components/badges/category-badge";
import { EListingCategory } from "@/enums/listing-enums";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { CategorySelect } from "@/components/forms/custom/custom-select/category-select";
import { RequiredAsterist } from "@/components/forms/custom/required-asterisk/required-asterisk";

type TListingViewCustomSelectCategoryProps = {
  value: TListingEditForm["category"];
  error?: string;
  hasSubmittedEither: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  isLoading?: boolean;
  isEditing: boolean;
};

export const ListingViewCustomSelectCategory = (
  props: TListingViewCustomSelectCategoryProps
) => {
  const {
    value,
    error,
    hasSubmittedEither,
    setFieldValue,
    isLoading,
    isEditing,
  } = props;
  const { t } = useAppTranslation();

  const category = t("PageListingCreateEdit.BasicInformation.category");
  const selectCategory = t("PageListingCreateEdit.BasicInformation.selectCategory");

  if (isLoading) {
    return (
      <div className="mb-4">
        <label className="form-label">{category}</label>
        <DefaultSkeleton
          className="px-[0px] py-[0px] max-w-full"
          classNameInner="h-[30px]"
        />
      </div>
    );
  }

  if (!isEditing) {
    return (
      <div className="mb-4 ">
        <label className="form-label">{category}</label>

        <p className="px-2 pt-[6px] pb-[4px] h-[38px] text-xl">
          <CategoryBadge category={value as EListingCategory} />
        </p>
      </div>
    );
  }

  return (
      <div className="inline-block">
        <label className="form-label">{selectCategory}</label><RequiredAsterist />
        <CategorySelect
          value={value}
          onChange={(selectedCategory) => {
            setFieldValue("category", selectedCategory);
          }}
          valid={!error && hasSubmittedEither}
          invalid={!!error && hasSubmittedEither}
          feedbackInvalid={error}
        />
      </div>
  );
};
