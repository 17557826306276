import { EOfferStatus } from "@/enums/offer-enums";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { CBadge } from "@coreui/react";

type TOfferStatusBadgeProps = {
  status: EOfferStatus;
};

export const OfferStatusBadge = (props: TOfferStatusBadgeProps) => {
  const { status } = props;
  const { t } = useAppTranslation();

  let text: string = status;
  let bgColor = "";

  switch (status) {
    case EOfferStatus.SUBMITTED:
      text = t("Enums.OfferStatus.SUBMITTED");
      bgColor = "bg-blue-500";
      break;
    case EOfferStatus.CLIENT_REJECTED:
      text = t("Enums.OfferStatus.CLIENT_REJECTED");
      bgColor = "bg-red-500";
      break;
    case EOfferStatus.CLIENT_ACCEPTED:
      text = t("Enums.OfferStatus.CLIENT_ACCEPTED");
      bgColor = "bg-green-500";
      break;
    case EOfferStatus.PROVIDER_REJECTED:
      text = t("Enums.OfferStatus.PROVIDER_REJECTED");
      bgColor = "bg-red-500";
      break;
    default:
      break;
  }

  const className = `${bgColor}`;

  return <CBadge className={className}>{text}</CBadge>;
};
