import { ToastMap } from "@/components/toasts/toast-map";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TToastState = {
  mainToast?: (typeof ToastMap)[number]["name"];
  mainToastContent?: string;
  mainToastColor?: "success" | "danger" | "warning" | "info";
  mainToastDuration?: number;
};

const initialState: TToastState = {
  mainToast: undefined,
  mainToastContent: undefined,
  mainToastColor: undefined,
  mainToastDuration: undefined,
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    addToastMain: (
      state,
      action: PayloadAction<{
        mainToast: TToastState["mainToast"];
        mainToastContent?: TToastState["mainToastContent"];
        mainToastColor?: TToastState["mainToastColor"];
        mainToastDuration?: TToastState["mainToastDuration"];
      }>
    ) => {
      state.mainToast = action.payload.mainToast;
      state.mainToastContent = action.payload.mainToastContent;
      state.mainToastColor = action.payload.mainToastColor;
      state.mainToastDuration = action.payload.mainToastDuration;
    },
    clearToastMain: (state) => {
      state.mainToast = undefined;
      state.mainToastContent = undefined;
      state.mainToastColor = undefined;
    },
  },
});

export const { addToastMain, clearToastMain } = toastSlice.actions;
export default toastSlice.reducer;
