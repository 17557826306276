import { Provider } from "react-redux";
import { store } from "./store";

type TReduxProviderProps = {
  children: React.ReactNode;
}

export const ReduxProvider = (props: TReduxProviderProps) => {
  const { children } = props;

  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
}