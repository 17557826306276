import React, { useEffect, useRef, useState } from "react";

type TSlideAppearWrapperProps = {
  isLoaded: boolean;
  children?: React.ReactNode;
};

const TRANSITION_DURATION = 500;

export const SlideAppearWrapper: React.FC<TSlideAppearWrapperProps> = ({
  isLoaded,
  children,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<string>("0px");

  useEffect(() => {
    if (isLoaded) {
      // Get the height of the content
      const contentHeight = contentRef.current?.scrollHeight || 0;
      setHeight(`${contentHeight}px`);
      setTimeout(() => {
        setHeight("auto");
      }, TRANSITION_DURATION);
    } else {
      setHeight("0px");
    }
  }, [isLoaded]);

  return (
    <div
      ref={contentRef}
      style={{
        height,
        transition: `height ${TRANSITION_DURATION}ms ease`,
      }}
      className="overflow-hidden"
    >
      {children}
    </div>
  );
};
