import { cilX } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CFormInput } from "@coreui/react";

type TCustomInputProps = {
  divWrap?: boolean;
  divWrapProps?: React.ComponentProps<"div">;
  clearable?: boolean;
} & React.ComponentProps<typeof CFormInput>;

const CustomInput = (props: TCustomInputProps) => {
  const {
    divWrap,
    divWrapProps,
    className,
    disabled,
    clearable,
    ...propsRest
  } = props;
  if (divWrap) {
    return (
      <div className={`${divWrapProps?.className} relative`}>
        <CFormInput
          {...propsRest}
          className={`${className} ${disabled ? "cursor-not-allowed" : ""}`}
          disabled={disabled}
        />
        {clearable && propsRest.value && (
          <CIcon
            icon={cilX}
            className="absolute right-3 top-3 cursor-pointer"
            onClick={() => {
              if (propsRest.onChange) {
                propsRest.onChange({
                  target: { value: "" },
                } as React.ChangeEvent<HTMLInputElement>);
              }
            }}
          />
        )}
      </div>
    );
  }
  return <CFormInput {...propsRest} />;
};

export default CustomInput;
