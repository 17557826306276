import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilFlagAlt,
  cilFile,
  cilList,
  cilCalendar,
  cilBan,
  cilSettings,
  cilTask,
  cilUser,
} from "@coreui/icons";
import { Item } from "@coreui/react/dist/esm/components/table/types";
import React, { useState } from "react";
import { CategoryBadge } from "@/components/badges/category-badge";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { useNavigate } from "react-router-dom";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";

type TColumn = {
  key: string;
  label: React.ReactNode;
};

type TItem = {
  id: number;
  group_id: number;
  status: React.ReactNode;
  title: string;
  category: string;
  created_by: React.ReactNode;
  created_at: React.ReactNode;
  hidden: React.ReactNode;
  actions: React.ReactNode;
};

export type TListingsTableProps = {
  tbody: TItem[];
  count: number;
  isLoading: boolean;
};

export const ListingsTable = (props: TListingsTableProps) => {
  const { tbody, isLoading } = props;
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const [mouseOverIndex, setMouseOverIndex] = useState<number | null>(null);

  const columns: TColumn[] = [
    {
      key: "id",
      label: (
        <div className="flex items-center gap-1 flex-nowrap">
          <CIcon icon={cilTask} /> #
        </div>
      ),
    },
    {
      key: "group",
      label: (
        <div className="flex items-center gap-1 flex-nowrap">
          <CIcon icon={cilTask} /> {t("PageListings.ListingsTable.group")}
        </div>
      ),
    },
    {
      key: "status",
      label: (
        <div className="flex items-center gap-1 flex-nowrap">
          <CIcon icon={cilFlagAlt} /> {t("PageListings.ListingsTable.status")}
        </div>
      ),
    },
    {
      key: "title",
      label: (
        <div className="flex items-center gap-1 flex-nowrap">
          <CIcon icon={cilFile} /> {t("PageListings.ListingsTable.title")}
        </div>
      ),
    },
    {
      key: "category",
      label: (
        <div className="flex items-center gap-1 flex-nowrap">
          <CIcon icon={cilList} /> {t("PageListings.ListingsTable.category")}
        </div>
      ),
    },
    {
      key: "created_by",
      label: (
        <div className="flex items-center gap-1 flex-nowrap whitespace-nowrap">
          <CIcon icon={cilUser} /> {t("PageListings.ListingsTable.createdBy")}
        </div>
      ),
    },
    {
      key: "created_at",
      label: (
        <div className="flex items-center gap-1 flex-nowrap whitespace-nowrap">
          <CIcon icon={cilCalendar} />{" "}
          {t("PageListings.ListingsTable.createdAt")}
        </div>
      ),
    },
    {
      key: "hidden",
      label: (
        <div className="flex items-center gap-1 flex-nowrap">
          <CIcon icon={cilBan} /> {t("CommonTables.hidden")}
        </div>
      ),
    },
    {
      key: "actions",
      label: (
        <div className="flex items-center gap-1 flex-nowrap">
          <CIcon icon={cilSettings} /> {t("CommonTables.actions")}
        </div>
      ),
    },
  ] as const;

  const items: Item[] = tbody.map((item) => ({
    id: item.id,
    group_id: item.group_id,
    status: item.status,
    title: item.title,
    category: item.category,
    created_by: item.created_by,
    created_at: item.created_at,
    hidden: item.hidden,
    actions: item.actions,
  }));

  return (
    <>
      <CTable
        className={`bg-white shadow-lg rounded-lg transition-all`}
        style={{
          marginBottom: 0,
        }}
      >
        <CTableBody
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto auto auto auto 1fr auto auto auto",
          }}
          onMouseLeave={() => {
            setMouseOverIndex(() => null);
          }}
        >
          <CTableRow
            className="text-base font-extrabold tracking-wider text-gray-900 uppercase bg-gray-200"
            style={{
              display: "contents",
            }}
            onMouseOver={() => {
              setMouseOverIndex(() => null);
            }}
          >
            {columns.map((column) => (
              <CTableHeaderCell
                className="py-4 border-b-2 border-gray-400"
                scope="col"
                key={column.key}
              >
                {column.label}
              </CTableHeaderCell>
            ))}
          </CTableRow>
          {isLoading ? (
            <CTableRow>
              {columns.map((_) => (
                <CTableDataCell key={_.key}>
                  <DefaultSkeleton />
                </CTableDataCell>
              ))}
            </CTableRow>
          ) : (
            items.map((item, _index) => (
              <CTableRow
                className="text-gray-700 hover:bg-gray-100"
                key={item.id}
                style={{
                  display: "contents",
                }}
                onMouseOver={() => {
                  setMouseOverIndex(() => _index);
                }}
                color={_index === mouseOverIndex ? "info" : ""}
              >
                <CTableDataCell className="flex items-center">
                  {item?.id}
                </CTableDataCell>
                <CTableDataCell className="flex items-center">
                  <CButton
                    type="button"
                    onClick={() => {
                      navigate(`/listing-group/${item?.group_id}`);
                    }}
                  >
                    <span className="text-blue-500 underline">
                      {item?.group_id}
                    </span>
                  </CButton>
                </CTableDataCell>
                <CTableDataCell className="flex items-center">
                  {item?.status}
                </CTableDataCell>
                <CTableDataCell className="flex items-center">
                  {item?.title}
                </CTableDataCell>
                <CTableDataCell className="flex items-center">
                  <CategoryBadge category={item?.category} />
                </CTableDataCell>
                <CTableDataCell className="flex items-center">
                  {item?.created_by}
                </CTableDataCell>
                <CTableDataCell className="flex items-center">
                  {item?.created_at}
                </CTableDataCell>
                <CTableDataCell className="flex items-center">
                  {item?.hidden}
                </CTableDataCell>
                <CTableDataCell>{item?.actions}</CTableDataCell>
              </CTableRow>
            ))
          )}
        </CTableBody>
      </CTable>
      {!isLoading && props.count === 0 && (
        <div className="flex flex-col items-center justify-center h-64">
          <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg shadow-lg">
            <p className="text-xl font-bold text-gray-700">No Data Available</p>
            <p className="mt-2 text-center text-gray-500">
              There is currently no data to display. Create your first item!
            </p>
            <p className="mt-2 text-center text-gray-500">
              In case of issues, reach out to{" "}
              <a
                href="mailto:support@machinevertical.com"
                className="text-blue-500 underline"
              >
                support@machinevertical.com
              </a>
            </p>
          </div>
        </div>
      )}
    </>
  );
};
