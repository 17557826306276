import * as yup from "yup";
import { TListingCreateForm } from "./listing-create";
import { MAX_ATTACHMENTS } from "@/constants/form-constatns";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";

type TSchemaProps = {
  isRestrictedBudget: boolean;
  isWithDeadline: boolean;
};

export const listingCreateSchema = ({
  isRestrictedBudget,
  isWithDeadline,
}: TSchemaProps) => {
  const { t } = useAppTranslation();

  const required = t("FormValidation.required");
  const requiredPublish = t("FormValidation.requiredPublish");
  const max255 = t("FormValidation.maxChars", { count: 255 });
  const max100 = t("FormValidation.maxChars", { count: 100 });
  const min0 = t("FormValidation.min0");
  const mustBeLaterThanToday = t("FormValidation.mustBeLaterThanToday");

  const maxAttachments = t("FormValidation.maxAttachments", {
    count: MAX_ATTACHMENTS,
  });

  const specificationsItemSchema = yup.object().shape({
    value: yup.string().required("Specification value cannot be empty.").max(255, "Specification value cannot exceed 255 characters."),
    is_optional: yup.boolean().required(),
  });

  const deadlineSchema = isWithDeadline
    ? yup
        .date()
        .required(required)
        .min(
          new Date(new Date().setDate(new Date().getDate() + 1))
            .toISOString()
            .split("T")[0],
          mustBeLaterThanToday
        )
    : yup.date().optional().nullable();

  const budgetSchema = isRestrictedBudget
    ? yup.number().required(required).min(1, min0)
    : yup.number().optional().min(0, min0).nullable();

  return yup.object<TListingCreateForm>().shape({
    title: yup.string().required(required).max(255, max255),
    category: yup.string().required(required),
    Specifications: yup.array().of(specificationsItemSchema).min(1, t("FormValidation.specification_min1")),
    country: yup.string().required(requiredPublish).max(100, max100),
    region: yup.string().optional().max(100, max100),
    attachments: yup.array().max(MAX_ATTACHMENTS, maxAttachments),
    deadline: deadlineSchema,
    budget: budgetSchema,
  });
};

export const listingCreateDraftSchema = ({
  isRestrictedBudget,
  isWithDeadline,
}: TSchemaProps) => {
  const { t } = useAppTranslation();

  const specificationsItemSchema = yup.object().shape({
    value: yup.string().required("Specification value cannot be empty.").max(255, "Specification value cannot exceed 255 characters."),
    is_optional: yup.boolean().required(),
  });

  const required = t("FormValidation.required");
  const max255 = t("FormValidation.maxChars", { count: 255 });
  const max100 = t("FormValidation.maxChars", { count: 100 });
  const min0 = t("FormValidation.min0");
  const mustBeLaterThanToday = t("FormValidation.mustBeLaterThanToday");

  const maxAttachments = t("FormValidation.maxAttachments", {
    count: MAX_ATTACHMENTS,
  });

  const budgetSchema = isRestrictedBudget
    ? yup.number().required(required).min(1, min0)
    : yup.number().optional().min(0, min0).nullable();

  const deadlineSchema = isWithDeadline
    ? yup
        .date()
        .required(required)
        .min(
          new Date(new Date().setDate(new Date().getDate() + 1))
            .toISOString()
            .split("T")[0],
          mustBeLaterThanToday
        )
    : yup.date().optional().nullable();

  return yup.object<TListingCreateForm>().shape({
    title: yup.string().required(required).max(255, max255),
    category: yup.string(),
    country: yup.string().max(100, max100),
    region: yup.string().max(100, max100),
    Specifications: yup.array().of(specificationsItemSchema).min(1, t("FormValidation.specification_min1")),
    attachments: yup.array().max(MAX_ATTACHMENTS, maxAttachments),
    deadline: deadlineSchema,
    budget: budgetSchema,
  });
};
