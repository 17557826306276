import { AttachmentTable } from "@/components/attachments/attachment-table/attachment-table";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { integerToFilesize } from "@/utils/integer-to-filesize";
import { cilActionUndo, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CTooltip } from "@coreui/react";
import { TQueryExistingAttachments } from "../hooks/use-query-existing-attachments";
import { Dispatch, SetStateAction } from "react";
import { dateToYearMonthDay } from "@/utils/date-fns";

type TOfferCreateEditViewFileTableProps = {
  isEditing: boolean;
  isOffice?: boolean;
  existingAttachments?: TQueryExistingAttachments[];

  files: File[];
  setFiles: (files: File[]) => void;

  attachmentUrlsToDelete?: string[];
  setAttachmentUrlsToDelete: Dispatch<SetStateAction<string[]>>;

  isTooManyEstimatedAttachments: boolean;
};

export const OfferCreateEditViewFileTable = ({
  isEditing,
  isOffice = false,
  files,
  existingAttachments = [],
  attachmentUrlsToDelete = [],
  setAttachmentUrlsToDelete,
  setFiles,
  isTooManyEstimatedAttachments,
}: TOfferCreateEditViewFileTableProps) => {
  const { t } = useAppTranslation();

  const handleAttachmentToDelete = ({
    url,
    indexOfNew,
  }: {
    url: string;
    indexOfNew?: number;
  }) => {
    if (indexOfNew !== undefined && indexOfNew !== null) {
      return removeFileByIndex(indexOfNew);
    }
    setAttachmentUrlsToDelete((prev) => {
      if (prev.includes(url)) {
        return prev.filter((item) => item !== url);
      }
      return [...prev, url];
    });
  };

  const removeFileByIndex = (index: number) => {
    const tempState = [...files].filter((_file, _index) => _index !== index);
    setFiles(tempState);
  };

  return (
    <AttachmentTable
      isEditing={isEditing}
      isRed={isTooManyEstimatedAttachments}
      attachments={
        [
          ...existingAttachments.map((file, _index) => {
            return {
              indexOfNew: null,
              display_name: file.display_name,
              attachment_url: file.attachment_url,
              size: file.size,
              created_at: file.created_at,
              created_by: file.created_by,
            };
          }),
          ...files.map((file, _index) => {
            return {
              indexOfNew: _index,
              display_name: file.name,
              attachment_url: URL.createObjectURL(file),
              size: file.size,
              created_at: new Date().toISOString(),
              created_by: t("AttachmentsTable.you"),
            };
          }),
        ].map((file) => {
          const splitName = file?.display_name.split(".");
          const type = splitName[splitName.length - 1] || "";

          return {
            fileUrl: file?.attachment_url,
            name: file?.display_name,
            size: integerToFilesize(file?.size),
            type,
            uploadedAt: dateToYearMonthDay(file?.created_at),
            uploadedBy: file?.created_by,
            markedToDelete: attachmentUrlsToDelete.includes(
              file?.attachment_url
            ),
            indexOfNew: file?.indexOfNew ?? null,
            actions: (
              <div className="flex justify-center w-auto gap-1">
                 {isEditing && (
                <CTooltip
                  content={
                    attachmentUrlsToDelete.includes(file?.attachment_url)
                      ? t("CommonTables.cancel")
                      : t("CommonTables.remove")
                  }
                >
                  <CButton
                    color={
                      attachmentUrlsToDelete.includes(file?.attachment_url)
                        ? "info"
                        : "danger"
                    }
                    size="sm"
                    className="rounded-full"
                    onClick={() => {
                      handleAttachmentToDelete({
                        url: file?.attachment_url,
                        indexOfNew:
                          file?.indexOfNew !== null &&
                          file?.indexOfNew !== undefined
                            ? file.indexOfNew
                            : undefined,
                      });
                    }}
                  >
                    
                    <span className="text-white">
                      <CIcon
                        icon={
                          attachmentUrlsToDelete.includes(file?.attachment_url)
                            ? cilActionUndo
                            : cilTrash
                        }
                      />
                    </span>
                  </CButton>
                </CTooltip>
                 )}
              </div>
            ),
          };
        }) || []
      }
      isWithActions={!isOffice}
    />
  );
};
