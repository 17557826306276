import { useAppDispatch } from "@/store/hooks";
import { addToastMain } from "@/store/toast/slice";
import { authedFetch } from "@/utils/authed-fetch";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import { TListingGroupCreateForm } from "../listing-group/create/create-group";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";

type TUseCreateListingGroupMutation = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useCreateListingGroupMutation = (
  props: TUseCreateListingGroupMutation
) => {
  const { onSuccess, onError } = props;
  const { t } = useAppTranslation();
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationKey: ["delete-listing"],
    mutationFn: async ({ formData }: { formData: TListingGroupCreateForm }) => {
      return authedFetch({
        endpoint: `api/office/listing-group`,
        method: "POST",
        body: formData,
        token: await getToken(),
      })
        .then((res) => {
          if (res.status >= 400) {
            throw new Error(res.statusText);
          } else {
            dispatch(
              addToastMain({
                mainToast: "custom_toast",
                mainToastColor: "success",
                mainToastContent: t("Toasts.ListingGroup.createSuccess"),
              })
            );
            onSuccess?.();
          }
        })
        .catch((error) => {
          dispatch(
            addToastMain({
              mainToast: "custom_toast",
              mainToastColor: "danger",
              mainToastContent: error.toString(),
            })
          );
          onError?.();
        });
    },
  });
};
