import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { DiscoverTable } from "../components/discover-table";
import { NewDiscoverCarousel } from "../components/new-discover-carousel";

export const PageDiscover = () => {
  useBreadcrumbs("DISCOVER");

  return (
    <div>
        <NewDiscoverCarousel />
      <div className="my-4 border-b-2" />
      <DiscoverTable />
    </div>
  );
};
