// ListingGroup.tsx
import React, { useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAppDispatch } from "@/store/hooks";
import { setModalConfirmation } from "@/store/modal/slice";
import { useAuth } from "@clerk/clerk-react";
import { useDebounce } from "@/hooks/use-debounce";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { authedFetch } from "@/utils/authed-fetch";
import { CustomPagination } from "@/components/forms/custom/custom-pagination/custom-pagination";
import { GroupsAndListingsButtons } from "../components/groups-and-listings-buttons";
import { useDeleteListingGroupMutation } from "../hooks/use-delete-listing-group-mutation";
import { Group, TQuery, TSpreadableParams } from "./components/types";
import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import { TableNoData } from "@/components/forms/custom/custom-table/table-no-data";
import { CBadge, CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilX, cilGrid, cilList, cilPen } from "@coreui/icons";
import { GroupGrid } from "./components/group-grid";
import { GroupTable } from "./components/group-table";

export const ListingGroup: React.FC = () => {
  useBreadcrumbs("LISTING_GROUPS");
  const { t } = useAppTranslation();
  const { getToken } = useAuth();
  const pageRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Get viewMode from URL parameters or default to 'grid'
  const initialViewMode =
    (searchParams.get("viewMode") as "grid" | "table") || "table";
  const [viewMode, setViewMode] = useState<"grid" | "table">(initialViewMode);

  const p = parseInt(searchParams.get("p") || "1", 10);
  const ps = parseInt(searchParams.get("ps") || "10", 10);
  const orderBy = searchParams.get("orderBy") || "";
  const orderDir = searchParams.get("orderDir") || "";
  const nameRaw = searchParams.get("name") || "";
  const setPage = (page: number) => setSearchParams({ p: page.toString() });

  const spreadableParams: TSpreadableParams = {};
  if (p && p > 1) spreadableParams.p = p.toString();
  if (ps) spreadableParams.ps = ps.toString();
  if (orderBy) spreadableParams.orderBy = orderBy;
  if (orderDir) spreadableParams.orderDir = orderDir;
  if (nameRaw) spreadableParams.name = nameRaw;

  const name = useDebounce(nameRaw, 250);

  const { data, isLoading, refetch } = useQuery<TQuery>({
    queryKey: ["listing-group", p, ps, name, orderBy, orderDir],
    queryFn: async () => {
      const params = new URLSearchParams();
      if (p) params.append("p", p.toString());
      if (ps) params.append("ps", ps.toString());
      if (name) params.append("name", name);
      if (orderBy) params.append("orderBy", orderBy);
      if (orderDir) params.append("orderDir", orderDir);

      const response = await authedFetch({
        endpoint: `api/office/listing-group?${params.toString()}`,
        token: await getToken(),
      });
      const jsonData = await response.json();
      return jsonData;
    },
  });

  const pageCount = Math.ceil((data?.count || 0) / ps);
  const count = data?.count || 0;

  const deleteMutation = useDeleteListingGroupMutation({ refetch });

  const handleDelete = (group: Group) => {
    deleteMutation.mutate({ id: group.id });
  };

  const dispatchDeleteModal = (group: Group) =>
    dispatch(
      setModalConfirmation({
        isOpen: true,
        title: t("Modals.ListingGroup.DeleteConfirmation.title"),
        message: t("Modals.ListingGroup.DeleteConfirmation.message"),
        messageSecondary: `#${group.id}: ${group.title || group.name}`,
        buttons: {
          cancel: {
            label: t("CommonTables.cancel"),
            color: "dark",
            variant: "outline",
          },
          confirm: {
            label: t("CommonTables.delete"),
            color: "danger",
            onClick: () => handleDelete(group),
          },
        },
      })
    );

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setSearchParams({ ...spreadableParams, name: e.target.value });
    } else {
      delete spreadableParams.name;
      setSearchParams({ ...spreadableParams });
    }
  };

  const handleClearFilterBadge = (key: keyof TSpreadableParams) => {
    delete spreadableParams[key];
    setSearchParams({ ...spreadableParams });
  };

  const handleClearFilterAll = () => {
    delete spreadableParams.name;
    setSearchParams({
      ...spreadableParams,
    });
  };

  // Handle sorting changes
  const handleSortingChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    let [newOrderBy, newOrderDir] = value.split("_");

    setSearchParams({
      ...spreadableParams,
      orderBy: newOrderBy,
      orderDir: newOrderDir,
    });
  };

  const setOrder = (orderBy: string, orderDir: string) => {
    setSearchParams({ ...spreadableParams, orderBy, orderDir });
  };

  // Define sorting options
  const sortingOptions = [
    { value: "name_asc", label: t("Sorting.byNameAsc") },
    { value: "name_desc", label: t("Sorting.byNameDesc") },
    { value: "created_at_asc", label: t("Sorting.byCreatedAtAsc") },
    { value: "created_at_desc", label: t("Sorting.byCreatedAtDesc") },
  ];

  return (
    <div ref={pageRef}>
      {/* Header Section with Tabs and View Mode Toggle */}
      <div className="flex items-center justify-between mb-4">
        {/* Tabs */}
        <GroupsAndListingsButtons activeState="groups" />
        {/* Right Controls */}
        <div className="flex items-center gap-2">
          {/* View Mode Toggle */}
          <CButton
            color={viewMode === "grid" ? "primary" : "secondary"}
            onClick={() => setViewMode("grid")}
          >
            <CIcon icon={cilGrid} />
          </CButton>
          <CButton
            color={viewMode === "table" ? "primary" : "secondary"}
            onClick={() => setViewMode("table")}
          >
            <CIcon icon={cilList} />
          </CButton>
        </div>
      </div>
      <div className="flex justify-between pt-3 pb-2">
        <div>
          <h1>{t("PageListingGroups.listingGroups")}</h1>
        </div>
      </div>

      {/* Search and Sort Controls */}
      <div className="flex items-center justify-between gap-3 px-3 py-3 bg-white rounded-t-xl">
        {/* Left Controls */}
        <div className="flex items-center gap-2">
          <span className="font-bold">
            {t("PageListingGroups.ListingGroupsTable.name")}:{" "}
          </span>
          <div>
            <CustomInput
              placeholder={t("CommonTables.searchDots")}
              value={nameRaw}
              onChange={handleChangeName}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setSearchParams({ ...spreadableParams, search: "" });
                }
              }}
              divWrap
              clearable
            />
          </div>
        </div>

        {/* Right Controls */}
        {viewMode === "table" && (
          <CButton
            color="info"
            onClick={() => {
              navigate(`/listing-group/create?viewMode=${viewMode}`);
            }}
          >
            <span className="text-white">
              <CIcon icon={cilPen} />
              <span className="ml-2">{t("PageListingGroups.createGroup")}</span>
            </span>
          </CButton>
        )}
      </div>

      {viewMode === "table" && (name || (orderBy && orderDir)) ? (
        <div className="flex px-3 pb-3 bg-white">
          <div className="flex items-stretch gap-2 p-2 bg-gray-100 rounded-lg">
            {name && (
              <CBadge color="info">
                <span className="flex items-center gap-1">
                  {`Name: ${name}`}
                  <CIcon
                    icon={cilX}
                    size="sm"
                    onClick={() => handleClearFilterBadge("name")}
                    className="cursor-pointer"
                  />
                </span>
              </CBadge>
            )}
          </div>
        </div>
      ) : null}
      <div className="self-stretch h-[5px] bg-white mb-[-5px]" />

      {/* Render Grid or Table based on View Mode */}
      {viewMode === "grid" ? (
        <GroupGrid
          data={data}
          isLoading={isLoading}
          onDelete={dispatchDeleteModal}
        />
      ) : (
        <GroupTable
          data={data}
          isLoading={isLoading}
          onDelete={dispatchDeleteModal}
          orderBy={orderBy}
          orderDir={orderDir}
          setOrder={setOrder}
        />
      )}

      {/* Pagination */}
      <div className="flex justify-center">
        <CustomPagination
          pageCount={pageCount}
          currentPage={p}
          onPageChange={setPage}
        />
      </div>

      {!isLoading && count === 0 && <TableNoData />}
    </div>
  );
};
