import { TBreadcrumbKeys } from "@/components/app-breadcrumb";
import { setBreadcrumbsItems } from "@/store/breadcrumbs/slice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

export const useBreadcrumbs = (breadcrumb?: TBreadcrumbKeys) => {
  const dispatch = useAppDispatch();
  const breadcrumbSelected = useAppSelector(
    (state) => state.breadcrumbs.breadcrumb
  );

  if (breadcrumb) {
    dispatch(setBreadcrumbsItems(breadcrumb!));
  }

  return { breadcrumbSelected };
};
