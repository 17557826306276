import { CategoryBadge } from "@/components/badges/category-badge";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { EListingCategory } from "@/enums/listing-enums";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { authedFetch } from "@/utils/authed-fetch";
import { dateToYearMonthDay } from "@/utils/date-fns";
import { useAuth } from "@clerk/clerk-react";
import {
  cilChevronLeft,
  cilChevronRight,
  cilCalendar,
  cilTag,
  cilArrowRight,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CCard, CCardBody, CCardTitle } from "@coreui/react";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

type TListing = {
  id: number;
  title: string;
  category: string;
  country: string;
  created_at: string;
  deadline: string;
};

type TQueryResponse = {
  count: number;
  listings: TListing[];
};

export const NewDiscoverCarousel = () => {
  const { t } = useAppTranslation();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery<TQueryResponse>({
    queryKey: ["new-listings"],
    queryFn: async () => {
      const response = await authedFetch({
        endpoint: "api/provider/discover/latest",
        token: await getToken(),
      });
      return response.json();
    },
  });

  // Dynamic number of visible cards based on screen size
  const [visibleCards, setVisibleCards] = useState(3);

  useEffect(() => {
    const updateVisibleCards = () => {
      if (window.innerWidth >= 1024) {
        setVisibleCards(3);
      } else if (window.innerWidth >= 768) {
        setVisibleCards(2);
      } else {
        setVisibleCards(1);
      }
    };

    updateVisibleCards();
    window.addEventListener("resize", updateVisibleCards);
    return () => window.removeEventListener("resize", updateVisibleCards);
  }, []);

  const listingsMap: React.ReactNode[] =
    data?.listings?.map((listing) => (
      <CCard
        key={listing.id}
        className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col justify-between h-full w-full p-6 transition-transform duration-300 hover:shadow-2xl hover:-translate-y-1"
      >
        <CCardBody className="flex flex-col justify-between h-full">
          <div>
            <div className="text-sm text-gray-500 flex items-center mb-2">
              <span className="font-medium">#{listing.id}</span>
            </div>
            <h4 className="text-xl font-bold text-gray-800 mb-2">
              {listing.title}
            </h4>
            <div className="pb-2 text-sm flex items-center space-x-2">
              <CIcon icon={cilTag} className="text-gray-400" />
              <CategoryBadge category={listing.category as EListingCategory} />
            </div>
          </div>
          <div className="flex justify-between items-end mt-4">
            <div className="text-sm text-gray-600">
              <span className="font-semibold flex items-center space-x-1">
                <CIcon icon={cilCalendar} className="text-gray-400" />
                <span>{t("PageDiscover.LatestCarousel.availableUntil")}:</span>
              </span>
              <span>
                {listing?.deadline
                  ? dateToYearMonthDay(listing.deadline)
                  : "-"}
              </span>
            </div>
            <CButton
              color="info"
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded transition flex items-center space-x-2"
              onClick={() => {
                navigate(`/discover/${listing.id}`, {
                  state: "discover-preview",
                });
              }}
            >
                                      <div className="flex items-center gap-1">
                        <span class="relative flex h-1 w-3">
  <span class="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-yellow-400 opacity-75"></span>
  <span class="relative inline-flex rounded-full h-2 w-2 bg-yellow-500"></span>
</span>
<span>{t("PageDiscover.LatestCarousel.viewMore")}</span>
              <CIcon icon={cilArrowRight} className="text-white" />
</div>
            </CButton>
          </div>
        </CCardBody>
      </CCard>
    )) || [];

  if (!isLoading && listingsMap.length === 0) {
    return <div className="text-gray-500">{t("CommonTables.noData")}</div>;
  }

  // Update skeletonsMap to match visibleCards
  const skeletonsMap = Array.from({ length: visibleCards }).map((_, index) => (
    <CCard
      key={index}
      className="bg-gray-100 rounded-lg shadow-lg w-full p-6 h-full"
    >
      <CCardBody className="flex flex-col justify-between h-full">
        <CCardTitle>
          <div className="text-sm text-gray-500">
            <DefaultSkeleton className="w-12" />
          </div>
          <DefaultSkeleton className="w-40 mt-2" />
          <div className="pb-2 text-sm mt-2">
            <DefaultSkeleton className="w-16" />
          </div>
        </CCardTitle>
        <div className="flex justify-between items-end mt-4">
          <div className="text-sm text-gray-500">
            <DefaultSkeleton className="w-24" />
            <DefaultSkeleton className="w-20 mt-1" />
          </div>
          <DefaultSkeleton className="w-24 h-8" />
        </div>
      </CCardBody>
    </CCard>
  ));

  return (
    <div>
            <h3 className="text-2xl font-bold mb-6">
            {t("PageDiscover.latestListings")}
      </h3>
      <Carousel cards={isLoading ? skeletonsMap : listingsMap} />
    </div>
  );
};

const Carousel = ({ cards }: { cards: React.ReactNode[] }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [visibleCards, setVisibleCards] = useState(3);

  useEffect(() => {
    const updateVisibleCards = () => {
      if (window.innerWidth >= 1024) {
        setVisibleCards(3);
      } else if (window.innerWidth >= 768) {
        setVisibleCards(2);
      } else {
        setVisibleCards(1);
      }
    };

    updateVisibleCards();
    window.addEventListener("resize", updateVisibleCards);
    return () => window.removeEventListener("resize", updateVisibleCards);
  }, []);

  const scrollNext = () => {
    setScrollPosition((prev) =>
      Math.min(prev + 1, cards.length - visibleCards)
    );
  };

  const scrollPrev = () => {
    setScrollPosition((prev) => Math.max(prev - 1, 0));
  };

  return (
    <div className="relative">
      {scrollPosition > 0 && (
        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white hover:bg-gray-200 p-3 rounded-full shadow transition-all focus:outline-none"
          onClick={scrollPrev}
          aria-label="Previous Slide"
        >
          <CIcon icon={cilChevronLeft} className="text-blue-500" size="2xl" />
        </button>
      )}

      <div className="overflow-hidden">
        <div
          className="flex transition-transform duration-300 ease-in-out"
          style={{
            transform: `translateX(-${
              (scrollPosition * 100) / visibleCards
            }%)`,
          }}
        >
          {cards.map((card, index) => (
            <div
              className="flex-none w-full"
              style={{ flex: `0 0 ${100 / visibleCards}%` }}
              key={index}
            >
              {card}
            </div>
          ))}
        </div>
      </div>

      {scrollPosition < cards.length - visibleCards && (
        <button
          className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white hover:bg-gray-200 p-3 rounded-full shadow transition-all focus:outline-none"
          onClick={scrollNext}
          aria-label="Next Slide"
        >
          <CIcon icon={cilChevronRight} className="text-blue-500" size="2xl" />
        </button>
      )}
    </div>
  );
};

export default NewDiscoverCarousel;
