import { CFormSwitch, CInputGroup, CInputGroupText } from "@coreui/react";
import { TListingEditForm } from "../listing-view-edit";
import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import CIcon from "@coreui/icons-react";
import { cilEuro } from "@coreui/icons";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";

type TListingViewRestrictedBudgetProps = {
  dataValue: TListingEditForm["budget"];
  value: TListingEditForm["budget"];
  error?: string;
  hasSubmittedEither: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  switchOnChange: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEditing: boolean;
  isLoading?: boolean;
  isRestrictedBudget: boolean;
};

export const ListingViewRestrictedBudget = (
  props: TListingViewRestrictedBudgetProps
) => {
  const {
    dataValue,
    value,
    error,
    setFieldValue,
    switchOnChange,
    handleChange,
    isEditing,
    isLoading,
    isRestrictedBudget,
    hasSubmittedEither,
  } = props;
  const { t } = useAppTranslation();

  const title = t("PageListingCreateEdit.Specifications.restrictedBudget");

  const budgetValid = !error && hasSubmittedEither;
  const budgetInvalid = !!error && hasSubmittedEither && isRestrictedBudget;

  if (isLoading) {
    return (
      <div className="">
        <label className="form-label">{title}</label>
        <DefaultSkeleton
          className="px-[0px] py-[0px] max-w-full"
          classNameInner="h-[30px]"
        />
      </div>
    );
  }

  if (!isEditing && value) {
    return (
      <div className="">
        <label className="form-label">{title}</label>

        <p className="px-[12px] py-[6px] h-[44px] mb-0 rounded flex items-center font-bold text-xl">
          {isRestrictedBudget ? (
            <>
              {value}
              <CIcon icon={cilEuro} size="xl" />
            </>
          ) : (
            <span className="text-lg font-normal">
              {t("PageListingCreateEdit.notAssigned")}
            </span>
          )}
        </p>
      </div>
    );
  }

  if (!isEditing && !value) return null;

  return (
    <div className="">
      <label className="form-label">{title}</label>
      <div className="flex">
        <CInputGroup className="sm:flex-1">
          <CInputGroupText
            style={
              isRestrictedBudget
                ? {}
                : {
                    border: "transparent",
                  }
            }
          >
            <CFormSwitch
              size="xl"
              onChange={() => {
                switchOnChange();
                if (isRestrictedBudget) {
                  setFieldValue("budget", null);
                } else {
                  setFieldValue("budget", dataValue ? dataValue : null);
                }
              }}
              checked={isRestrictedBudget}
            />
          </CInputGroupText>
          {isRestrictedBudget && (
            <>
              <CustomInput
                id="budget"
                type="number"
                disabled={!isRestrictedBudget}
                value={value}
                onChange={handleChange}
                valid={budgetValid}
                invalid={budgetInvalid}
              />
              <CInputGroupText>€</CInputGroupText>
            </>
          )}
        </CInputGroup>
      </div>
      {budgetInvalid ? (
        <div className="!block invalid-feedback">{error}</div>
      ) : null}
    </div>
  );
};
