// CreateGroupTile.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import { cilPen } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";

interface CreateGroupTileProps {}

export const CreateGroupTile: React.FC<CreateGroupTileProps> = ({}) => {
  const navigate = useNavigate();
  const { t } = useAppTranslation();

  return (
    <div
      className="flex flex-col items-center justify-center p-4 transition-colors border-2 border-gray-300 border-dashed rounded-lg cursor-pointer create-group-tile hover:bg-gray-100"
      onClick={() => navigate("/listing-group/create?viewMode=grid")}
    >
      <CIcon icon={cilPen} size="xxl" className="mb-2 text-gray-500" />
      <span className="text-center text-gray-500">
        {t("PageListingGroups.createGroup")}
      </span>
    </div>
  );
};
