import { useAppTranslation } from "@/locales/helpers/translation-helpers";

type TOfferCreateEditViewHeaderProps = {
  listingId?: string;
  offerId?: string;
  isEditing?: boolean;
};

export const OfferCreateEditViewHeader = ({
  listingId,
  offerId,
  isEditing,
}: TOfferCreateEditViewHeaderProps) => {
  const { t } = useAppTranslation();

  const title = isEditing
    ? t("PageOfferCreateEditView.titleEdit")
    : t("PageOfferCreateEditView.titleCreate");

  return (
    <>
      <h3 className="mb-0">{title}</h3>
      <span className="flex items-center gap-2 text-slate-500">
        {t("PageOfferCreateEditView.listingId", { id: listingId })}
      </span>

      {isEditing && (
        <span className="flex items-center gap-2 text-slate-500">
          {t("PageOfferCreateEditView.offerId", { id: offerId })}
        </span>
      )}
    </>
  );
};
