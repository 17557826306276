import { CustomButton } from "@/components/forms/custom/custom-button/custom-button";
import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import { RequiredAsterist } from "@/components/forms/custom/required-asterisk/required-asterisk";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { listingGroupCreateSchema } from "./schema";
import { useCreateListingGroupMutation } from "../../hooks/use-create-listing-group";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { CSpinner } from "@coreui/react";

const initialValues: TListingGroupCreateForm = {
  name: "",
};

export const CreateGroup = () => {
  useBreadcrumbs("LISTING_GROUP__CREATE");
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const viewMode = (searchParams.get('viewMode') as 'grid' | 'table') || 'table';

  const createMutation = useCreateListingGroupMutation({
    onSuccess: () => {
      navigate(`/listing-group?viewMode=${viewMode}`);
    },
  });

  const validationSchema = listingGroupCreateSchema();

  return (
    <div className="max-w-[700px]">
      <Formik<TListingGroupCreateForm>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          createMutation.mutate({
            formData: values,
          });
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
          <Form>
            <div className="flex flex-col">
              <h5 className="px-2 pb-0">
                {t("PageListingGroupCreate.newListingGroup")}
              </h5>
            </div>

            <div className="h-32 p-3 border border-gray-200 rounded-lg">
              <CustomInput
                className=""
                id="name"
                label={
                  <>
                    {t("PageListingGroupCreate.name")}
                    <RequiredAsterist />
                  </>
                }
                type="text"
                value={values.name}
                onChange={handleChange}
                valid={hasSubmitted && !errors.name}
                invalid={hasSubmitted && !!errors.name}
                feedbackInvalid={errors.name}
                divWrap
              />
            </div>

            <div className="flex justify-end gap-2 pt-4">
              <CustomButton
                type="button"
                color="secondary"
                variant="outline"
                className="flex-1 sm:flex-initial"
                disabled={isSubmitting}
                onClick={() => {
                  navigate(-1);
                }}
              >
                {t("CommonTables.cancel")}
              </CustomButton>
              <CustomButton
                type="button"
                color="info"
                className="flex-1 text-white sm:flex-initial"
                disabled={isSubmitting}
                onClick={() => {
                  setHasSubmitted(true);
                  handleSubmit();
                }}
              >
                {isSubmitting && <CSpinner as="span" size="sm" aria-hidden="true" />}
                {t("CommonTables.create")}
              </CustomButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export type TListingGroupCreateForm = {
  name: string;
};
