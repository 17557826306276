import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { useAppDispatch } from "@/store/hooks";
import { setModalConfirmation } from "@/store/modal/slice";

export const useOfferModals = () => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();

  const handleDispatchOfferRejectModal = ({
    onConfirm,
  }: {
    onConfirm: () => void;
  }) => {
    dispatch(
      setModalConfirmation({
        isOpen: true,
        title: t("Modals.Offer.RejectConfirmation.title"),
        message: t("Modals.Offer.RejectConfirmation.message"),
        buttons: {
          cancel: {
            label: t("CommonTables.cancel"),
            color: "dark",
            variant: "outline",
          },
          confirm: {
            color: "danger",
            label: t("Modals.Offer.RejectConfirmation.Buttons.confirm"),
            onClick: () => {
              onConfirm();
            },
          },
        },
      })
    );
  };

  return {
    handleDispatchOfferRejectModal,
  };
};
