import { CFormSwitch } from "@coreui/react";
import { TListingCreateForm } from "../listing-create";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";

type TListingCreateSettingsProps = {
  values: TListingCreateForm;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  hasSubmittedDraft: boolean;
  hasSubmittedPublish: boolean;
};

export const ListingCreateSettings = ({
  values,
  setFieldValue,
  hasSubmittedDraft,
  hasSubmittedPublish,
}: TListingCreateSettingsProps) => {
  const hasSubmittedEither = hasSubmittedDraft || hasSubmittedPublish;
  const { t } = useAppTranslation();

  return (
    <>
      <h5 className="px-2 pt-4 pb-0">{t("PageListingCreateEdit.settings")}</h5>
      <div className="flex flex-col gap-4 p-3 border border-gray-200 rounded-lg">
        <div className="">
          <label className="form-label">{t("CommonTables.hidden")}</label>
          <CFormSwitch
            size="xl"
            valid={hasSubmittedEither}
            onChange={() => {
              setFieldValue("is_hidden", !values.is_hidden);
            }}
            value={values.is_hidden ? 1 : 0}
            checked={values.is_hidden}
          />
        </div>
      </div>
    </>
  );
};
