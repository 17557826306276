import { useTranslation } from "react-i18next";
import EN from "../en/translation.json";

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${"" extends P ? "" : "."}${P}`
    : never
  : never;
type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[],
];
export type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
    : "";

export type ValidI18nKey = Leaves<typeof EN, 10>;

export type TranslateFunction = (
  message: ValidI18nKey,
  params?: { [key: string]: string | number | undefined }
) => string;

export const useAppTranslation = () => {
  const { t: translate, ...rest } = useTranslation();

  const t: TranslateFunction = (message, params) => {
    return translate(message, params);
  };

  return { t, ...rest };
};
