import { useEffect, useRef, useState } from "react";

type TTableShadowWrapperProps = {
  children?: React.ReactNode;
  isRed?: boolean;
};

export const TableShadowWrapper = ({
  children,
  isRed,
}: TTableShadowWrapperProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [hasLeftShadow, setHasLeftShadow] = useState(false);
  const [hasRightShadow, setHasRightShadow] = useState(false);

  const updateShadows = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;

      setHasLeftShadow(scrollLeft > 0);
      setHasRightShadow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      updateShadows();
      scrollContainer.addEventListener("scroll", updateShadows);
      window.addEventListener("resize", updateShadows);

      // Clean up event listeners on unmount
      return () => {
        scrollContainer.removeEventListener("scroll", updateShadows);
        window.removeEventListener("resize", updateShadows);
      };
    }
  }, []);

  return (
    <div
      className={`relative transition-all w-full mb-2 bg-white rounded-lg ${isRed ? "shadow-[0_10px_20px_rgba(239,_68,_68,_0.7)]" : "shadow-lg"}`}
    >
      <div
        className={`absolute inset-y-0 left-0 w-4 bg-gradient-to-r from-gray-200 transition-opacity duration-300 pointer-events-none ${
          hasLeftShadow ? "opacity-100" : "opacity-0"
        }`}
      ></div>

      <div
        className={`absolute inset-y-0 right-0 w-4 bg-gradient-to-l from-gray-200 transition-opacity duration-300 pointer-events-none ${
          hasRightShadow ? "opacity-100" : "opacity-0"
        }`}
      ></div>

      <div ref={scrollContainerRef} className="overflow-x-auto rounded-lg">
        {children}
      </div>
    </div>
  );
};
