import {
  MAX_ATTACHMENT_SIZE,
  MAX_ATTACHMENTS,
} from "@/constants/form-constatns";
import { CFormTextarea } from "@coreui/react";
import { useState } from "react";
import { useToastsDiscover } from "../../hooks/use-toasts-discover";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { useNavigate, useParams } from "react-router-dom";
import { OfferCreateEditViewButtons } from "./components/offer-create-edit-view-buttons";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { useMutationCreateOffer } from "./hooks/use-mutation-create-offer";
import { OfferCreateEditViewHeader } from "./components/offer-create-edit-view-header";
import { OfferCreateEditViewFileInput } from "./components/offer-create-edit-view-file-input";
import { OfferCreateEditViewFileTable } from "./components/offer-create-edit-view-file-table";
import { OfferCreateEditViewTableValidations } from "./components/offer-create-edit-view-table-validations";
import { useQueryExistingAttachments } from "./hooks/use-query-existing-attachments";

type TPageOfferCreateEditViewProps = {
  isEditing?: boolean;
};

export const PageOfferCreateEditView = ({
  isEditing,
}: TPageOfferCreateEditViewProps) => {
  useBreadcrumbs(isEditing ? "OFFER_EDIT" : "OFFER_CREATE");
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const {
    toastAttachmentsAreRequired,
    toastMaxAttachments,
    toastMaxAttachmentSize,
  } = useToastsDiscover();
  const { listingId, enrollmentId, offerId } = useParams();
  const [files, setFiles] = useState<File[]>([]);
  const [attachmentUrlsToDelete, setAttachmentUrlsToDelete] = useState<
    string[]
  >([]);
  const [comment, setComment] = useState<string>("");

  const isTooManyEstimatedAttachments = files.length > MAX_ATTACHMENTS;

  const { data: existingAttachments } = useQueryExistingAttachments({
    offerId: Number(offerId),
  });

  const filesSizeSum = files.reduce((sum, file) => sum + file.size, 0);

  const filesFromServerSizeSum =
    existingAttachments
      ?.filter((x) => !attachmentUrlsToDelete.includes(x.attachment_url))
      .reduce((sum, file) => sum + file.size, 0) || 0;

  const totalSizeSum = filesSizeSum + filesFromServerSizeSum;

  const totalEstimatedAttachmentsCount =
    files.length +
    (existingAttachments?.length || 0) -
    attachmentUrlsToDelete.length;

  const { mutate: mutateOffer, isPending: isPendingMutateOffer } =
    useMutationCreateOffer({
      comment,
      files,
      offerId: Number(offerId),
      enrollmentId: Number(enrollmentId),
      attachmentUrlsToDelete,
      afterSuccess: () => navigate(-1),
    });

  const handleOnSubmit = () => {
    if (totalEstimatedAttachmentsCount <= 0) {
      toastAttachmentsAreRequired();
      return;
    }
    if (files.length > MAX_ATTACHMENTS) {
      toastMaxAttachments();
      return;
    }
    if (totalSizeSum > MAX_ATTACHMENT_SIZE) {
      toastMaxAttachmentSize();
      return;
    }
    mutateOffer();
  };

  return (
    <div>
      <OfferCreateEditViewHeader
        listingId={listingId}
        offerId={offerId}
        isEditing={isEditing}
      />

      <div className="pt-4">
        <CFormTextarea
          label={t("PageOfferCreateEditView.comment")}
          rows={3}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>

      <h4 className="mt-4">{t("PageOfferCreateEditView.attachments")}</h4>

      <OfferCreateEditViewTableValidations
        isTooManyEstimatedAttachments={isTooManyEstimatedAttachments}
      />

      <div>
        <OfferCreateEditViewFileTable
          existingAttachments={existingAttachments}
          isEditing={!!isEditing}
          files={files}
          setFiles={setFiles}
          setAttachmentUrlsToDelete={setAttachmentUrlsToDelete}
          attachmentUrlsToDelete={attachmentUrlsToDelete}
          isTooManyEstimatedAttachments={isTooManyEstimatedAttachments}
        />

        <OfferCreateEditViewFileInput
          files={files}
          totalSizeSum={totalSizeSum}
          totalEstimatedAttachmentsCount={totalEstimatedAttachmentsCount}
          setFiles={setFiles}
          isTooManyEstimatedAttachments={isTooManyEstimatedAttachments}
        />
      </div>

      <div className="flex justify-end gap-2 py-4">
        <OfferCreateEditViewButtons
          onSubmit={handleOnSubmit}
          onCancel={() => navigate(-1)}
          isLoading={isPendingMutateOffer}
        />
      </div>
    </div>
  );
};
