import { configureStore } from "@reduxjs/toolkit";
import uiReducer from "./ui/slice";
import toastReducer from "./toast/slice";
import modalReducer from "./modal/slice";
import breadcrumbsReducer from "./breadcrumbs/slice";
import groupReducer from "./group/slice";

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    toast: toastReducer,
    modal: modalReducer,
    breadcrumbs: breadcrumbsReducer,
    group: groupReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
