import { EOfferStatus } from "@/enums/offer-enums";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { useAppDispatch } from "@/store/hooks";
import { addToastMain } from "@/store/toast/slice";
import { authedFetch } from "@/utils/authed-fetch";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";

type TUseOfficeListingEnrollmentChangeStatusProps = {
  onSuccess: () => void;
  onError: () => void;
};

export const useOfficeOfferStatusChange = (
  props: TUseOfficeListingEnrollmentChangeStatusProps
) => {
  const { onSuccess, onError } = props;
  const { t } = useAppTranslation();
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationKey: ["listing-offer-change-status"],
    mutationFn: async ({
      id,
      listingId,
      status,
    }: {
      id: number;
      listingId: number;
      status: EOfferStatus;
    }) => {
      return authedFetch({
        endpoint: `api/office/enrollment/listing/${listingId}/offer/${id}/status`,
        method: "PUT",
        body: {
          status,
        },
        token: await getToken(),
      })
        .then((res) => {
          if (res.status >= 400) {
            throw new Error(res.statusText);
          } else {
            dispatch(
              addToastMain({
                mainToast: "custom_toast",
                mainToastColor: "success",
                mainToastContent: status === EOfferStatus.CLIENT_ACCEPTED ? t("Toasts.Offer.acceptedOffer") : t("Toasts.Offer.rejectedOffer"),
              })
            );
            onSuccess();
          }
        })
        .catch((error) => {
          dispatch(
            addToastMain({
              mainToast: "custom_toast",
              mainToastColor: "danger",
              mainToastContent: error.toString(),
            })
          );
          onError();
        });
    },
  });
};
